import React, { useEffect, useRef, useState } from "react";
import AdmitCardTemplate1 from "./AdmitCardTemplate1";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { URL } from "../../../utils/Constants";
import { axiosPost } from "../../../utils/AxiosApi";
import _, { groupBy } from "lodash";
import moment from "moment";

const animatedComponents = makeAnimated();
function CreateAdmitCard() {
  const [template, setTemplate] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [note, setNote] = useState("");

  const [classValue, setClassValue] = useState(null);
  const [educationLevel, setEducationLevel] = useState({});

  const [assessmentSubjectValue, setAssessmentSubjectValue] = useState(null);
  const [assessmentAllData, setAssessmentAllData] = useState(null);
  const [assessmentSubjectList, setAssessmentSubjectList] = useState({});
  const [classRooms, setClassRooms] = useState(null);
  const [routineData, setRoutineData] = useState(null);
  const [classroomsList, setClassroomsList] = useState([]);
  const [educationLevelAllData, setEducationLevelAllData] = useState(null);

  const [filteredTable, setFilteredTable] = useState(null);
  const [filtereduniqueDates, setFilteredUniqueDates] = useState(null);

  const [student, setStudent] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentOptions, setSudentOptions] = useState({});

  const [className, setClassName] = useState("");

  const [schoolInfos, setSchoolInfos] = useState(null);
  const [studentInfos, setStudentInfos] = useState(null);
  const [studentFetched, setStudentFetched] = useState(false);

  const componentRef = useRef();
  const printFunction = useReactToPrint({
    content: () => componentRef.current,
    orientation: "landscape",
    removeAfterPrint: true,
    documentTitle: `${assessmentSubjectValue?.label}_${
      classRooms ? classRooms?.label : classValue?.label
    }_Admit Card`,
  });

  useEffect(() => {
    getEducationLevels();
    getSchoolInfo();
  }, []);

  useEffect(() => {
    let assessmentallDatas = assessmentAllData;
    let selectedData = classValue;
    if (assessmentallDatas && selectedData) {
      let assessmentOptions = assessmentallDatas?.map((item) => ({
        ...item,
        value: item.id,
        label: item.assessmentName,
      }));
      setAssessmentSubjectList(assessmentOptions);
    }
  }, [assessmentAllData]);

  useEffect(() => {
    let allData = educationLevelAllData;
    let selectedData = classValue;
    if (allData && selectedData) {
      let yearLevels = allData.filter((item) => item.id === selectedData.id)[0]
        ?.yearLevels;
      let YearLevelOptions = yearLevels?.map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      }));
      setClassroomsList(YearLevelOptions);
    } else {
      setClassroomsList({});
    }
  }, [classValue]);

  useEffect(() => {
    let studentAllDatas = studentInfos;
    if (studentAllDatas) {
      let studentOptions = studentAllDatas?.map((item) => ({
        ...item,
        value: item?.id,
        label: item.people?.name,
      }));
      setSudentOptions(studentOptions);
    }
  }, [studentInfos]);

  const getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        setEducationLevelAllData(data);
        setEducationLevel(
          data?.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        );
      }
    });
  };

  const handleClassChange = (value) => {
    setClassValue(value);
    setAssessmentSubjectValue(null);
    setClassRooms(null);
    setSelectedStudent(null);
    getAssessments(value?.id);
  };

  const handleAssessmentListChange = (value) => {
    setAssessmentSubjectValue(value);
    setClassRooms(null);
    setSelectedStudent(null);

    if (value?.id) {
      // getRoutineData(value?.id, classRooms?.id)
      getStudentList(value?.id, classRooms?.id, null);
    } else {
      setStudentInfos(null);
    }
  };

  const handleClassRoomListChange = (value) => {
    setClassRooms(value);
    setClassName(value?.name);
    setSelectedStudent(null);
    if (value?.id) {
      getStudentList(assessmentSubjectValue?.id, value?.id, null);
      // getRoutineData(assessmentSubjectValue?.id, value?.id)
    } else {
      // getRoutineData(assessmentSubjectValue?.id, null)
      getStudentList(assessmentSubjectValue?.id, null, null);
    }
  };

  const handleStudentChange = (value) => {
    let student = value;
    setStudent(student?.id);
    setSelectedStudent(student);
    getStudentList(assessmentSubjectValue?.id, classRooms?.id, student?.id);
  };

  const getAssessments = (param) => {
    let data = {
      educationLevelId: param,
    };
    axiosPost(URL.getAssessmentWithScaleOrComment, data, (response) => {
      if (response.data.status === 200) {
        setAssessmentAllData(response.data.data);
      }
    });
  };

  const transformstudentData = (data) => {
    const uniqueDates = Array.from(
      new Set(
        data.flatMap((d) => [
          d?.assessmentTheoryFromDate &&
            moment(d.assessmentTheoryFromDate).format("YYYY-MM-DD"),
          d?.assessmentPracticalFromDate &&
            moment(d.assessmentPracticalFromDate).format("YYYY-MM-DD"),
        ])
      )
    )
      .filter((item) => !!item)
      .sort();

    const groupedByDateTheory = groupBy(data, (entry) =>
      moment(entry.assessmentTheoryFromDate).format("YYYY-MM-DD")
    );
    const groupedByDatePractical = groupBy(data, (entry) =>
      moment(entry.assessmentPracticalFromDate).format("YYYY-MM-DD")
    );

    const mappedSubjects = Object.entries(groupedByDateTheory)
      .concat(Object.entries(groupedByDatePractical))
      .map(([date, entries]) => {
        const datePart = date.split(" ")[0];
        return entries.map((entry) => {
          let theoryDate;
          let practicalDate;
          if (entry?.assessmentTheoryFromDate) {
            theoryDate =
              moment(entry?.assessmentTheoryFromDate).format("YYYY-MM-DD") ===
              datePart;
          }
          if (entry?.assessmentPracticalFromDate) {
            practicalDate =
              moment(entry?.assessmentPracticalFromDate).format(
                "YYYY-MM-DD"
              ) === datePart;
          }
          let time = "";
          let subjectType = "";
          if (theoryDate) {
            time = `Th-${moment(entry.assessmentTheoryFromDate).format(
              "hh:mm A"
            )} to ${moment(entry.assessmentTheoryToDate).format("hh:mm A")}`;
            subjectType = "Th";
          }
          if (practicalDate) {
            if (time) {
              time += ` & Pr-${moment(entry.assessmentPracticalFromDate).format(
                "hh:mm A"
              )} to ${moment(entry.assessmentPracticalToDate).format(
                "hh:mm A"
              )}`;
              subjectType = "Th & Pr";
            } else {
              time = `Pr-${moment(entry.assessmentPracticalFromDate).format(
                "hh:mm A"
              )} to ${moment(entry.assessmentPracticalToDate).format(
                "hh:mm A"
              )}`;
              subjectType = "Pr";
            }
          }
          return {
            subject: entry.name,
            date: datePart,
            time: time,
            subjectType: subjectType,
          };
        });
      });

    const newTable = mappedSubjects.flat().reduce((acc, curr) => {
      if (!acc[curr.date]) {
        acc[curr.date] = [];
      }
      if (curr.time !== "") {
        const existingSubject = acc[curr.date].find((subject) =>
          subject.startsWith(curr.subject)
        );
        if (!existingSubject) {
          acc[curr.date].push(`${curr.subject} (${curr.subjectType})`);
        }
      }
      return acc;
    }, {});

    setFilteredTable(newTable);
    setFilteredUniqueDates(uniqueDates);
    return { table: newTable, uniqueDates };
  };

  const getRoutineData = (assessId, yearLevelId) => {
    let data = {
      assessmentId: assessId,
      educationLevelId: classValue?.id,
      yearLevelId: yearLevelId ? yearLevelId : null,
    };
    axiosPost(URL.getRoutineData, data, (response) => {
      if (response.data.status === 200) {
        // setRoutineData(response.data.data);
        // transformstudentData(response.data.data)
      }
    });
  };

  const templateOptions = [
    {
      label: "Template 1",
      value: 1,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNote(value);
  };

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e);
    setTemplate(e?.label);
    setClassValue(null);
    setAssessmentSubjectValue(null);
    setClassRooms(null);
    setSelectedStudent(null);
  };

  const downloadRoutine = async () => {
    document.getElementById("printRoutineAdmitCard").parentNode.style.overflow =
      "visible";
    const element = document.getElementById("printRoutineAdmitCard");
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("AdmitCard.pdf");
  };

  const getSchoolInfo = () => {
    axiosPost(URL.getSchoolByKey, {}, (response) => {
      if (response.status === 200) {
        setSchoolInfos(response.data.data);
      }
    });
  };

  const getStudentList = (assessId, yearLevelId, studentId) => {
    let data = {
      assessmentId: assessId,
      educationLevelId: classValue?.id,
      yearLevelId: yearLevelId ? yearLevelId : null,
      id: studentId ? studentId : null,
    };
    axiosPost(URL.getStudentWithAssessmentRoutine, data, (response) => {
      if (response.status === 200) {
        setStudentInfos(response.data.data);
        setStudentFetched(true);
      }
    });
  };

  return (
    <div
      className="card-setup-container tt-widgetContent-tab-holder"
      style={{ height: "79.5vh" }}
    >
      <div className="row">
        <div className="col-md-12 col-12">
          <div className="tt-group-header">Student Details</div>
          <div className="tt-group-body">
            <div className="d-flex justify-content-between">
              <div className="w-100 mr-1">
                <strong>Template:</strong>
                <Select
                  className="w-100"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={selectedTemplate}
                  options={templateOptions}
                  name="template"
                  onChange={(e) => handleTemplateChange(e)}
                  placeholder="Select Template"
                  isClearable
                />
              </div>
              <div className="w-100 mx-1">
                <strong>Education Level:</strong>
                <Select
                  isDisabled={!!!selectedTemplate}
                  className="w-100 mr-1"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={classValue}
                  options={educationLevel}
                  name="class"
                  onChange={handleClassChange}
                  placeholder="Select Level"
                  isClearable
                />
              </div>
              <div className="w-100 mx-1">
                <strong>Assessment:</strong>
                <Select
                  isDisabled={!!!classValue}
                  className="w-100"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={assessmentSubjectValue}
                  options={assessmentSubjectList}
                  name="assessmentList"
                  onChange={handleAssessmentListChange}
                  placeholder="Select Assessment"
                  isClearable
                />
              </div>
              <div className="w-100 mx-1">
                <strong>Class</strong>
                <Select
                  isDisabled={!!!assessmentSubjectValue}
                  className="w-100"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={classRooms}
                  options={classroomsList}
                  name="classRooms"
                  onChange={handleClassRoomListChange}
                  placeholder="Select Class"
                  isClearable
                />
              </div>
              <div className="w-100 ml-1">
                <strong>Student List:</strong>
                <Select
                  isDisabled={!!!classRooms}
                  className="w-100"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={selectedStudent}
                  options={studentOptions}
                  name="student"
                  onChange={handleStudentChange}
                  placeholder="Select Students"
                  isClearable
                />
              </div>
            </div>
            <div className="mt-2">
              <div className="w-100">
                <div className="py-2 d-flex flex-column">
                  <strong>Add Note:</strong>
                  <input
                    type="text"
                    name="note"
                    value={note}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Note"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center py-1 justify-content-end mt-2">
              <btn
                style={{
                  pointerEvents:
                    !!classValue && !!assessmentSubjectValue ? "all" : "none",
                }}
                className={
                  !!classValue && !!assessmentSubjectValue
                    ? `mr-2 tt-button tt-button-primary float-right permissionBtnCSS`
                    : `mr-2 tt-button float-right permissionBtnCSS tt-disable-comment-box`
                }
                onClick={printFunction}
              >
                Download / Print Admit Card
              </btn>
              {/* <btn
                                style={{
                                    pointerEvents: (!!classValue && !!assessmentSubjectValue) ? 'all' : 'none'
                                }}
                                className={
                                    (!!classValue && !!assessmentSubjectValue) ?
                                        `mr-2 tt-button tt-button-primary float-right permissionBtnCSS` :
                                        `mr-2 tt-button float-right permissionBtnCSS tt-disable-comment-box`
                                }
                                onClick={(e) => { downloadRoutine() }}>Download Routine</btn> */}
            </div>
          </div>
        </div>
        <div className="col-md-12 col-12">
          <div className="tt-group-header">Admit Card Preview</div>
          <div
            className="card-preview"
            id="printRoutineAdmitCard"
            ref={componentRef}
            style={{ zoom: "90%" }}
          >
            {template === "Template 1" ? (
              <>
                {studentInfos?.length > 0 ? (
                  <>
                    {studentInfos?.map((item) => {
                      return (
                        <AdmitCardTemplate1
                          transformstudentData={transformstudentData}
                          classValue={classValue}
                          assessmentSubjectValue={assessmentSubjectValue}
                          classRooms={classRooms}
                          className={className}
                          routineData={routineData}
                          filteredTable={filteredTable}
                          filtereduniqueDates={filtereduniqueDates}
                          note={note}
                          schoolInfos={schoolInfos}
                          student={item}
                          studentInfos={studentInfos}
                          studentFetched={studentFetched}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    <AdmitCardTemplate1
                      transformstudentData={transformstudentData}
                      classValue={classValue}
                      assessmentSubjectValue={assessmentSubjectValue}
                      classRooms={classRooms}
                      className={className}
                      routineData={routineData}
                      filteredTable={filteredTable}
                      filtereduniqueDates={filtereduniqueDates}
                      note={note}
                      schoolInfos={schoolInfos}
                      studentInfos={studentInfos}
                      studentFetched={studentFetched}
                    />
                  </>
                )}
              </>
            ) : (
              <div className="text-center" style={{ fontSize: "1.5rem" }}>
                Select template to see the preview
              </div>
            )}
          </div>
        </div>
        {/* {!!assessmentSubjectValue &&
                    (
                        <>
                            <div className='p-2 col-12'>
                                <ExamModuleViewStudent
                                    classValue={classValue}
                                    assessmentSubjectValue={assessmentSubjectValue}
                                    routineData={routineData}
                                    filteredTable={filteredTable}
                                    filtereduniqueDates={filtereduniqueDates}
                                    isStudent={true}
                                />
                            </div>
                        </>
                    )
                } */}
      </div>
    </div>
  );
}

export default CreateAdmitCard;
