import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/Utils";
import Template1 from "../CardsAndCertificates/CardTemplates/Template1";
import Template2 from "../CardsAndCertificates/CardTemplates/Template2";
import Template3 from "../CardsAndCertificates/CardTemplates/Template3";

const CreateCards = () => {
  const [userType, setUserType] = useState("");
  const [validityDate, setValidityDate] = useState("");
  const [issuedDate, setIssuedDate] = useState("");
  const [digitalSignature, setDigitalSignature] = useState("");
  const [authorizedSignatureName, setAuthorizedSignatureName] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [isConfigured, setIsConfigured] = useState(false);
  const [schoolDetails, setSchoolDetails] = useState(null);

  const [template, setTemplate] = useState(null);

  const [userTypeData, setUserTypeData] = useState([
    {
      name: "Staff",
      value: "staff",
    },
    {
      name: "Student",
      value: "student",
    },
  ]);

  const [templateData, setTemplateData] = useState([
    {
      name: "Template 1",
      value: "temp1",
    },
    {
      name: "Template 2",
      value: "temp2",
    },
    {
      name: "Template 3",
      value: "temp3",
    },
  ]);

  const saveConfig = () => {
    let formData = new FormData();
    setShowSpinner(true);

    if (
      validityDate === "" ||
      template === "" ||
      template === null ||
      issuedDate === "" ||
      digitalSignature === "" ||
      userType === ""
    ) {
      swal("Error", "Enter all the required fields");
      setShowSpinner(false);

      return;
    } else {
      let data = {
        academicYearId: 1,
        issueDate: issuedDate,
        validTill: validityDate,
        authorizedSignatureName: authorizedSignatureName,
        cardType: userType,
        templateId: template === "temp1" ? 1 : template === "temp2" ? 2 : 3,
      };

      formData.append("cardData", JSON.stringify(data));
      formData.append("signature", digitalSignature);

      swal({
        title: "Are you sure the data is correct?",
        text: "This is a one time setup and cannot be changed later.",
        closeOnClickOutside: false,
        allowOutsideClick: false,
        buttons: true,
        dangerMode: true,
      }).then((isConfirm) => {
        if (isConfirm) {
          axiosPost(
            URL.insertIdCardConfig,
            formData,
            (response) => {
              if (response.data.status === 200) {
                setShowSpinner(false);
                // swal("Success", response.data.message);
                setIsConfigured(true);

                swal(
                  "Success",
                  "Identity card configurations saved successfully"
                );
                setDigitalSignature("");
              }
              //  else {
              //   swal("Error", response.data.message);
              //   setShowSpinner(false);
              // }
            },
            (err) => {
              displayErrorAlert(err);
              setShowSpinner(false);
            }
          );
        } else {
          setShowSpinner(false);
        }
      });
    }
  };

  const handleTemplateChange = (e) => {
    if (digitalSignature !== "") {
      setTemplate(e.target.value);
    } else {
      setTemplate("");
      swal("Error", "First add digital signature file to see preview");
    }
  };

  const handleChangeUserType = (e) => {
    setShowSpinner(true);
    setUserType(e.target.value);
    document.getElementById("signFile").value = "";
    setDigitalSignature("");
    axiosPost(URL.getIdCardConfig, { cardType: e.target.value }, (response) => {
      if (response.data.data.length > 0) {
        setIsConfigured(true);
        setIssuedDate(response.data.data[0].issueDate);
        setValidityDate(response.data.data[0].validTill);
        setTemplate(
          response.data.data[0].templateId === 1
            ? "temp1"
            : response.data.data[0].templateId === 2
              ? "temp2"
              : "temp3"
        );
        setAuthorizedSignatureName(
          response.data.data[0].authorizedSignatureName
        );
        setDigitalSignature("");
      
        setDigitalSignature(response.data.data[0].authorizedSignatureImagePath);

      } else {
        setDigitalSignature("");
        setIsConfigured(false);
        setIssuedDate("");
        setValidityDate("");
        setTemplate("");
        setAuthorizedSignatureName("");
      }

      setShowSpinner(false);
    });
  };

  useEffect(() => {
    axiosPost(URL.getSchoolByKey, {}, (response) => {
      if (response.status === 200) {
        setSchoolDetails(response.data.data);
        // this.setState({
        //   schoolInfos: response.data.data[0],
        //   countryId: response.data.data[0].countryId,
        //   isLoading: false,
        //   switchToggle: response.data.data[0].logoLocation,
        // });
      }
    });
  }, []);

  return (
    <>
      {showSpinner ? (
        <div className="fullWindow-Spinner">
          <div>
            <Spinner color="white"></Spinner>
          </div>
          <div style={{ fontSize: "16px", marginTop: "15px" }}>
            Please Wait!! Processing ...
          </div>
        </div>
      ) : null}
      <div
        className="card-setup-container tt-widgetContent-tab-holder"
        style={{ height: "79.5vh" }}
      >
        <div className="row">
          <div className="col-md-5">
            <div className="tt-group-header">Configuration</div>

            <div className="p-3">
              <div
                className="form-group row"
                style={{ marginBottom: "20px !important" }}
              >
                <div className="col-md-4">
                  <label htmlFor="userType">
                    {" "}
                    <b>
                      User Type :
                      <span className="tt-assessment-module-mandatory">*</span>
                    </b>
                  </label>
                </div>
                <div className="col-md-8">
                  <select
                    className="form-control"
                    onChange={(e) => handleChangeUserType(e)}
                    value={userType}
                  >
                    <option selected value="" disabled>
                      Choose user type
                    </option>
                    {userTypeData.map((ut, key) => (
                      <option value={ut.value}>{ut.name}</option>
                    ))}
                  </select>{" "}
                </div>
              </div>

              <div
                className="form-group row"
                style={{ marginBottom: "20px !important" }}
              >
                <div className="col-md-4">
                  <label htmlFor="issuedDate">
                    <b>
                      Issued Date:{" "}
                      <span className="tt-assessment-module-mandatory">*</span>
                    </b>{" "}
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    disabled={isConfigured ? true : false}
                    type="date"
                    className="form-control"
                    name="issuedDate"
                    onChange={(e) => setIssuedDate(e.target.value)}
                    value={issuedDate}
                  />
                </div>{" "}
              </div>

              <div
                className="form-group row"
                style={{ marginBottom: "20px !important" }}
              >
                <div className="col-md-4">
                  <label htmlFor="validityDate">
                    <b>
                      Validity Date:{" "}
                      <span className="tt-assessment-module-mandatory">*</span>{" "}
                    </b>{" "}
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    disabled={isConfigured || issuedDate === "" ? true : false}
                    type="date"
                    className="form-control"
                    name="validityDate"
                    onChange={(e) => setValidityDate(e.target.value)}
                    value={validityDate}
                    min={issuedDate}
                  />
                </div>{" "}
              </div>
              <div
                className="form-group row"
                style={{ marginBottom: "20px !important" }}
              >
                <div className="col-md-4">
                  <label htmlFor="digitalSignature">
                    <b>
                      Digital Signature:{" "}
                      <span className="tt-assessment-module-mandatory">*</span>{" "}
                    </b>
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    id="signFile"
                    accept="image/png, image/jpeg"
                    disabled={isConfigured ? true : false}
                    type="file"
                    className="form-control"
                    name="digitalSignature"
                    onChange={(e) => {
                      setDigitalSignature(e.target.files[0])
                    }}
                  />
                </div>{" "}
              </div>

              <div
                className="form-group row"
                style={{ marginBottom: "20px !important" }}
              >
                <div className="col-md-4">
                  <label htmlFor="digitalSignature">
                    <b>Authorized Signature Name: </b>
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    disabled={isConfigured ? true : false}
                    placeholder="Enter authorized person name"
                    type="text"
                    className="form-control"
                    name="authorizedSignatureName"
                    onChange={(e) => setAuthorizedSignatureName(e.target.value)}
                    value={authorizedSignatureName}
                  />
                </div>{" "}
              </div>

              <div
                className="form-group row"
                style={{ marginBottom: "20px !important" }}
              >
                <div className="col-md-4">
                  <label htmlFor="template">
                    <b>
                      Card Template:{" "}
                      <span className="tt-assessment-module-mandatory">*</span>
                    </b>{" "}
                  </label>
                </div>
                <div className="col-md-8">
                  <select
                    disabled={isConfigured ? true : false}
                    className="form-control"
                    onChange={(e) => handleTemplateChange(e)}
                    value={template}
                  >
                    <option selected>Choose template</option>
                    {templateData?.map((td) => (
                      <option value={td?.value}>{td?.name}</option>
                    ))}
                  </select>
                </div>
              </div>

              <button
                className="tt-button tt-button-primary"
                onClick={() => saveConfig()}
              disabled={isConfigured ? true : false}
              >
                Save Configuration
              </button>
            </div>
          </div>
          <div className="col-md-7">
            <div className="tt-group-header">Card Preview</div>
            <div className="card-preview" style={{ zoom: "90%" }}>
              {template === "temp1" ? (
                <Template1
                  validityDate={validityDate}
                  issuedDate={issuedDate}
                  authorizedSignatureName={authorizedSignatureName}
                  signature={
                    isConfigured
                      ? `${URL.imageSelectURL}${digitalSignature}`
                      : (digitalSignature &&
                        window.URL.createObjectURL(digitalSignature)) ||
                      null
                  }
                  schoolName={schoolDetails.name}
                  schoolAddress={schoolDetails.address}
                  schoolContact={schoolDetails.phoneNumber}
                  schoolWebAddress={schoolDetails.website}
                  schoolLogo={`${URL.imageSelectURL}${schoolDetails?.imageName}`}
                  emergencyContact={{
                    name: "Emergency Contact Name",
                    contactNumber: "Emergency Contact Number",
                  }}
                />
              ) : template === "temp2" ? (
                <Template2
                  validityDate={validityDate}
                  authorizedSignatureName={authorizedSignatureName}
                  issuedDate={issuedDate}
                  signature={
                    isConfigured
                      ? `${URL.imageSelectURL}${digitalSignature}`
                      : (digitalSignature &&
                        window.URL.createObjectURL(digitalSignature)) ||
                      null
                  }
                  schoolName={schoolDetails.name}
                  schoolAddress={schoolDetails.address}
                  schoolContact={schoolDetails.phoneNumber}
                  schoolWebAddress={schoolDetails.website}
                  schoolLogo={`${URL.imageSelectURL}${schoolDetails?.imageName}`}
                  emergencyContact={{
                    name: "Emergency Contact Name",
                    contactNumber: "Emergency Contact Number",
                  }}
                />
              ) : template === "temp3" ? (
                <Template3
                  validityDate={validityDate}
                  issuedDate={issuedDate}
                  authorizedSignatureName={authorizedSignatureName}
                  signature={
                    isConfigured
                      ? `${URL.imageSelectURL}${digitalSignature}`
                      : (digitalSignature &&
                        window.URL.createObjectURL(digitalSignature)) ||
                      null
                  }
                  schoolName={schoolDetails.name}
                  schoolAddress={schoolDetails.address}
                  schoolContact={schoolDetails.phoneNumber}
                  schoolWebAddress={schoolDetails.website}
                  schoolLogo={`${URL.imageSelectURL}${schoolDetails?.imageName}`}
                  emergencyContact={{
                    name: "Emergency Contact Name",
                    contactNumber: "Emergency Contact Number",
                  }}
                  userAddress="People Address"
                />
              ) : (
                <div className="text-center" style={{ fontSize: "1.5rem" }}>
                  Select template to see the preview
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCards;
