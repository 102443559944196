import React from 'react'

const SmsDetails = (props) => {
    let detailLog = props.detailLog;
    let sentStatus = props.sentStatus;
    let message = props.message;
    return (
        <>
            <div>
                <div className="row">
                    {detailLog.groups && detailLog.groups.length > 0 && (
                        <div className="col-6 mb-3">
                            <span>
                                <strong className="mr-2">Notify groups:</strong>

                                <div style={{ maxHeight: "200px" }}>
                                    <div className="tt-chipBox mt-2 mb-2">
                                        {detailLog.groups?.length > 0 &&
                                            detailLog.groups?.map((nd) => (
                                                <div className="tt-chipHolder mb-2">{nd.name}</div>
                                            ))}
                                    </div>
                                </div>
                            </span>
                        </div>
                    )
                    }
                    {detailLog.people && detailLog.people.length > 0 &&
                        (
                            <div className="col-6 mb-3">
                                <span>
                                    <strong className="mr-2">Notify peoples:</strong>
                                    <div className="tt-chipBox mt-2 mb-2">
                                        {detailLog.people?.length > 0 &&
                                            detailLog.people?.map((nd) => (
                                                <div className="tt-chipHolder mb-2">{nd.name}</div>
                                            ))}
                                    </div>
                                </span>
                            </div>
                        )
                    }
                </div>
                <div className="row">
                    <div className="col">
                        <strong className="mr-2">Message: </strong>
                        <p className="p-2 mt-2">{message}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SmsDetails
