/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, AdmissionForm, render the Admission Form with respect to the JSON input
 */

import React, { Component } from "react";
import AddressCluster from "../AddressCluster";
import IdentityCluster from "../IdentityCluster";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import YearLevelAcademicLevel from "../YearLevelAcademicLevel";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  URL,
  peopleCode,
  addressCode,
  regEx,
  draggable,
  checkWidgetAdminOrNot,
} from "../../../utils/Constants";
import swal from "@sweetalert/with-react";
import { connect } from "react-redux";
import {
  getAdmissionFormConfig,
  getAdmissionList,
  getAdmissionListPagination,
} from "../../../ducks/AdmissionDucks";
import "../../../assets/scss/admission.scss";
import ModalWindow from "./../../UI/ModalWindow";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import NoteDisplay from "../../../utils/noteDisplay";
import AddNewRelationShip from "./AddNewRelationShip";
import TableForm from "./TableForm";
import { cloneDeep } from "lodash";
import { MESSAGEHEADER } from "../../../utils/DisplayMessage";
import { emailValidate, mobilePhoneValidator } from "../../../utils/Utils";
import ImportAdmission from "../ImportAdmission";

class AdmissionForm extends Component {
  state = {
    admissionFormConfigurationStatus: false,
    admissionTableFieldGroups: [],
    studentContactTableFieldGroups: [],
    dynamicField: [],
    yearLevel: "",
    academicLevel: "",
    customTableFieldValues: [],
    customFields: [],
    errors: {
      yearLevel: false,
      academicLevel: false,
      admissionTableFieldGroups: {},
      studentContactTableFieldGroups: [{}],
    },
    countryFirstLevel: [],
    countrySecondLevel: [],
    countryThirdLevel: [],
    allergies: 0,
    deformity: 0,
    siblings: 0,
    transportation: 0,
    scholarship: 0,
    scholarshipType: "entrance",
    relationships: [],
    studentImage: "",
    studentContactImage: [],
    isSubmitting: false,
    yearLevels: [],
    isAvailable: false,
    relationshipModal: false,
    isSibling: false,
    isParent: false,
    relationshipName: "",
    render: false,
    isLoading: true,
    identityClusterDatePickerToggle: false,
    subFormDatePickerToggle: false,
    importModal: false,
  };

  toggleRelationshipModal = () => {
    this.setState({
      relationshipModal: !this.state.relationshipModal,
      isSibling: false,
      isParent: false,
      relationshipName: "",
    });
  };

  handleRelationShipChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  relationshipModalFooter = () => {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col text-right">
            <button
              className="tt-button tt-button-primary mr-3"
              onClick={(e) => this.submitRelationShip(e)}
            >
              Add
            </button>
            <button
              className="tt-button tt-button-primary"
              onClick={this.toggleRelationshipModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  submitRelationShip = (e) => {
    e.preventDefault();
    if (this.state.relationshipName !== "") {
      let data = {
        name: this.state.relationshipName,
        isSibling: this.state.isSibling,
        isParent: this.state.isParent,
      };
      axiosPost(URL.insertRelationship, data, (response) => {
        if (response.status === 200) {
          swal({
            title: "Success",
            text: "Relationship added successfully",
            closeOnClickOutside: false,
            allowOutsideClick: false,
          });
          draggable();
          this.getRelationships();
          this.toggleRelationshipModal();
        }
      });
    } else {
      swal({
        title: "Error",
        text: "Name cannot be empty",
        closeOnClickOutside: false,
        allowOutsideClick: false,
      });
      draggable();
    }
  };

  /**
   * This method is invoked immediately after a component is mounted (inserted into the tree) and it fetches all the Admission Configuration
   * from the database.
   *
   */
  componentDidMount() {
    const crudName = this.props.editMode ? "update" : "insert";
    const peopleId = this.props.peopleId;
    this.setState({ regEx });
    this.props.getAdmissionFormConfig(crudName, peopleId);
    axiosPost(
      URL.getIdentityCluster,
      {
        isConfig: false,
      },
      (response) => {
        if (response.status === 200) {
          let data = response.data.data;
          this.setState({ customFields: data });
        }
      }
    );
    axiosPost(
      URL.getAddressCluster,
      {
        isConfig: false,
      },
      (response) => {
        if (response.status === 200) {
          let data = response.data.data;
          this.setState({ addressCustomFields: data });
        }
      }
    );
    this.getRelationships();
    this.getYearLevels();
    if (this.props.editMode) {
      this.setUpdateConfiguration();
    }
  }

  setUpdateConfiguration = () => {
    let admissionData = this.props.admissionData;
    this.setState({
      academicLevel: admissionData.academicYearId,
      yearLevel: admissionData.yearLevelId,
      // studentImage: admissionData.people.photo,
      gender: admissionData.people.gender,
      nationality: admissionData.people.nationality,
      email: admissionData.people.emailAddress,
    });
  };

  getYearLevels = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        let yearLevels = response.data.data;
        this.setState(
          {
            yearLevels,
          },
          function () {
            this.checkAvaible();
          }
        );
      }
    });
  };

  checkAvaible = () => {
    if (this.state.yearLevels.length > 0) {
      this.setState({ isAvailable: true, render: true });
    } else {
      this.setState({ render: true });
    }
  };

  getRelationships = () => {
    axiosPost(URL.getRelationships, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({ relationships: data });
      }
    });
  };

  componentDidUpdate(nextProps, prevState) {
    if (
      nextProps.admissionFormConfigurationStatus !==
      this.props.admissionFormConfigurationStatus
    ) {
      this.setState({
        admissionFormConfigurationStatus:
          this.props.admissionFormConfigurationStatus,
        isLoading: false,
      });
    }

    if (
      nextProps.admissionTableFieldGroups !==
      this.props.admissionTableFieldGroups
    ) {
      this.setState(
        {
          admissionTableFieldGroups: this.props.admissionTableFieldGroups,
          isLoading: false,
        },
        async () => {
          if (this.props.editMode) {
            await this.setUpdateCustomFieldValues("admissionTableFieldGroups");
          }
        }
      );
    }
    if (
      nextProps.studentContactTableFieldGroups !==
      this.props.studentContactTableFieldGroups
    ) {
      let errors = { ...this.state.errors };
      errors.studentContactTableFieldGroups = [];
      this.props.studentContactTableFieldGroups.forEach((group) => {
        errors.studentContactTableFieldGroups.push({});
      });
      this.setState(
        {
          studentContactTableFieldGroups:
            this.props.studentContactTableFieldGroups,
          isLoading: false,
          errors,
        },
        async () => {
          if (this.props.editMode) {
            await this.setUpdateCustomFieldValues(
              "studentContactTableFieldGroups"
            );
          }
        }
      );
    }
    if (nextProps.dynamicField !== this.props.dynamicField) {
      this.setState({ dynamicField: this.props.dynamicField });
    }
  }

  setUpdateCustomFieldValues = (stateName) => {
    let fieldGroups = [...this.state[stateName]];
    if (fieldGroups.length > 0) {
      fieldGroups.forEach((el, elIdx) => {
        let admissionData;
        let people;
        if (stateName === "studentContactTableFieldGroups") {
          admissionData = this.props.admissionData.studentContacts[elIdx];
          people = admissionData.contactPeople;
          el["Is Legal Guardian"] = admissionData.isLegalGuardian;
          el["Relationship"] = admissionData.relationshipId;
          el.contactPeopleId = admissionData.contactPeopleId;
        } else {
          admissionData = this.props.admissionData;
          people = admissionData.people;
        }
        if (el.customTableFields.length > 0) {
          el.customTableFields.forEach((field) => {
            if (field.fieldTypeCode === "pre-peop") {
              field.gender = people.gender;
              field.nationality = people.nationality;
              field.email = people.emailAddress;
              field.year = new Date(people.dateOfBirth).getFullYear();
              field.month = new Date(people.dateOfBirth).getMonth() + 1;
              field.day = new Date(people.dateOfBirth).getDate();

              if (field.customTableFieldValues) {
                field.customTableFieldValues.forEach((v) => {
                  field[v.customTableFieldName] = v.value;
                });
              }
            }
            if (field.fieldTypeCode === "table") {
              field.customFieldTableColumns.forEach((column) => {
                if (column.customFieldTableSubColumns.length === 0) {
                  column &&
                    column?.customFieldTableColumnValues.length > 0 &&
                    column.customFieldTableColumnValues.forEach((value) => {
                      const index = field.rows.findIndex(
                        (row) => row.rowId === value.rowId
                      );
                      if (index !== -1) {
                        field.rows[index][column.name] = value.value;
                      } else {
                        field.rows.push({
                          rowId: value.rowId,
                          [column.name]: value.value,
                        });
                      }
                    });
                } else {
                  column.customFieldTableSubColumns.forEach((subCol) => {
                    subCol.customFieldTableColumnValues.forEach((value) => {
                      const index = column.rows.findIndex(
                        (row) => row.rowId === value.rowId
                      );
                      if (index !== -1) {
                        column.rows[index][subCol.name] = value.value;
                      } else {
                        column.rows.push({
                          rowId: value.rowId,
                          [subCol.name]: value.value,
                        });
                      }
                    });
                  });
                }
              });
            }
            if (field.fieldTypeCode === "radio") {
              field[`value${field.id}`] = field.value;
            }
          });
        }
      });
    }
    this.setState({ [stateName]: fieldGroups }, async () => {
      let newFieldGroups = [...this.state[stateName]];

      //the use of gold old for loop is to keep the async await working inside a loop;
      // cause forEach failed to do so
      for (let i = 0; i < newFieldGroups.length; i++) {
        let group = newFieldGroups[i];
        let admissionData;
        let people;
        if (stateName === "studentContactTableFieldGroups") {
          admissionData = this.props.admissionData.studentContacts[i];
          people = admissionData.contactPeople;
        } else {
          admissionData = this.props.admissionData;
          people = admissionData.people;
        }
        const customTableFields = group.customTableFields;
        for (let j = 0; j < customTableFields.length; j++) {
          let customTableField = customTableFields[j];

          if (customTableField.customTableFieldValues) {
            customTableField.customTableFieldValues.forEach((v) => {
              customTableField[v.customTableFieldName] = v.value;
            });
          }

          for (let k = 0; k < people?.peopleAddresses?.length; k++) {
            let addressField = people.peopleAddresses[k];
            if (
              customTableField.id === addressField.customTableFieldId &&
              customTableField.fieldTypeCode === "pre-addr"
            ) {
              customTableField.country = addressField.countryId;
              customTableField.countryFirstLevelId =
                addressField.countryFirstLevelId;
              customTableField.countrySecondLevelId =
                addressField.countrySecondLevelId;
              customTableField.countryThirdLevelId =
                addressField.countryThirdLevelId;
              const countryFirstLevelPromise = new Promise((res, rej) => {
                axiosPost(
                  URL.getCountryFirstLevel,
                  {
                    countryId: addressField.countryId,
                  },
                  (response) => {
                    if (response.status === 200) {
                      res(response.data.data);
                    }
                  }
                );
              });
              const countryFirstLevel = await countryFirstLevelPromise;
              if (countryFirstLevel) {
                customTableField.countryFirstLevel = countryFirstLevel;
              }
              const countrySecondLevelPromise = new Promise((res, rej) => {
                axiosPost(
                  URL.getCountrySecondLevel,
                  {
                    countryId: addressField.countryId,
                    countryFirstLevelId: addressField.countryFirstLevelId,
                  },
                  (response) => {
                    if (response.status === 200) {
                      res(response.data.data);
                    }
                  }
                );
              });
              const countrySecondLevel = await countrySecondLevelPromise;
              if (countrySecondLevel) {
                customTableField.countrySecondLevel = countrySecondLevel;
              }
              const countryThirdLevelPromise = new Promise((res, rej) => {
                axiosPost(
                  URL.getCountryThirdLevel,
                  {
                    countryId: addressField.countryId,
                    countryFirstLevelId: addressField.countryFirstLevelId,
                    countrySecondLevelId: addressField.countrySecondLevelId,
                  },
                  (response) => {
                    if (response.status === 200) {
                      res(response.data.data);
                    }
                  }
                );
              });
              const countryThirdLevel = await countryThirdLevelPromise;
              if (countryThirdLevel) {
                customTableField.countryThirdLevel = countryThirdLevel;
              }
            }
          }
        }
      }

      this.setState({
        [stateName]: newFieldGroups,
      });
    });
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let errors = { ...this.state.errors };
    if (value.length === 0) {
      errors[name] = true;
    } else {
      errors[name] = false;
    }
    this.setState({ errors }, () => {
      this.setState({
        [name]: value,
      });
    });
  };

  handleFetchingLevels = () => {
    this.setState({ fetchingLevels: !this.state.fetchingLevels });
  };

  mobilePhoneValidatorNepal = (num) => {
    const r = /^(?:\+977[- ]?|0)?9\d{9}$/;
    return r.test(num);
  };


  /**
   * This method is invoked on clicking submit button
   */
  submit = (e) => {
    e.preventDefault();
    //validation for mandatory fields
    let errorFields = [];
    let newErrors = { ...this.state.errors };
    if (this.state.yearLevel === "") {
      errorFields.push("Class");
      newErrors.yearLevel = true;
    }
    if (this.state.academicLevel === "") {
      errorFields.push("Academic level");
      newErrors.academicLevel = true;
    }
    let newAdmissionTableFieldGroups = [
      ...this.state.admissionTableFieldGroups,
    ],
      newStudentContactTableFieldGroups = [
        ...this.state.studentContactTableFieldGroups,
      ];

    newAdmissionTableFieldGroups.forEach((config) => {
      config.customTableFields.forEach((field) => {

        if (
          field.fieldTypeCode !== addressCode &&
          field.fieldTypeCode !== peopleCode
        ) {
          if (field.isMandatory && field.fieldTypeCode === "multi-checkbox") {
            let checkData = field.customTableFieldDatas.length,
              trueCount = 0;
            if (checkData > 0) {
              field.customTableFieldDatas.forEach((el) => {
                if (el.isChecked) {
                  trueCount++;
                }
              });
              if (trueCount === 0) {
                errorFields.push(`${field.fieldName} `);
                field.error = true;
              }
            }
          } else if (
            field.isMandatory &&
            !field.value
            // ("value" + field.id === " " ||
            //   "value" + field.id === null ||
            //   "value" + field.id === undefined ||
            //   "data" + field.id === " " ||
            //   "data" + field.id === null ||
            //   "data" + field.id === undefined)
          ) {
            errorFields.push(`Student's ${field.fieldName} `);
            field.error = true;
          }
        }
        if (field.fieldTypeCode === peopleCode) {
          field.error = {};
          this.state.customFields.forEach((cField) => {
            // let keys = Object.keys(field);
            if (field[cField.fieldName] === undefined && cField.isMandatory) {
              errorFields.push(`Student's ${cField.fieldName} `);
              field.error[cField.fieldName] = true;
            }
          });
        }
        if (field.fieldTypeCode === addressCode) {
          field.error = {};

          if (!field.country || field.country == "" || field.country === null) {
            errorFields.push("Student's Country");
            field.error.stdCountry = true;
          } else {
            if (field.countryFirstLevelId === "" && field.countryFirstLevelId.length > 0) {
              errorFields.push("Student's Province");
              field.error.stdProvince = true;
            }
            if (field.countrySecondLevelId === "" && field.countrySecondLevelId.length > 0) {
              errorFields.push("Student's District");
              field.error.stdDistrict = true;
            }
            if (field.countryThirdLevelId === "" && field.countryThirdLevel.length > 0) {
              errorFields.push("Student's City");
              field.error.stdCity = true;
            }
          }
          this.state.addressCustomFields.forEach((aField) => {
            // let keys = Object.keys(field);
            if (field[aField.fieldName] === undefined && aField.isMandatory) {
              errorFields.push(`${aField.fieldName} `);
              field.error[aField.fieldName] = true;
            }
          });
        }
      });
    });

    newStudentContactTableFieldGroups.forEach((config, idx) => {
      config.customTableFields.forEach((field) => {
        if (
          field.fieldTypeCode !== addressCode &&
          field.fieldTypeCode !== peopleCode
        ) {
          if (
            field.isMandatory &&
            (field.value === "" || field.value === null)
          ) {
            errorFields.push(`Student Contact ${field.fieldName} `);
            field.error = true;
          }
        }
        if (field.fieldTypeCode === peopleCode) {
          field.error = {};
          this.state.customFields.forEach((cField) => {
            // let keys = Object.keys(field);
            if (field[cField.fieldName] === undefined && cField.isMandatory) {
              errorFields.push(`Student Contact ${cField.fieldName} `);
              field.error[cField.fieldName] = true;
            }
          });
          if (!field.year) {
            newErrors.studentContactTableFieldGroups[idx].year = true;
          }

          if (!field.month) {
            // errorFields.push("Student's date of birth");
            newErrors.studentContactTableFieldGroups[idx].month = true;
          }
          if (!field.day) {
            // errorFields.push("Student's date of birth");
            newErrors.studentContactTableFieldGroups[idx].day = true;
          }

          if (!field.year || !field.month || !field.day) {
            errorFields.push("Student Contact date of birth");
          }
          if (!field.gender) {
            errorFields.push("Student Contact gender");
            newErrors.studentContactTableFieldGroups[idx].gender = true;
          } else {
            newErrors.studentContactTableFieldGroups[idx].gender = false;
          }
          if (
            field.mobileNumber &&
            (field.mobileNumber !== null || field.mobileNumber !== "") &&
            !this.mobilePhoneValidatorNepal(field.mobileNumber)
          ) {
            swal({
              title: "Warning",
              text: "Please insert valid mobile number.1",
            });
            return;
          }

          if (field.nationality === null || field.nationality === undefined) {
            errorFields.push("Student Contact nationality");
            newErrors.studentContactTableFieldGroups[idx].nationality = true;
          } else {
            newErrors.studentContactTableFieldGroups[idx].nationality = false;
          }
          if (!field.email) {
            // errorFields.push("Student Contact email");
            newErrors.studentContactTableFieldGroups[idx].email = true;
          } else {
            newErrors.studentContactTableFieldGroups[idx].email = false;
          }
        }
        if (field.fieldTypeCode === addressCode) {
          field.error = {};
          if (!field.country || field.country == "" || field.country === null) {
            errorFields.push("Student Contact  Country");
            field.error.stdCountry = true;
          } else {
            if (field.countryFirstLevelId === "" && field.countryFirstLevelId.length > 0) {
              errorFields.push("Student Contact Province");
              field.error.stdProvince = true;
            }
            if (field.countrySecondLevelId === "" && field.countrySecondLevelId.length > 0) {
              errorFields.push("Student Contact District");
              field.error.stdDistrict = true;
            }
            if (field.countryThirdLevelId === "" && field.countryThirdLevel.length > 0) {
              errorFields.push("Student Contact City");
              field.error.stdCity = true;
            }
          }
          this.state.addressCustomFields.forEach((aField) => {
            // let keys = Object.keys(field);
            if (field[aField.fieldName] === undefined && aField.isMandatory) {
              errorFields.push(`Student Contact ${aField.fieldName} `);

              field.error[aField.fieldName] = true;
            }
          });
        }
      });
      if (config.Relationship === "") {
        errorFields.push("Student Contact relationship");
        newErrors.studentContactTableFieldGroups[idx].Relationship = true;
      } else {
        newErrors.studentContactTableFieldGroups[idx].Relationship = false;
      }
    });

    for (let i = 0; i < this.state.admissionTableFieldGroups.length; i++) {
      for (
        let j = 0;
        j < this.state.admissionTableFieldGroups[i].customTableFields.length;
        j++
      ) {
        const field =
          this.state.admissionTableFieldGroups[i].customTableFields[j];
        if (
          this.state.admissionTableFieldGroups[i].customTableFields[j]
            .fieldTypeCode === peopleCode
        ) {
          if (!field.year) {
            newErrors.admissionTableFieldGroups.year = true;
          }
          if (!field.month) {
            newErrors.admissionTableFieldGroups.month = true;
          }
          if (!field.day) {
            newErrors.admissionTableFieldGroups.day = true;
          }

          if (!field.year || !field.month || !field.day) {
            errorFields.push("Student's date of birth");
          }
          if (!field.gender) {
            errorFields.push("Student's gender");
            newErrors.admissionTableFieldGroups.gender = true;
          } else {
            newErrors.admissionTableFieldGroups.gender = false;
          }
          if (
            field.mobileNumber &&
            (field.mobileNumber !== null || field.mobileNumber !== "") &&
            !this.mobilePhoneValidatorNepal(field.mobileNumber)
            // !mobilePhoneValidator(field.mobileNumber)
          ) {
            swal({
              title: "Warning",
              text: "Please insert valid mobile number.2",
            });
            return;
          }

          // if (!field.email) {
          //   // errorFields.push("Student Contact email");
          //   newErrors.admissionTableFieldGroups[i].email = true;
          // } else {
          //   newErrors.admissionTableFieldGroups[i].email = false;
          // }

          if (field.nationality === null || field.nationality === undefined) {
            errorFields.push("Student's nationality");
            newErrors.admissionTableFieldGroups.nationality = true;
          } else {
            newErrors.admissionTableFieldGroups.nationality = false;
          }

          // if (
          //   field.year === null ||
          //   field.month === null ||
          //   field.day === null
          // ) {
          //   swal({
          //     title: "Warning",
          //     text: "Please insert student's date of birth. ",
          //   });
          //   return;
          // }
          // if (field.gender === null || field.gender === "") {
          //   swal({
          //     title: "Warning",
          //     text: "Please insert student's gender.",
          //   });
          //   return;
          // }
          // if (
          //   field.mobileNumber &&
          //   (field.mobileNumber !== null || field.mobileNumber !== "") &&
          //   !mobilePhoneValidator(field.mobileNumber)
          // ) {
          //   swal({
          //     title: "Warning",
          //     text: "Please insert valid mobile number.",
          //   });
          //   return;
          // }
          // if (field.nationality === null || field.nationality === undefined) {
          //   swal({
          //     title: "Warning",
          //     text: "Please insert student's nationality.",
          //   });
          //   return;
          // }
        }
        // if (
        //   this.state.admissionTableFieldGroups[i].customTableFields[j]
        //     .fieldTypeCode === addressCode
        // ) {
        //   if (field.country === null || field.country === undefined) {
        //     swal({
        //       title: "Warning",
        //       text: "Please insert student's country.",
        //     });
        //     return;
        //   }
        // }
      }
    }

    for(let i=0;i<this.state.studentContactTableFieldGroups.length;i++){
      for(let j=0;j<this.state.studentContactTableFieldGroups[i].customTableFields.length;j++){
        const contactFields = this.state.studentContactTableFieldGroups[i].customTableFields[j];
        if( contactFields.mobileNumber &&
          (contactFields.mobileNumber !== null || contactFields.mobileNumber !== "") &&
          !this.mobilePhoneValidatorNepal(contactFields.mobileNumber)){
            swal({
              title: "Warning",
              text: "Please insert valid mobile number.3",
            });
            return;
          }
      }
    }

    if (errorFields.length > 0) {
      swal({
        title: `${errorFields.length} mandatory fields are empty.`,
        content: (
          <div className="row">
            {errorFields.map((field, sIdx) => (
              <div className="col-md-6">
                <ul className="text-left">
                  <li key={sIdx}>{field}</li>
                </ul>
              </div>
            ))}
          </div>
        ),
        dangerMode: true,
      });
      this.setState({
        errors: newErrors,
        admissionTableFieldGroups: newAdmissionTableFieldGroups,
        studentContactTableFieldGroups: newStudentContactTableFieldGroups,
      });
      return;
    }

    //static validation for fields in identity and address clusters
    //student

    // for (let i = 0; i < this.state.admissionTableFieldGroups.length; i++) {
    //   for (
    //     let j = 0;
    //     j < this.state.admissionTableFieldGroups[i].customTableFields.length;
    //     j++
    //   ) {
    //     const field =
    //       this.state.admissionTableFieldGroups[i].customTableFields[j];
    //     if (
    //       this.state.admissionTableFieldGroups[i].customTableFields[j]
    //         .fieldTypeCode === peopleCode
    //     ) {
    //       if (
    //         field.year === null ||
    //         field.month === null ||
    //         field.day === null
    //       ) {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert student's date of birth. ",
    //         });

    //         return;
    //       }
    //       if (field.gender === null || field.gender === "") {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert student's gender.",
    //         });
    //         return;
    //       }

    //       if (
    //         field.mobileNumber &&
    //         (field.mobileNumber !== null || field.mobileNumber !== "") &&
    //         !mobilePhoneValidator(field.mobileNumber)
    //       ) {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert valid mobile number.",
    //         });
    //         return;
    //       }

    //       if (field.nationality === null || field.nationality === undefined) {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert student's nationality.",
    //         });
    //         return;
    //       }
    //     }
    //     if (
    //       this.state.admissionTableFieldGroups[i].customTableFields[j]
    //         .fieldTypeCode === addressCode
    //     ) {
    //       if (field.country === null || field.country === undefined) {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert student's country.",
    //         });
    //         return;
    //       }
    //     }
    //   }
    // }
    //for student contact
    // for (let i = 0; i < this.state.studentContactTableFieldGroups.length; i++) {
    //   for (
    //     let j = 0;
    //     j <
    //     this.state.studentContactTableFieldGroups[i].customTableFields.length;
    //     j++
    //   ) {
    //     const field =
    //       this.state.studentContactTableFieldGroups[i].customTableFields[j];
    //     if (
    //       this.state.studentContactTableFieldGroups[i].customTableFields[j]
    //         .fieldTypeCode === peopleCode
    //     ) {
    //       if (
    //         field.year === null ||
    //         field.month === null ||
    //         field.day === null
    //       ) {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert student contact's date of birth. ",
    //         });
    //         return;
    //       }

    //       if (field.email !== "" || field.email !== null) {
    //         if (!emailValidate(field.email)) {
    //           swal({
    //             title: "Warning",
    //             text: "Please enter valid email address for student contact.",
    //           });
    //           return;
    //         }
    //       }

    //       if (field.gender === null || field.gender === "") {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert student contact's gender.",
    //         });
    //         return;
    //       }

    //       if (
    //         this.state.studentContactTableFieldGroups[i]["Is Legal Guardian"] &&
    //         (field.mobileNumber === null || field.mobileNumber === "")
    //       ) {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert legal guardian's mobile number.",
    //         });
    //         return;
    //       }

    //       if (
    //         field.mobileNumber &&
    //         (field.mobileNumber !== null || field.mobileNumber !== "") &&
    //         !mobilePhoneValidator(field.mobileNumber)
    //       ) {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert valid legal guardian's mobile number.",
    //         });
    //         return;
    //       }

    //       if (field.nationality === null || field.nationality === undefined) {
    //         swal({
    //           title: "Warning",
    //           text: "Please insert student contact's nationality.",
    //         });
    //         return;
    //       }
    //       // if (field.email === null || field.email === undefined) {
    //       //   swal({
    //       //     title: "Warning",
    //       //     text: "Please insert student contact's email address.",
    //       //   });
    //       //   return;
    //       // }
    //     }
    //     // if (
    //     //   this.state.studentContactTableFieldGroups[i].customTableFields[j]
    //     //     .fieldTypeCode === addressCode
    //     // ) {
    //     //   if (field.country === null || field.country === undefined) {
    //     //     swal({
    //     //       title: "Warning",
    //     //       text: "Please insert student contact's country.",
    //     //     });
    //     //     return;
    //     //   }
    //     // }
    //   }
    // }

    //for image
    // if (
    //   (this.state.studentImage === null || this.state.studentImage === "") &&
    //   !this.props.editMode
    // ) {
    //   swal({
    //     title: "Warning",
    //     text: "Please insert photo of the student.",
    //   });
    //   return;
    // }

    let data = {},
      people = {};
    people.name = "";
    if (this.props.editMode) {
      data.id = this.props.admissionId;
      data.admissionStatusId = this.props.admissionStatusId;
      people.id = this.props.peopleId;
    }

    people.peopleAddresses = [];
    let customTableFieldValues = [],
      studentCustomTableFieldValues = [],
      studentContacts = [],
      addressCustomTableFieldValues = [],
      pushToAddress = {};
      this.state.admissionTableFieldGroups.forEach((group) => {
        data.yearLevelId = parseInt(this.state.yearLevel);
        data.academicYearId = parseInt(this.state.academicLevel);
        group.customTableFields.forEach((field) => {
        if (
          field.fieldTypeCode !== peopleCode &&
          field.fieldTypeCode !== addressCode
        ) {
          if (field.isMulti && field.fieldTypeCode === "multi-checkbox") {
            let value = [];

            field.customTableFieldDatas.forEach((data) => {
              if (data.isChecked) {
                value.push(data.data);
              }
            });
            customTableFieldValues.push({
              dbTableId: field.dbTableId,
              customTableFieldId: field.id,
              value: value.join(","),
              groupId: group.id,
            });
          } else if (field.isMulti && field.fieldTypeCode === "radio") {
            customTableFieldValues.push({
              dbTableId: field.dbTableId,
              customTableFieldId: field.id,
              value: field[`value${field.id}`],
              groupId: group.id,
            });
          } else {
            customTableFieldValues.push({
              dbTableId: field.dbTableId,
              customTableFieldId: field.id,
              value: field.value,
              groupId: group.id,
            });
          }
        }
        if (field.fieldTypeCode === peopleCode) {
          people.dateOfBirth = new Date(
            `${field.year}-${field.month}-${field.day}`
          );
          people.gender = field.gender;
          people.nationality = field.nationality;
          people.photo = field.photo;
          people.mobileNumber = field.mobileNumber;
          people.emailAddress=field.email
          // people.
          this.state.customFields.forEach((cField) => {
            let keys = Object.keys(field);
            keys.forEach((key) => {
              if (cField.fieldName === key) {
                people.name = people.name.concat(`${field[key]} `);
                customTableFieldValues.push({
                  dbTableId: cField.dbTableId,
                  customTableFieldId: cField.id,
                  value: field[key],
                  groupId: group.id,
                });
              }
            });
          });
        }
        if (field.fieldTypeCode === addressCode) {
          addressCustomTableFieldValues = [];
          pushToAddress = {};
          pushToAddress.countryId = field.country;
          pushToAddress.countryFirstLevelId = field.countryFirstLevelId;
          pushToAddress.countrySecondLevelId = field.countrySecondLevelId;
          pushToAddress.countryThirdLevelId = field.countryThirdLevelId;
          pushToAddress.customTableFieldId = field.id;
          this.state.addressCustomFields.forEach((adCField) => {
            let keys = Object.keys(field);
            keys.forEach((key) => {
              if (adCField.fieldName === key) {
                addressCustomTableFieldValues.push({
                  dbTableId: adCField.dbTableId,
                  customTableFieldId: adCField.id,
                  value: field[key],
                  groupId: group.id,
                });
              }
            });
          });
          pushToAddress.addressCustomTableFieldValues =
            addressCustomTableFieldValues;
          people.peopleAddresses.push(pushToAddress);
        }
        if (field.fieldTypeCode === "table") {
          let customFieldTableColumnValues = [];
          field.customFieldTableColumns.forEach((column) => {
            if (column.customFieldTableSubColumns?.length === 0) {
              field.rows.forEach((row, rowIndex) => {
                if (row[column.name] && column.fieldTypeCode === "checkbox") {
                  customFieldTableColumnValues.push({
                    rowId: row.rowId,
                    columnId: column.id,
                    groupId: group.id,
                    value: row[column.name],
                  });
                } else if (
                  row[column.name] === undefined &&
                  column.fieldTypeCode === "checkbox"
                ) {
                  customFieldTableColumnValues.push({
                    rowId: row.rowId,
                    columnId: column.id,
                    groupId: group.id,
                    value: false,
                  });
                } else if (
                  column.fieldTypeCode === "radio" &&
                  row[`${column.name}${rowIndex}`]
                ) {
                  customFieldTableColumnValues.push({
                    rowId: row.rowId,
                    columnId: column.id,
                    groupId: group.id,
                    value: row[`${column.name}${rowIndex}`],
                  });
                } else if (row[column.name]) {
                  customFieldTableColumnValues.push({
                    rowId: row.rowId,
                    columnId: column.id,
                    groupId: group.id,
                    value: row[column.name],
                  });
                }
              });
            } else {
              column.customFieldTableSubColumns.forEach((subCol) => {
                column.rows.forEach((row, rowIndex) => {
                  if (row[subCol.name]) {
                    customFieldTableColumnValues.push({
                      rowId: row.rowId,
                      columnId: subCol.id,
                      groupId: group.id,
                      value: row[subCol.name],
                    });
                  }
                  //add code for checkbox if value is needed as false
                  else if (
                    row[subCol.name] === undefined &&
                    subCol.fieldTypeCode === "checkbox"
                  ) {
                    customFieldTableColumnValues.push({
                      rowId: row.rowId,
                      columnId: subCol.id,
                      groupId: group.id,
                      value: false,
                    });
                  } else if (
                    subCol.fieldTypeCode === "radio" &&
                    row[`${subCol.name}${rowIndex}`]
                  ) {
                    customFieldTableColumnValues.push({
                      rowId: row.rowId,
                      columnId: subCol.id,
                      groupId: group.id,
                      value: row[`${subCol.name}${rowIndex}`],
                    });
                  } else if (row[column.name]) {
                    customFieldTableColumnValues.push({
                      rowId: row.rowId,
                      columnId: column.id,
                      groupId: group.id,
                      value: row[column.name],
                    });
                  }
                });
              });
            }
          });
          customTableFieldValues.push({
            dbTableId: field.dbTableId,
            customTableFieldId: field.id,
            fieldTypeCode: "table",
            groupId: group.id,
            customFieldTableColumnValues: customFieldTableColumnValues,
          });
        }
      });
    });
    people.customTableFieldValues = customTableFieldValues;
    data.people = people;

    //for student contacts
    this.state.studentContactTableFieldGroups.forEach((group) => {
      let obj = {};
      let contactPeople = {};
      contactPeople.name = "";
      contactPeople.id = group.contactPeopleId;
      contactPeople.emailAddress = "";
      contactPeople.peopleAddresses = [];
      studentCustomTableFieldValues = [];
      let addressCustomTableFieldValues = [],
        pushToAddress = {};
      // contactPeople.emailAddress = group.emailAddress;
      obj.relationship = group.Relationship;
      obj.isLegalGuardian = group["Is Legal Guardian"];
      obj.isNew = group.new || false;
      group.customTableFields.forEach((field) => {
        if (
          field.fieldTypeCode !== peopleCode &&
          field.fieldTypeCode !== addressCode
        ) {
          if (field.isMulti && field.fieldTypeCode === "multi-checkbox") {
            let value = [];
            field.customTableFieldDatas.forEach((data) => {
              if (field[`data${field.id}`]) {
                value.push(data.data);
              }
            });
            studentCustomTableFieldValues.push({
              customTableFieldId: field.id,
              value: value.join(","),
              groupId: group.id,
            });
          } else if (field.isMulti && field.fieldTypeCode === "radio") {
            studentCustomTableFieldValues.push({
              customTableFieldId: field.id,
              value: field[`value${field.id}`],
              groupId: group.id,
            });
          } else {
            studentCustomTableFieldValues.push({
              customTableFieldId: field.id,
              value: field.value,
              groupId: group.id,
            });
          }
        }
        if (field.fieldTypeCode === peopleCode) {
          contactPeople.nationality = field.nationality;
          contactPeople.dateOfBirth = new Date(
            `${field.year}-${field.month}-${field.day}`
          );
          contactPeople.gender = field.gender;
          contactPeople.mobileNumber = field.mobileNumber;
          contactPeople.photo = field.photo;
          contactPeople.emailAddress = field.email;
          this.state.customFields.forEach((cField) => {
            let keys = Object.keys(field);
            keys.forEach((key) => {
              if (cField.fieldName === key) {
                contactPeople.name = contactPeople.name.concat(
                  `${field[key]} `
                );
                studentCustomTableFieldValues.push({
                  dbTableId: cField.dbTableId,
                  customTableFieldId: cField.id,
                  value: field[key],
                  groupId: group.id,
                });
              }
            });
          });
        }
        if (field.fieldTypeCode === addressCode) {
          addressCustomTableFieldValues = [];
          pushToAddress = {};
          pushToAddress.countryId = field.country;
          pushToAddress.countryFirstLevelId = field.countryFirstLevelId;
          pushToAddress.countrySecondLevelId = field.countrySecondLevelId;
          pushToAddress.countryThirdLevelId = field.countryThirdLevelId;
          pushToAddress.customTableFieldId = field.id;
          this.state.addressCustomFields.forEach((adCField) => {
            let keys = Object.keys(field);
            keys.forEach((key) => {
              if (adCField.fieldName === key) {
                addressCustomTableFieldValues.push({
                  dbTableId: adCField.dbTableId,
                  customTableFieldId: adCField.id,
                  value: field[key],
                  groupId: group.id,
                });
              }
            });
          });
          pushToAddress.addressCustomTableFieldValues =
            addressCustomTableFieldValues;
          contactPeople.peopleAddresses.push(pushToAddress);
        }

        contactPeople.customTableFieldValues = studentCustomTableFieldValues;
        obj.contactPeople = contactPeople;
      });
      studentContacts.push(obj);
    });

    data.studentContacts = studentContacts;

    //validation for static part in clusters

    let formData = new FormData();
    formData.append("admission", JSON.stringify(data));
    formData.append("studentImage", this.state.studentImage);
    for (let i = 0; i < this.state.studentContactImage.length; i++) {
      formData.append(`studentContactImage`, this.state.studentContactImage[i]);
    }

    const endPoint = this.props.editMode
      ? URL.admissionFormUpdate
      : URL.admissionFormInsert;
    const message = this.props.editMode
      ? "Student successfully updated."
      : "Student successfully inserted.";
      this.setState({ isSubmitting: true }, () => {
        axiosPost(
          endPoint,
          formData,
          (response) => {
            if (response.status === 200) {
              swal({
                title: "Success",
                text: message,
                allowOutsideClick: false,
                closeOnClickOutside: false,
              });
              draggable();
              this.getAdmissionStatus();
              this.props.editMode && this.props.toggleEditModal();
              this.props.editMode &&
                this.props.handleAdmissionSearch("noAlphabet");
              let newErrors = JSON.parse(JSON.stringify(this.state.errors));
              newErrors.yearLevel = false;
              newErrors.academicLevel = false;
              newErrors.admissionTableFieldGroups = {};
              newErrors.studentContactTableFieldGroups = [{}];
              this.setState(
                {
                  yearLevel: !this.props.editMode ? "" : this.state.yearLevel,
                  academicLevel: !this.props.editMode
                    ? ""
                    : this.state.academicLevel,
                  isSubmitting: false,
                  errors: newErrors,
                },
                () => {
                  const crudName = this.props.editMode ? "update" : "insert";
                  const peopleId = this.props.peopleId;
                  !this.props.editMode && this.props.getAdmissionFormConfig(crudName,peopleId);
                }
              );
            }
          },
          (err) => {
            this.setState({
              isSubmitting: false,
            });
            swal({
              title: "Error",
              text: `${err.response.data.message}`,
              allowOutsideClick: false,
              closeOnClickOutside: false,
            });
          }
        );
      });

    };

  getAdmissionStatus = () => {
    // const params = {
    //   isVisible: true,
    // };
    axiosPost(URL.getAdmissionStatus, {}, (response) => {
      if (response.status === 200) {
        this.setState({ admissionStatuses: response.data.data }, function () {
          let status = this.state.admissionStatuses;
          let id = "";
          status.forEach((el) => {
            if (el.name === "Current") {
              id = el.id;
            }
          });
          this.setState({ admissionStatusId: id }, function () {
            !this.props.editMode &&
              this.props.getAdmissionListPagination(
                this.state.admissionStatusId,
                0,
                20
              );
          });
        });
      }
    });
  };

  /**
   * This methods binds the input fields with its respective state
   * @param e is the synthentic event
   * @param customTableField is the Custom Table Field Id associted with the tuple
   * @param dbTableId is the db Table Id associted with the tuple
   */
  handleAddressClusterChange = (e, customTableFieldId, dbTableId) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  /**
   * Duplicate Student Contact Form
   */
  handleDuplicateStudentContactForm = (e) => {
    let studentContactTableFieldGroups = [
      ...this.state.studentContactTableFieldGroups,
    ],
      newdynamicField = JSON.parse(
        JSON.stringify({ ...this.state.dynamicField })
      );
    let newErrors = { ...this.state.errors };
    newErrors.studentContactTableFieldGroups.push({});
    newErrors.studentContactTableFieldGroups.push({});
    newdynamicField.customTableFields.forEach((field) => {
      field.value = "";
      field.year = null;
      field.month = null;
      field.day = null;
      field.gender = "";
    });
    newdynamicField["Is Legal Guardian"] = false;
    newdynamicField["Relationship"] = "";
    newdynamicField["emailAddress"] = null;
    newdynamicField["new"] = true;
    studentContactTableFieldGroups.push(newdynamicField);

    let newStudentContactImages = [...this.state.studentContactImage];
    this.setState((prevState) => ({
      studentContactTableFieldGroups: [
        ...prevState.studentContactTableFieldGroups,
        newdynamicField,
      ],
    }));
    this.setState({
      errors: newErrors,
      studentContactImage: newStudentContactImages,
    });
  };

  handleDynamicRemoveStudentContactForm = (e, indexToDel) => {
    e.preventDefault();
    let newStudentContactTableFieldGroups = [
      ...this.state.studentContactTableFieldGroups,
    ];
    newStudentContactTableFieldGroups.splice(indexToDel, 1);
    let newStudentContactImages = [...this.state.studentContactImage];
    newStudentContactImages.splice(indexToDel, 1);
    this.setState({
      studentContactTableFieldGroups: newStudentContactTableFieldGroups,
      studentContactImage: newStudentContactImages,
    });
  };
  /**
   * @author Suyog Manandhar
   *
   * This method handles Change for nested JSON Object i.e. admissionTableFieldGroups
   * @param e is the event
   * @param gIdx is the index of the corresponding input field
   * @param stateName is the name of the state input field binded with
   * @param fIdx is the index of the 2nd dimension JSON Object i.e. customTableFields
   */
  handleNestedChange = (e, gIdx, stateName, fIdx, fieldTypeCode, dataIdx) => {
    let newState = [...this.state[stateName]];

    let errors = { ...this.state.errors };
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (
      name === "Is Legal Guardian" ||
      name === "Relationship" ||
      name === "emailAddress"
    ) {
      newState[gIdx][name] = value;
    } else {
      if (
        fieldTypeCode === "select" ||
        fieldTypeCode === "radio" ||
        fieldTypeCode === "textarea"
      ) {
        newState[gIdx].customTableFields[fIdx][name] = value;
      } else if (fieldTypeCode === "multi-checkbox") {
        let fields =
          newState[gIdx].customTableFields[fIdx].customTableFieldDatas[dataIdx];
        fields.isChecked = value;
      } else {
        if (!value.match(this.state.regEx)) {
          swal({
            title: "Warning",
            text: "Character not allowed",
            allowOutsideClick: false,
            closeOnClickOutside: false,
          });
          draggable();
        } else {
          newState[gIdx].customTableFields[fIdx][name] = value;
        }
      }
      if (e.target.value.length > 0) {
        //   if (stateName === "admissionTableFieldGroups")
        if (newState[gIdx].customTableFields[fIdx].error)
          newState[gIdx].customTableFields[fIdx].error = false;
        //   else newState[gIdx].customTableFields[fIdx].error = false;
      } else {
        //   if (stateName === "admissionTableFieldGroups")
        if (newState[gIdx].customTableFields[fIdx].error)
          newState[gIdx].customTableFields[fIdx].error = true;
        //   else newState[gIdx].customTableFields[fIdx].error = true;
      }
    }

    this.setState({
      [stateName]: newState,
      errors,
    });
  };

  /**
   * This handles change in the Identity Cluster Fields
   * @param  e is the synthetic event
   * @param idx is the corresponding index of the mapped value
   */
  handleCustomFieldChange = async (
    e,
    studentFieldIndex,
    customTableFieldIndex,
    stateName,
    fields
  ) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let newState = [...this.state[stateName]];
    let errors = { ...this.state.errors };
    newState[studentFieldIndex].customTableFields[customTableFieldIndex][name] =
      value;
    if (name === "country") {
      const data = {
        countryId: e.target.value,
      };
      newState[studentFieldIndex].customTableFields[customTableFieldIndex][
        "countryFirstLevelId"
      ] = "";
      newState[studentFieldIndex].customTableFields[customTableFieldIndex][
        "countrySecondLevelId"
      ] = "";
      newState[studentFieldIndex].customTableFields[customTableFieldIndex][
        "countryThirdLevelId"
      ] = "";

      const countryFirstLevelPromise = new Promise((res, rej) => {
        axiosPost(URL.getCountryFirstLevel, data, (response) => {
          if (response.status === 200) {
            res(response.data.data);
          }
        });
      });

      const countryFirstLevel = await countryFirstLevelPromise;
      if (countryFirstLevel) {
        newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "countryFirstLevel"
        ] = countryFirstLevel;
        newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "countrySecondLevel"
        ] = "";
        newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "countryThirdLevel"
        ] = "";
      }
    }
    if (name === "countryFirstLevelId") {
      const data = {
        countryId:
          newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "country"
          ],
        countryFirstLevelId: e.target.value,
      };

      const countrySecondLevelPromise = new Promise((res, rej) => {
        axiosPost(URL.getCountrySecondLevel, data, (response) => {
          if (response.status === 200) {
            res(response.data.data);
          }
        });
      });

      const countrySecondLevel = await countrySecondLevelPromise;
      if (countrySecondLevel)
        newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "countrySecondLevel"
        ] = countrySecondLevel;
    }
    if (name === "countrySecondLevelId") {
      const data = {
        countryId:
          newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "country"
          ],
        countryFirstLevelId:
          newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "countryFirstLevelId"
          ],
        countrySecondLevelId: e.target.value,
      };

      const countryThirdLevelPromise = new Promise((res, rej) => {
        axiosPost(URL.getCountryThirdLevel, data, (response) => {
          if (response.status === 200) {
            res(response.data.data);
          }
        });
      });

      const countryThirdLevel = await countryThirdLevelPromise;
      if (countryThirdLevel)
        newState[studentFieldIndex].customTableFields[customTableFieldIndex][
          "countryThirdLevel"
        ] = countryThirdLevel;
    }
    if (fields && fields.mandatory) {
      if (value.length > 0) {
        // if (stateName === "admissionTableFieldGroups") {
        if (
          newState[studentFieldIndex].customTableFields[customTableFieldIndex]
            .error
        ) {
          newState[studentFieldIndex].customTableFields[
            customTableFieldIndex
          ].error[fields.fieldName] = false;
        }
        // }
        // else {
        //   newState[studentFieldIndex].customTableFields[
        //     customTableFieldIndex
        //   ].error[fields.fieldName] = false;
        // }
      } else {
        // if (stateName === "admissionTableFieldGroups")
        if (
          newState[studentFieldIndex].customTableFields[customTableFieldIndex]
            .error
        ) {
          newState[studentFieldIndex].customTableFields[
            customTableFieldIndex
          ].error[fields.fieldName] = true;
        }
        // else
        //   newState[studentFieldIndex].customTableFields[
        //     customTableFieldIndex
        //   ].error[fields.fieldName] = false;
      }
    }
    this.setState({ [stateName]: newState, errors });
  };

  handleBdayChange = (
    e,
    studentFieldIndex,
    customTableFieldIndex,
    stateName,
  ) => {
    let newState = cloneDeep([...this.state[stateName]]);
    let errors = { ...this.state.errors };
    newState[studentFieldIndex].customTableFields[customTableFieldIndex].year =
      e.target.value.substring(0, 4);
    newState[studentFieldIndex].customTableFields[customTableFieldIndex].month =
      e.target.value.substring(5, 7).substring(0, 1) === "0"
        ? e.target.value.substring(6, 7)
        : e.target.value.substring(5, 7);
    newState[studentFieldIndex].customTableFields[customTableFieldIndex].day =
      e.target.value.substring(8, 10).substring(0, 1) === "0"
        ? e.target.value.substring(9, 10)
        : e.target.value.substring(8, 10);
    if (e.target.value.length > 0) {
      if (stateName === "admissionTableFieldGroups") {
        errors[stateName].year = false;
        errors[stateName].month = false;
        errors[stateName].day = false;
      } else {
        errors[stateName][studentFieldIndex].year = false;
        errors[stateName][studentFieldIndex].month = false;
        errors[stateName][studentFieldIndex].day = false;
      }
    } else {
      if (stateName === "admissionTableFieldGroups") {
        errors[stateName].year = true;
        errors[stateName].month = true;
        errors[stateName].day = true;
      } else {
        errors[stateName][studentFieldIndex].year = true;
        errors[stateName][studentFieldIndex].month = true;
        errors[stateName][studentFieldIndex].day = true;
      }
    }
    this.setState({ [stateName]: newState, errors });
  };

  handleDateChange = (
    objKey,
    objValue,
    studentFieldIndex,
    customTableFieldIndex,
    stateName
  ) => {
    let newState = [...this.state[stateName]];
    let errors = { ...this.state.errors };
    newState[studentFieldIndex].customTableFields[customTableFieldIndex][
      objKey
    ] = objValue;
    if (objValue) {
      if (stateName === "admissionTableFieldGroups") {
        errors[stateName][objKey] = false;
      } else {
        errors[stateName][studentFieldIndex][objKey] = false;
      }
    } else {
      if (stateName === "admissionTableFieldGroups") {
        errors[stateName][objKey] = true;
      } else {
        errors[stateName][studentFieldIndex][objKey] = true;
      }
    }
    this.setState({ [stateName]: newState, errors });
  };

  handleStaticChange = (e) => {
    const name = e.target.name;
    const type = e.target.type;
    const value = type === "checkbox" ? e.target.checked : e.target.value;
    if (name === "scholarshipType") {
      this.setState({});
    }
    this.setState({ [name]: value });
  };

  handleFileChange = (
    e,
    studentFieldIndex,
    customTableFieldIndex,
    stateName
  ) => {
    var data = e.target.files[0];
    if ( e.target.accept.includes(data.type)) {
      if (stateName === "admissionTableFieldGroups") {
        this.setState({ studentImage: data });
      } else {
        let newStudentContactImages = [...this.state.studentContactImage];
        //   newStudentContactImages[studentFieldIndex][
        //     `image${newStudentContactImages.length}`
        //   ] = e.target.files[0];
        newStudentContactImages.push(data);
        this.setState({
          studentContactImage: newStudentContactImages,
        });
      }
    } else {
      swal(MESSAGEHEADER.error, "Unsupported File Type", "");
    }
  };

  handleDuplicate = (groupIndex, fieldIndex, stateName, subCol, ctfcIndex) => {
    let newState = cloneDeep(this.state[stateName]);
    if (subCol === "subCol") {
      let length =
        newState[groupIndex].customTableFields[fieldIndex]
          .customFieldTableColumns[ctfcIndex].rows.length;
      newState[groupIndex].customTableFields[
        fieldIndex
      ].customFieldTableColumns[ctfcIndex].rows.push({
        rowId: length === 0 ? length++ : length,
      });
    } else {
      if (newState[groupIndex].customTableFields[fieldIndex].rows) {
        let length =
          newState[groupIndex].customTableFields[fieldIndex].rows.length;
        newState[groupIndex].customTableFields[fieldIndex].rows.push({
          rowId: length === 0 ? length++ : length,
        });
      } else {
        newState[groupIndex].customTableFields[fieldIndex].rows = [];
        newState[groupIndex].customTableFields[fieldIndex].rows.push({
          rowId: 0,
        });
        newState[groupIndex].customTableFields[fieldIndex].rows.push({
          rowId: 1,
        });
      }
    }
    this.setState({ [stateName]: newState });
  };

  handleRemoveDuplicate = (
    groupIndex,
    fieldIndex,
    stateName,
    rowIndex,
    subCol,
    ctfcIndex
  ) => {
    let newState = cloneDeep(this.state[stateName]);
    if (subCol === "subCol") {
      newState[groupIndex].customTableFields[
        fieldIndex
      ].customFieldTableColumns[ctfcIndex].rows.splice(rowIndex, 1);
    } else {
      newState[groupIndex].customTableFields[fieldIndex].rows.splice(
        rowIndex,
        1
      );
    }
    this.setState({ [stateName]: newState });
  };

  handleCustomFieldTablesChange = (
    e,
    studentFieldIndex,
    customTableFieldIndex,
    columnIndex,
    subColumnIndex,
    rowIndex,
    stateName,
    subColumn
  ) => {
    let newState = cloneDeep(this.state[stateName]);
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const name = e.target.name;
    if (subColumn) {
      newState[studentFieldIndex].customTableFields[
        customTableFieldIndex
      ].customFieldTableColumns[columnIndex].rows[rowIndex][name] = value;
    } else {
      newState[studentFieldIndex].customTableFields[customTableFieldIndex].rows[
        rowIndex
      ][name] = value;
    }
    this.setState({ [stateName]: newState });
  };

  icToggleDatePicker = () => {
    this.setState({
      identityClusterDatePickerToggle:
        !this.state.identityClusterDatePickerToggle,
    });
  };
  icSubFormToggleDatePicker = () => {
    this.setState({
      subFormDatePickerToggle: !this.state.subFormDatePickerToggle,
    });
  };

  checkIfEmailAvailable = () => { };
  render() {
    return (
      this.state.render && (
        <>
          {this.state.isAvailable ? (
            <div
              className="tt-widgetContent-tab-holder"
              style={{ height: "79.9vh" }}
            >
              <div className="tt-group-header">
                Admission Form
                {checkWidgetAdminOrNot("Admission") === true ? (
                  <button
                    className="tt-button tt-button-primary float-right permissionBtnCSS"
                    onClick={() => {
                      this.setState({
                        permissionModal: !this.state.permissionModal,
                      });
                    }}
                  >
                    Permissions
                  </button>
                ) : null}
                {!this.props.editMode &&
                 ( <button
                    className="tt-button tt-button-primary float-right permissionBtnCSS"
                    onClick={() => {
                      this.setState({
                        importModal: !this.state.importModal,
                      });
                    }}
                  >
                    Import Data
                  </button>)
                }
              </div>
              <div>
                {!this.state.isLoading ? (
                  this.state.admissionFormConfigurationStatus ? (
                    <div className="tt-newListBox container-fluid">
                      <YearLevelAcademicLevel
                        yearLevel={this.state.yearLevel}
                        academicLevel={this.state.academicLevel}
                        handleChange={this.handleChange}
                        errors={this.state.errors}
                      />
                      {this.state.admissionTableFieldGroups.length > 0 ? (
                        this.state.admissionTableFieldGroups.map(
                          (group, gIdx) => (
                            <div key={gIdx}>
                              {group.headerHidden ? null : (
                                <div className="tt-group-header">
                                  {group.name}
                                </div>
                              )}
                              {group.customTableFields
                                ? group.customTableFields.map(
                                  (customTableField, fIdx) => (
                                    <div key={fIdx}>
                                      <div className="form-group row">
                                        <div className="col-md-1"></div>
                                        {customTableField.fieldName ===
                                          null ||
                                          customTableField.fieldName ===
                                          "" ? null : (
                                          <div className="col-md-3">
                                            <label htmlFor={`id${fIdx}`}>
                                              <strong>
                                                {customTableField.fieldName}
                                                {": "}
                                              </strong>
                                            </label>
                                          </div>
                                        )}
                                        <div
                                          className={
                                            customTableField.fieldName ===
                                              null ||
                                              customTableField.fieldName === ""
                                              ? "col-md-10"
                                              : "col-md-7"
                                          }
                                        >
                                          {customTableField.fieldTypeCode ===
                                            addressCode ? (
                                            <AddressCluster
                                              studentFieldIndex={gIdx}
                                              customTableFieldIndex={fIdx}
                                              customTableField={
                                                customTableField
                                              }
                                              countryFirstLevel={
                                                this.state.countryFirstLevel
                                              }
                                              countrySecondLevel={
                                                this.state.countrySecondLevel
                                              }
                                              countryThirdLevel={
                                                this.state.countryThirdLevel
                                              }
                                              handleCustomFieldChange={
                                                this.handleCustomFieldChange
                                              }
                                              stateName="admissionTableFieldGroups"
                                              errors={this.state.errors}
                                              handleFetchingLevels={
                                                this.handleFetchingLevels
                                              }
                                              fetchingLevels={
                                                this.state.fetchingLevels
                                              }
                                              inputForm={true}
                                              config={false}
                                            />
                                          ) : customTableField.fieldTypeCode ===
                                            peopleCode ? (
                                            <IdentityCluster
                                              studentFieldIndex={gIdx}
                                              customTableFieldIndex={fIdx}
                                              stateName="admissionTableFieldGroups"
                                              customFields={
                                                this.state.customFields
                                              }
                                              handleBdayChange={
                                                this.handleBdayChange
                                              }
                                              handleDateChange={
                                                this.handleDateChange
                                              }
                                              customTableField={
                                                customTableField
                                              }
                                              handleCustomFieldChange={
                                                this.handleCustomFieldChange
                                              }
                                              errors={this.state.errors}
                                              handleFileChange={
                                                this.handleFileChange
                                              }
                                              student={true}
                                              icToggleDatePicker={
                                                this.icToggleDatePicker
                                              }
                                              identityClusterDatePickerToggle={
                                                this.state
                                                  .identityClusterDatePickerToggle
                                              }
                                              adbsToggler={true}
                                              inputForm={true}
                                              config={false}
                                            />
                                          ) : (
                                            <>
                                              {customTableField.fieldTypeName ===
                                                "file" ? (
                                                <input
                                                  type={
                                                    customTableField.fieldTypeName
                                                  }
                                                  name="value"
                                                  id={`id${fIdx}`}
                                                  checked={
                                                    customTableField.value
                                                  }
                                                  onChange={(e) =>
                                                    this.handleNestedChange(
                                                      e,
                                                      gIdx,
                                                      "admissionTableFieldGroups",
                                                      fIdx
                                                    )
                                                  }
                                                  className={
                                                    customTableField?.error
                                                      ? "form-control indicate-error"
                                                      : "form-control"
                                                  }
                                                />
                                              ) : (
                                                <>
                                                  {customTableField.isMulti &&
                                                    customTableField.fieldTypeCode !==
                                                    "select" &&
                                                    customTableField.fieldTypeCode !==
                                                    "table" ? (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      {customTableField.customTableFieldDatas.map(
                                                        (data, id) => (
                                                          <>
                                                            <label className="mr-1">
                                                              {data.data}
                                                            </label>
                                                            <input
                                                              type={
                                                                customTableField.fieldTypeCode ===
                                                                  "multi-checkbox"
                                                                  ? "checkbox"
                                                                  : customTableField.fieldTypeCode
                                                              }
                                                              name={
                                                                customTableField.fieldTypeCode ===
                                                                  "radio"
                                                                  ? "value" +
                                                                  customTableField.id
                                                                  : customTableField.fieldTypeCode ===
                                                                    "multi-checkbox"
                                                                    ? data.data
                                                                    : "data" +
                                                                    customTableField.id
                                                              }
                                                              defaultChecked={
                                                                customTableField.value ===
                                                                  data.data
                                                                  ? true
                                                                  : false
                                                              }
                                                              checked={
                                                                customTableField.fieldTypeCode ===
                                                                  "multi-checkbox"
                                                                  ? data.isChecked
                                                                  : null
                                                              }
                                                              maxLength="255"
                                                              id={`id${id}`}
                                                              value={
                                                                data.data
                                                              }
                                                              onChange={(e) =>
                                                                this.handleNestedChange(
                                                                  e,
                                                                  gIdx,
                                                                  "admissionTableFieldGroups",
                                                                  fIdx,
                                                                  customTableField.fieldTypeCode,
                                                                  id
                                                                )
                                                              }
                                                              className={
                                                                customTableField?.error
                                                                  ? "indicate-error mr-3"
                                                                  : "mr-3"
                                                              }
                                                            />
                                                          </>
                                                        )
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <>
                                                      {customTableField.isMulti &&
                                                        customTableField.fieldTypeCode ===
                                                        "select" ? (
                                                        <select
                                                          name="value"
                                                          //   className="form-control"
                                                          className={
                                                            customTableField?.error
                                                              ? "form-control indicate-error"
                                                              : "form-control"
                                                          }
                                                          value={
                                                            customTableField.value
                                                          }
                                                          onChange={(e) =>
                                                            this.handleNestedChange(
                                                              e,
                                                              gIdx,
                                                              "admissionTableFieldGroups",
                                                              fIdx,
                                                              customTableField.fieldTypeCode
                                                            )
                                                          }
                                                        >
                                                          <option
                                                            disabled
                                                            selected
                                                          >
                                                            Choose a value
                                                          </option>
                                                          {customTableField.customTableFieldDatas.map(
                                                            (data) => (
                                                              <option
                                                                value={
                                                                  data.data
                                                                }
                                                              >
                                                                {data.data}
                                                              </option>
                                                            )
                                                          )}
                                                        </select>
                                                      ) : (
                                                        <>
                                                          {customTableField.fieldTypeCode ===
                                                            "textarea" ? (
                                                            <textarea
                                                              name="value"
                                                              value={
                                                                customTableField.value
                                                              }
                                                              onChange={(e) =>
                                                                this.handleNestedChange(
                                                                  e,
                                                                  gIdx,
                                                                  "admissionTableFieldGroups",
                                                                  fIdx,
                                                                  customTableField.fieldTypeCode
                                                                )
                                                              }
                                                              className={
                                                                customTableField.error
                                                                  ? "form-control indicate-error"
                                                                  : "form-control"
                                                              }
                                                            />
                                                          ) : (
                                                            <>
                                                              {customTableField.fieldTypeCode ===
                                                                "table" ? (
                                                                <>
                                                                  <TableForm
                                                                    field={
                                                                      customTableField
                                                                    }
                                                                    studentFieldIndex={
                                                                      gIdx
                                                                    }
                                                                    customTableFieldIndex={
                                                                      fIdx
                                                                    }
                                                                    handleDuplicate={
                                                                      this
                                                                        .handleDuplicate
                                                                    }
                                                                    handleRemoveDuplicate={
                                                                      this
                                                                        .handleRemoveDuplicate
                                                                    }
                                                                    stateName="admissionTableFieldGroups"
                                                                    conf={
                                                                      false
                                                                    }
                                                                    handleCustomFieldTablesChange={
                                                                      this
                                                                        .handleCustomFieldTablesChange
                                                                    }
                                                                  />
                                                                </>
                                                              ) : (
                                                                <input
                                                                  type={
                                                                    customTableField.fieldTypeCode
                                                                  }
                                                                  name={
                                                                    "value"
                                                                  }
                                                                  maxLength="255"
                                                                  value={
                                                                    customTableField.value
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    this.handleNestedChange(
                                                                      e,
                                                                      gIdx,
                                                                      "admissionTableFieldGroups",
                                                                      fIdx,
                                                                      customTableField.fieldTypeCode
                                                                    )
                                                                  }
                                                                  className={
                                                                    customTableField.error
                                                                      ? "form-control indicate-error"
                                                                      : "form-control"
                                                                  }
                                                                />
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                              {customTableField.mandatory ? (
                                                <sup>*</sup>
                                              ) : null}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )
                                : null}
                            </div>
                          )
                        )
                      ) : (
                        <div
                          className="text-center"
                          style={{ marginTop: "200px" }}
                        >
                          <Spinner color="primary" />
                        </div>
                      )}
                      {this.state.studentContactTableFieldGroups
                        ? this.state.studentContactTableFieldGroups.map(
                          (group, gIdx) => (
                            <div key={gIdx}>
                              {group.headerHidden ? null : (
                                <div className="tt-group-header">
                                  {group.name}{" "}
                                  {gIdx > 0 ? (
                                    <button
                                      className="tt-button tt-button-danger float-right"
                                      onClick={(e) =>
                                        this.handleDynamicRemoveStudentContactForm(
                                          e,
                                          gIdx
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon icon={faMinusSquare} />
                                    </button>
                                  ) : null}
                                </div>
                              )}
                              {group.customTableFields
                                ? group.customTableFields.map(
                                  (customTableField, fIdx) => (
                                    <div key={fIdx}>
                                      <div className="form-group row">
                                        <div className="col-md-1"></div>
                                        {customTableField.fieldTypeCode ===
                                          addressCode ||
                                          customTableField.fieldTypeCode ===
                                          peopleCode ? null : (
                                          <div className="col-md-3">
                                            <label htmlFor={`id${fIdx}`}>
                                              <strong>
                                                {customTableField.fieldName}
                                                {": "}
                                              </strong>
                                            </label>
                                          </div>
                                        )}
                                        <div
                                          className={
                                            customTableField.fieldTypeCode ===
                                              addressCode ||
                                              customTableField.fieldTypeCode ===
                                              peopleCode
                                              ? "col-md-10"
                                              : "col-md-7"
                                          }
                                        >
                                          {customTableField.fieldTypeCode ===
                                            addressCode ? (
                                            <AddressCluster
                                              studentFieldIndex={gIdx}
                                              customTableFieldIndex={fIdx}
                                              customTableField={
                                                customTableField
                                              }
                                              countryFirstLevel={
                                                this.state.countryFirstLevel
                                              }
                                              countrySecondLevel={
                                                this.state
                                                  .countrySecondLevel
                                              }
                                              countryThirdLevel={
                                                this.state.countryThirdLevel
                                              }
                                              stateName="studentContactTableFieldGroups"
                                              handleCustomFieldChange={
                                                this.handleCustomFieldChange
                                              }
                                              handleFetchingLevels={
                                                this.handleFetchingLevels
                                              }
                                              errors={this.state.errors}
                                              inputForm={true}
                                              config={false}
                                            />
                                          ) : customTableField.fieldTypeCode ===
                                            peopleCode ? (
                                            <IdentityCluster
                                              studentFieldIndex={gIdx}
                                              customTableFieldIndex={fIdx}
                                              handleBdayChange={
                                                this.handleBdayChange
                                              }
                                              stateName="studentContactTableFieldGroups"
                                              customTableField={
                                                customTableField
                                              }
                                              handleCustomFieldChange={
                                                this.handleCustomFieldChange
                                              }
                                              handleDateChange={
                                                this.handleDateChange
                                              }
                                              handleFileChange={
                                                this.handleFileChange
                                              }
                                              errors={this.state.errors}
                                              student={false}
                                              checkIfEmailAvailable={
                                                this.checkIfEmailAvailable
                                              }
                                              icToggleDatePicker={
                                                this
                                                  .icSubFormToggleDatePicker
                                              }
                                              identityClusterDatePickerToggle={
                                                this.state
                                                  .subFormDatePickerToggle
                                              }
                                              adbsToggler={true}
                                              inputForm={true}
                                              config={false}
                                            />
                                          ) : (
                                            <>
                                              <>
                                                {customTableField.isMulti &&
                                                  customTableField.fieldTypeCode !==
                                                  "select" &&
                                                  customTableField.fieldTypeCode !==
                                                  "table" ? (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                    }}
                                                  >
                                                    {customTableField.customTableFieldDatas.map(
                                                      (data) => (
                                                        <>
                                                          <label className="mr-1">
                                                            {data.data}
                                                          </label>
                                                          <input
                                                            type={
                                                              customTableField.fieldTypeCode ===
                                                                "multi-checkbox"
                                                                ? "checkbox"
                                                                : customTableField.fieldTypeCode
                                                            }
                                                            name={
                                                              customTableField.fieldTypeCode ===
                                                                "radio"
                                                                ? "value" +
                                                                customTableField.id
                                                                : "data" +
                                                                customTableField.id
                                                            }
                                                            maxLength="255"
                                                            id={`id${fIdx}`}
                                                            value={
                                                              data.data
                                                            }
                                                            onChange={(e) =>
                                                              this.handleNestedChange(
                                                                e,
                                                                gIdx,
                                                                "studentContactTableFieldGroups",
                                                                fIdx
                                                              )
                                                            }
                                                            className={
                                                              customTableField?.error
                                                                ? "indicate-error mr-3"
                                                                : "mr-3"
                                                            }
                                                          />
                                                        </>
                                                      )
                                                    )}
                                                  </div>
                                                ) : (
                                                  <>
                                                    {customTableField.isMulti &&
                                                      customTableField.fieldTypeCode ===
                                                      "select" ? (
                                                      <select
                                                        name="value"
                                                        //name={customTableField.fieldTypeName}
                                                        //   className="form-control"
                                                        className={
                                                          customTableField?.error
                                                            ? "form-control indicate-error"
                                                            : "form-control"
                                                        }
                                                        onChange={(e) =>
                                                          this.handleNestedChange(
                                                            e,
                                                            gIdx,
                                                            "studentContactTableFieldGroups",
                                                            fIdx
                                                          )
                                                        }
                                                      >
                                                        <option
                                                          disabled
                                                          selected
                                                        >
                                                          Choose a value
                                                        </option>
                                                        {customTableField.customTableFieldDatas.map(
                                                          (data) => (
                                                            <option
                                                              value={
                                                                data.data
                                                              }
                                                            >
                                                              {data.data}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                    ) : (
                                                      <>
                                                        {customTableField.fieldTypeCode ===
                                                          "table" ? (
                                                          <TableForm
                                                            field={
                                                              customTableField
                                                            }
                                                            studentFieldIndex={
                                                              gIdx
                                                            }
                                                            customTableFieldIndex={
                                                              fIdx
                                                            }
                                                            handleDuplicate={
                                                              this
                                                                .handleDuplicate
                                                            }
                                                            handleRemoveDuplicate={
                                                              this
                                                                .handleRemoveDuplicate
                                                            }
                                                            stateName="studentContactTableFieldGroups"
                                                            conf={false}
                                                            handleCustomFieldTablesChange={
                                                              this
                                                                .handleCustomFieldTablesChange
                                                            }
                                                          />
                                                        ) : (
                                                          <input
                                                            type={
                                                              customTableField.fieldTypeCode
                                                            }
                                                            name={"value"}
                                                            maxLength="255"
                                                            id={`id${fIdx}`}
                                                            value={
                                                              customTableField.value
                                                            }
                                                            onChange={(e) =>
                                                              this.handleNestedChange(
                                                                e,
                                                                gIdx,
                                                                "studentContactTableFieldGroups",
                                                                fIdx
                                                              )
                                                            }
                                                            className={
                                                              customTableField?.error
                                                                ? "form-control indicate-error"
                                                                : "form-control"
                                                            }
                                                          />
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                                {customTableField.mandatory ? (
                                                  <span className="tt-assessment-module-mandatory">
                                                    *
                                                  </span>
                                                ) : null}
                                              </>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )
                                : null}
                              <div>
                                {this.state.siblings === 1 ? (
                                  <div className="form-group row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-3">
                                      <label>
                                        <strong>Sibling’s name</strong>
                                      </label>
                                    </div>
                                    <div className="col-md-7">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="col-md-1"></div>
                                  </div>
                                ) : null}
                              </div>

                              <div className="form-group row">
                                <div className="col-md-1" />
                                <div className="col-md-3">
                                  <label>
                                    <strong>Is Legal Guardian</strong>
                                  </label>
                                </div>
                                <div className="col-md-7">
                                  <input
                                    type="checkbox"
                                    name="Is Legal Guardian"
                                    checked={group["Is Legal Guardian"]}
                                    onChange={(e) =>
                                      this.handleNestedChange(
                                        e,
                                        gIdx,
                                        "studentContactTableFieldGroups"
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-md-1" />
                              </div>
                              <div className="form-group row">
                                <div className="col-md-1" />
                                <div className="col-md-3">
                                  <label>
                                    <strong>Relationship</strong>

                                    <span className="tt-assessment-module-mandatory">
                                      *
                                    </span>
                                  </label>
                                </div>
                                <div className="col">
                                  <select
                                    name="Relationship"
                                    value={group["Relationship"]}
                                    onChange={(e) =>
                                      this.handleNestedChange(
                                        e,
                                        gIdx,
                                        "studentContactTableFieldGroups"
                                      )
                                    }
                                    className={
                                      this.state.errors
                                        .studentContactTableFieldGroups[gIdx]
                                        ? this.state.errors
                                          .studentContactTableFieldGroups[
                                          gIdx
                                        ].Relationship
                                          ? "form-control indicate-error"
                                          : "form-control"
                                        : "form-control"
                                    }
                                  >
                                    <option value="" disabled selected>
                                      Choose a value
                                    </option>
                                    {this.state.relationships.map(
                                      (relationship, id) => (
                                        <option
                                          value={relationship.id}
                                          key={id}
                                        >
                                          {relationship.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div className="col-md-auto">
                                  <button
                                    className="tt-button tt-button-primary"
                                    onClick={this.toggleRelationshipModal}
                                  >
                                    Add New Relationship
                                  </button>
                                </div>
                                <div className="col-md-1" />
                              </div>
                            </div>
                          )
                        )
                        : null}
                      {this.state.studentContactTableFieldGroups ? (
                        <div className="form-group row">
                          <div className="col-md-10"></div>
                          <div className="col-md-1"></div>

                          <div className="col-md-1">
                            {" "}
                            <button
                              className="tt-button tt-button-danger"
                              onClick={this.handleDuplicateStudentContactForm}
                            >
                              <FontAwesomeIcon icon={faPlusSquare} />
                            </button>
                          </div>
                        </div>
                      ) : null}
                      <div className="form-group row">
                        <div className="col-md-8"></div>
                        <div className="col-md-4 admission-notice">
                          <div className="notice-text">
                            The following must be submitted with the
                            application:
                            <li> Application fees</li>
                            <li> Progress report of last year</li>
                            <li> Health History Form</li>
                            <li> Birth Certificate</li>
                          </div>
                        </div>
                      </div>
                      {this.state.studentContactTableFieldGroups ? (
                        <div className="form-group row mt-4">
                          <div className="col text-right mr-4">
                            <button
                              className="tt-button tt-button-primary"
                              type="submit"
                              onClick={this.submit}
                              disabled={this.state.isSubmitting}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    // <Spinner color="primary" />

                    <div
                      className="tt-widgetContent-tab-holder text-center py-3"
                      style={{ height: "79.5vh" }}
                    >
                      Admission form configuration not found. First configure
                      the form.
                    </div>
                  )
                ) : (
                  <div className="text-center">
                    <Spinner color="primary" />
                  </div>
                )}
              </div>
              <ModalWindow
                modal={this.state.permissionModal}
                size="lg"
                id="tt-permissionModal"
                toggleModal={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
                modalHeader={"Assign permission to user"}
                modalBody={
                  <GranularPermissionModal
                    widgetName="Admission"
                    moduleName="Admission"
                    header="Add Admission Form"
                    activityName="insert-admission-form"
                  />
                }
              ></ModalWindow>
              <ModalWindow
                modal={this.state.relationshipModal}
                toggleModal={this.toggleRelationshipModal}
                modalHeader="Add New Relationship"
                modalBody={
                  <AddNewRelationShip
                    isSibling={this.state.isSibling}
                    isParent={this.state.isParent}
                    relationshipName={this.state.relationshipName}
                    handleRelationShipChange={this.handleRelationShipChange}
                  ></AddNewRelationShip>
                }
                modalFooter={this.relationshipModalFooter()}
              ></ModalWindow>
              <ModalWindow
                modal={this.state.importModal}
                size="lg"
                toggleModal={() => {
                  this.setState({
                    importModal: !this.state.importModal,
                  });
                }}
                modalHeader={"Import Admission Data"}
                modalBody={
                  <ImportAdmission/>
                }
              ></ModalWindow>
              {this.state.isSubmitting ? (
                <div className="fullWindow-Spinner">
                  <div>
                    <Spinner color="white"></Spinner>
                  </div>
                  <div style={{ fontSize: "16px", marginTop: "15px" }}>
                    Please wait...
                    {`${this.props.editMode ? "Updating" : "Creating"}`} Student
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div
              className="tt-widgetContent-tab-holder"
              style={{ height: "79.5vh" }}
            >
              <NoteDisplay
                textContent={`
To add a new Admission, you must first add ${localStorage.getItem("yearLevel")
                    ? localStorage.getItem("yearLevel")
                    : "Grade"
                  }. Please add ${localStorage.getItem("yearLevel")
                    ? localStorage.getItem("yearLevel")
                    : "Grade"
                  } and then add a new Admission.`}
              ></NoteDisplay>
            </div>
          )}
        </>
      )
    );
  }
}

const mapStateToProps = (state, props) => ({
  admissionTableFieldGroups: state.admission.admissionTableFieldGroups,
  studentContactTableFieldGroups:
    state.admission.studentContactTableFieldGroups,
  dynamicField: state.admission.dynamicField,
  admissionFormConfigurationStatus:
    state.admission.admissionFormConfigurationStatus,
});

const mapActionToProps = {
  getAdmissionFormConfig,
  getAdmissionList,
  getAdmissionListPagination,
};

export default connect(mapStateToProps, mapActionToProps)(AdmissionForm);
