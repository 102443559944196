import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  URL,
  postType,
  userRole,
  userRoleCode,
} from "../../../utils/Constants";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import JoditEditor from "jodit-react";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  limitChars: 60000,
  removeButtons: [
    "hr",
    "about",
    "fullsize",
    "eraser",
    "undo",
    "redo",
    "table",
    "file",
    "format",
    "brush",
    "source",
    "fontsize",
    "paragraph",
    "left",
    "center",
    "right",
    "justify",
    "video",
    "superscript",
    "subscript",
    "print",
    "copyformat",
  ],
  uploader: {
    insertImageAsBase64URI: true,
  },
};

const animatedComponents = makeAnimated();

class Notice extends Component {
  state = {
    groupsArray: [],
    peopleArray: [],
    peopleSpinner: false,
  };
  componentDidMount() {
    if (userRole === userRoleCode.roleAdmin) {
      axiosPost(URL.getAllGroups, {}, (response) => {
        if (response.status === 200) {
          let groups = response.data.data;
          let groupsArray = [];
          groups.forEach((element) => {
            groupsArray.push({ label: element.name, value: element.id });
          });
          this.setState({
            groupsArray,
          });
        }
      });
    } else {
      let peopleId = localStorage.getItem("peopleId");

      let data = {
        peopleId: peopleId,
      };
      axiosPost(URL.getGroups, data, (response) => {
        if (response.status === 200) {
          let groups = response.data.data;
          let groupsArray = [];
          groups.forEach((element) => {
            groupsArray.push({ label: element.name, value: element.id });
          });
          this.setState({
            groupsArray,
          });
        }
      });
    }
    this.getPageType();
    this.getGroupAssociatedPeople();
  }

  getPageType = () => {
    var origin = window.location.href;
    var response = origin.split("/").pop();
    if (origin.includes("groupPage")) {
      this.state.groupsArray.map((data) => {
        if (data.label === response.replaceAll("_", " ")) {
          let param = {
            label: data.label,
            value: data.value,
          };
          this.props.handleChangeSelect(param);
        }
      });
    } else if (origin.includes("familyPage")) {
      let data = {
        familyId: response,
      };
      axiosPost(URL.selectFamilyMembers, data, (response) => {
        if (response.status === 200) {
          let people = response.data.data;
          let familyMembers = [];
          let peopleArray = [];
          let yearLevel = localStorage.getItem("yearLevel");
          let classroom = localStorage.getItem("classroom");
          if (people.length > 0) {
            people.forEach((el) => {
              let displayName = "";
              if (el.userRoleCode === userRoleCode.roleStudent) {
                displayName = `${el.peopleName} ( [${el.userRole}] ${yearLevel} ${el.yearLevel} - ${classroom} ${el.classroom})`;
              } else {
                displayName = `${el.peopleName} [${el.userRole}]`;
              }
              familyMembers.push({
                label: displayName,
                value: el.id,
              });
            });
          }
          this.props.handleIndividualSelect(familyMembers);
        }
      });
    }
  };

  getGroupAssociatedPeople = () => {
    let data = {
      peopleId: localStorage.getItem("peopleId"),
    };
    this.setState({ peopleSpinner: true }, function () {
      axiosPost(
        URL.getGroupAssociatedPeople,
        data,
        (response) => {
          if (response.status === 200) {
            let people = response.data.data;
            let peopleArray = [];
            let yearLevel = localStorage.getItem("yearLevel");
            let classroom = localStorage.getItem("classroom");
            if (people.length > 0) {
              people.forEach((el) => {
                let displayName = "";
                if (el.userRoleCode === userRoleCode.roleStudent) {
                  displayName = `${el.name} ( [${el.userRole}] ${yearLevel} ${el.yearLevel} - ${classroom} ${el.classroom})`;
                } else {
                  displayName = `${el.name} [${el.userRole}]`;
                }
                peopleArray.push({ label: displayName, value: el.id });
              });
            }

            this.setState({ peopleArray, peopleSpinner: false });
          }
        },
        (failur) => {
          this.setState({ peopleSpinner: false });
        }
      );
    });
  };
  render() {
    return (
      <div>
        {/* <CKEditor
          editor={ClassicEditor}
          name="postContent"
          data={this.props.textContent}
          onChange={(event, editor) => {
            this.props.handleTextEditor(event, editor.getData());
          }}
        /> */}
        <div className="row mb-1">
          <div className="col-md-1 mt-1 text-center">
            <strong>Heading</strong>
          </div>
          <div className="col-md-11">
            <input
              type="text"
              className="form-control"
              name="heading"
              value={this.props.heading}
              onChange={this.props.handleChange}
            ></input>
          </div>
        </div>
        <JoditEditor
          onChange={(e) => this.props.handleTextEditor(e)}
          config={config}
        />
        {/* <div className="row mt-2">
          <div className="col-md-2 text-center">
            <input
              type="checkbox"
              id="addSubject"
              className="tt-record-deadline-checkbox"
            ></input>
            <label htmlFor="addSubject">
              <strong>&nbsp;{"Add a Title"}</strong>
            </label>
          </div>
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              name="heading"
              value={this.props.heading}
              onChange={this.props.handleChange}
            ></input>
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-6">
            <div className="tt-postNoticeBoxes">
              <div>
                <strong>Attached files:</strong>&nbsp;
                {Math.round(this.props.fileSize / 1024)} of 2048K (2 MB)
              </div>
              <div className="tt-postFiles">
                {this.props.files.length > 0 ? (
                  this.props.files.map((file, idx) => {
                    return (
                      <div key={idx}>
                        {file.type.includes("image") ? (
                          <div
                            className="tt-postFileHolder"
                            style={{ padding: 0 }}
                          >
                            <img
                              src={window.URL.createObjectURL(file)}
                              alt="notice"
                            ></img>
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="tt-fileDelete"
                              onClick={(e) => {
                                this.props.deleteFileContent(e, idx, file);
                              }}
                            ></FontAwesomeIcon>
                          </div>
                        ) : (
                          <div className="tt-postFileHolder">
                            <FontAwesomeIcon
                              icon={faFile}
                              className="tt-postFileIcon"
                            ></FontAwesomeIcon>
                            <div>{file.name}</div>
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="tt-fileDelete"
                              style={{ top: "-80px" }}
                              onClick={(e) => {
                                this.props.deleteFileContent(e, idx, file);
                              }}
                            ></FontAwesomeIcon>
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center">No Files uploaded</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="tt-postNoticeBoxes">
              <strong>Embeded Media</strong>
              <div className="tt-postEmbeded">
                {this.props.selectedMedias.length > 0
                  ? this.props.selectedMedias.map((media, idx) => {
                      return (
                        <div className="row" key={idx}>
                          <div className="col-md-11">
                            <p style={{ wordBreak: "break-word" }}>{media}</p>
                          </div>
                          <div
                            className="col-md-1"
                            onClick={(e) =>
                              this.props.deleteLinkContent(e, idx)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                            ></FontAwesomeIcon>
                          </div>
                        </div>
                      );
                    })
                  : "No links Added"}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-10 text-right">
            <p>
              Attach Documents (pdf) / Image (jpg,png,gif)
              {/* Attach Documents (pdf, docs, ods, rtf, etc.) / Image (jpg,png,gif) */}
            </p>
          </div>
          <div className="col-md-2">
            <input
              id="myInput"
              type="file"
              ref={(ref) => (this.upload = ref)}
              style={{ display: "none" }}
              onChange={this.props.handleFileSelect}
              onClick={(e) => {
                e.target.value = "";
              }}
              accept="image/jpeg, image/png, image/PNG, application/pdf"
              multiple
            />
            <button
              className="tt-button tt-button-primary tt-button-fullWidth"
              onClick={() => {
                this.upload.click();
              }}
            >
              Attach File
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 text-right">
            <p className="tt-postNoticeHeading">Embed Media</p>
          </div>
          <div className="col-md-4">
            <select
              className="form-control"
              name="selectedOrigin"
              value={this.props.selectedOrigin}
              onChange={this.props.handleChange}
            >
              <option value="" disabled>
                Youtube / Vimeo / Dailymotion / External Link
              </option>
              <option value="youtube">Youtube</option>
              <option value="vimeo">Vimeo</option>
              <option value="dailymotion">Dailymotion</option>
              <option value="externalLink">External Link</option>
              <option value="slideshare">SlideShare</option>
              <option value="soundcloud">Sound Cloud</option>
              {/* <option value="slideshare">SlideShare</option> */}
            </select>
          </div>
          <div className="col-md-4">
            <input
              type="text"
              name="links"
              value={this.props.media}
              placeholder="URL"
              className="form-control"
              onChange={this.props.handleChange}
            ></input>
          </div>
          <div className="col-md-2">
            <button
              className="tt-button tt-button-primary tt-button-fullWidth"
              onClick={this.props.handleMediaChanges}
              disabled={this.props.media === ""}
            >
              Add Link
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 text-right">
            <p className="tt-postNoticeHeading">Notify*</p>
          </div>
          <div className="col-md-4">
            <Select
              closeMenuOnSelect={false}
              isClearable={true}
              components={animatedComponents}
              isMulti
              options={this.state.groupsArray}
              placeholder="Choose Group"
              name="colors"
              onChange={this.props.handleChangeSelect}
              menuPlacement="auto"
              value={this.props.selectedGroups}
            />
          </div>
          <div className="col-md-4">
            <Select
              closeMenuOnSelect={false}
              isClearable={true}
              components={animatedComponents}
              isMulti
              options={this.state.peopleArray}
              placeholder="Choose Individual People"
              name="people"
              onChange={this.props.handleIndividualSelect}
              menuPlacement="auto"
              value={this.props.selectedIndividual}
              isLoading={
                this.state.peopleArray.length > 0 || !this.state.peopleSpinner
                  ? false
                  : true
              }
            />
          </div>
          <div className="col-md-2">
            <button
              className="tt-button tt-button-primary tt-button-fullWidth"
              onClick={(e) =>
                this.props.submitPost(
                  e,
                  postType.noticeCode,
                  this.props.groupPost
                )
              }
              disabled={
                this.props.heading === "" || this.props.textContent === ""
              }
            >
              Post Notice
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Notice;
