import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Spinner } from "reactstrap";
import moment from "moment";

const animatedComponents = makeAnimated();

class CalendarForm extends Component {
  state = {
    selectOptions: [],
    values: [],
  };

  componentDidMount() {
    if (this.props.educationLevels) {
      let selectOptions = [...this.props.educationLevels];
      selectOptions.forEach((level) => {
        level.label = level.name;
        level.value = level.id;
      });
      this.setState({ selectOptions }, () => {
        if (this.props.educationLevelIdMultiple === "0") {
          this.setState({ values: selectOptions });
        } else {
          const index = this.props.educationLevels.findIndex(
            (level) =>
              level.id === parseInt(this.props.educationLevelIdMultiple)
          );

          index !== -1 &&
            this.setState({ values: [...[this.state.selectOptions[index]]] });
        }
      });
    }
  }

  colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: data.color,
      };
    },
  };

  render() {
    let maxEndDate = moment(this.props.maxEndDate).format("YYYY-MM-DD");
    return (
      <form className="form-group">
        <label htmlFor="calendarTitle">
          <strong>
            Event Name  
            <span className="tt-assessment-module-mandatory">*</span>
          </strong>
        </label>
        <input
          type="text"
          id="title"
          name="calendarTitle"
          className="form-control mb-2"
          value={this.props.calendarTitle}
          onChange={this.props.handleChange}
          required
        />
        <div className="row mt-1 mb-1">
          <div className="col-md-6">
            <label>
              <strong>
                Start Date
                <span className="tt-assessment-module-mandatory">*</span>
              </strong>
            </label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={this.props.startDate}
              onChange={this.props.handleChange}
              className="form-control mb-2"
              required
              max={maxEndDate}
            />
          </div>
          <div className="col-md-6">
            <label>
              <strong>
                End Date
                <span className="tt-assessment-module-mandatory">*</span>
              </strong>
            </label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              min={this.props.startDate}
              value={this.props.endDate} //Uncommented
              onChange={this.props.handleChange}
              className="form-control"
              required
              disabled={this.props.startDate === ""}
              max={maxEndDate}
            />
          </div>
        </div>

        {/* {this.props.create ? (
          <div className="py-1">
            <label>
              <strong>Education Level</strong>
            </label>

            <div className="tt-chipBox">
              {this.state.values.length > 0
                ? this.state.values.map((value, idx) => {
                    return (
                      <div className="tt-chipHolder" key={idx}>
                        {value.label}
                      </div>
                    );
                  })
                : "No Education Level"}
            </div>
          </div>
        ) : (
          ""
        )} */}
        <div className="py-1">
          <label>
            <strong>Education Level</strong>
          </label>

          <div className="tt-chipBox">
            {this.state.values.length > 0
              ? this.state.values.map((value, idx) => {
                  return (
                    <div className="tt-chipHolder" key={idx}>
                      {value.label}
                    </div>
                  );
                })
              : "No Education Level"}
          </div>
        </div>

        <strong>
          {" "}
          Event Type
          <span className="tt-assessment-module-mandatory">*</span>
        </strong>
        <div style={{ width: "300px" }} className="mb-1">
          <Select
            styles={this.colorStyles}
            menuPlacement="auto"
            menuPosition="fixed"
            closeMenuOnSelect={false}
            components={animatedComponents}
            options={this.props.selectHolidayOption}
            placeholder="Choose Event Type"
            name="Holiday"
            onChange={this.props.handleChangeEvent}
            closeMenuOnScroll={false}
            value={this.props.selectedEvent}
          />
        </div>
        <label className="mt-1">
          <strong className="optional">Description(Optional):</strong>
        </label>
        <textarea
          className="form-control optional"
          name="description"
          value={this.props.description}
          onChange={this.props.handleChange}
        ></textarea>
        {this.props.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait.
              {this.props.action === "add"
                ? "Creating Event ..."
                : "Updating Event ..."}
            </div>
          </div>
        ) : null}
      </form>
    );
  }
}

export default CalendarForm;
