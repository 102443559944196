import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { axiosPost } from "../../../../utils/AxiosApi";
import {
  checkWidgetAdminOrNot,
  subject,
  URL,
} from "../../../../utils/Constants";
import StudentDetailRevamp from "./StudentDetailRevamp";
import swal from "sweetalert";
import { MESSAGEHEADER } from "../../../../utils/DisplayMessage";
import { showErroMessage } from "../../../../utils/Utils";
import { Spinner } from "reactstrap";
import { faL } from "@fortawesome/free-solid-svg-icons";

// import CreatableSelect from "react-select/creatable";
const animatedComponents = makeAnimated();
const SubjectAssesmentRevampCollapsible = (props) => {
  const [yearLevel, setYearLevel] = useState(null);
  const [classValue, setClassValue] = useState(null);
  const [classroomsList, setClassroomsList] = useState(null);
  const [classRooms, setClassRooms] = useState(null);
  const [assessmentSubjectList, setAssessmentSubjectList] = useState(null);
  const [assessmentSubjectValue, setAssessmentSubjectValue] = useState(null);
  const [allData, setAllData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subjectFinalize, setSubjectFinalize] = useState({});
  const [sectionLoading, setSectionLoading] = useState(false);
  const [assessmentLoading, setAssessmentLoading] = useState(false);
  const [finalizeUnfinalizeClick, setFinalizeUnfinalizeClick] = useState(false);
  const [finalizeUnFinalizeVal, setFinalizeUnFinalizeVal] = useState({});
  const [reRender, setReRender] = useState(false);
  const [finalizeLoader, setFinalizeLoader] = useState(false);

  const getClassrooms = (yearLevelId) => {
    setSectionLoading(true);
    axiosPost(
      URL.selectClassroom,
      {
        yearLevelId: yearLevelId,
      },
      (response) => {
        if (response.status === 200) {
          let classrooms = response.data.data;
          setClassroomsList(
            classrooms.map((item) => ({
              ...item,
              value: item.name,
              label: item.name,
            }))
          );
        }
        setSectionLoading(false);
      },
      (err) => {
        setSectionLoading(false);
      }
    );
  };

  const getYearLevel = () => {
    let param = {
      code: props.code,
    };
    axiosPost(URL.selectYearLevelByTeacher, param, (response) => {
      if (response.status === 200) {
        const yearLevels = response.data.data;
        setYearLevel(
          yearLevels.map((item) => ({
            ...item,
            value: item.name,
            label: item.name,
          }))
        );

        // if (yearLevels.length > 0) {
        //   this.setState({ showSubjectAssessmentSpinner: true });
        // } else {
        //   this.setState({ showSubjectAssessmentSpinner: false });
        // }
        // this.setState(
        //   {
        //     yearLevels,
        //   },
        //   () => {
        //     if (this.state.yearLevels !== null) {
        //       let yearLevels = [...this.state.yearLevels];
        //       yearLevels.forEach((level) => {
        //         level.isYearLevelOpen = false;
        //       });
        //       this.setState({ yearLevels });
        //     }
        //   }
        // );
      }
    });
  };

  useEffect(() => {
    if (checkWidgetAdminOrNot(props.name) === true) {
      let param = {
        assessmentModuleCode: props.code,
      };
      axiosPost(
        URL.getYearLevelByAssessmentSubjectConfig,
        param,
        (response) => {
          if (response.status === 200) {
            const yearLevels = response.data.data;
            setYearLevel(
              yearLevels.map((item) => ({
                ...item,
                value: item.name,
                label: item.name,
              }))
            );
            // if (yearLevels.length > 0) {
            //   this.setState({ showSubjectAssessmentSpinner: true });
            // } else {
            //   this.setState({ showSubjectAssessmentSpinner: false });
            // }
            // this.setState(
            //   {
            //     yearLevels,
            //   },
            //   () => {
            //     let yearLevels = [...this.state.yearLevels];
            //     yearLevels.forEach((level) => {
            //       level.isYearLevelOpen = false;
            //     });
            //     this.setState({ yearLevels });
            //   }
            // );
          }
        }
      );
    } else {
      getYearLevel();
    }
  }, []);

  const handleClassChange = (value) => {
    setClassValue(value);
    setClassRooms(null);
    setAssessmentSubjectValue(null);
    setAllData([]);
    setStudentData([]);

    if (value?.id) {
      getClassrooms(value?.id);
    }
  };

  const handleClassRoomListChange = (value) => {
    setClassRooms(value);
    setAssessmentSubjectValue(null);
    setAllData([]);
    setStudentData([]);
    const payload = {
      yearLevelId: value.yearLevelId,
      educationLevelId: value.educationLevelId,
      classroomId: value.id,
    };
    if (value?.id) {
      // getClassrooms(value?.id);
      getAssessmentWithScaleOrComment(payload);
    }
  };

  const handleAssessmentListChange = (value) => {
    setAssessmentSubjectValue(value);
    setAllData([]);
    setStudentData([]);
    const payload = {
      assessmentId: value.id,
      classroomId: classRooms.id,
      educationLevelDivisionId: value.educationLevelDivisionId,
      educationLevelId: value.educationLevelId,
      yearLevelId: classRooms.yearLevelId,
    };
    if (value?.id) {
      getAssessmentSubjectList(payload);
    }
  };

  const refetchAssessment = () => {
    const payload = {
      assessmentId: assessmentSubjectValue.id,
      classroomId: classRooms.id,
      educationLevelDivisionId: assessmentSubjectValue.educationLevelDivisionId,
      educationLevelId: assessmentSubjectValue.educationLevelId,
      yearLevelId: classRooms.yearLevelId,
    };
    getAssessmentSubjectList(payload);
  };

  const getAssessmentWithScaleOrComment = (idObject) => {
    setAssessmentLoading(true);
    axiosPost(
      URL.getAssessmentWithScaleOrComment,
      idObject,
      (response) => {
        if (response.status === 200) {
          let assessments = response.data.data;
          setAssessmentSubjectList(
            assessments.map((item) => ({
              ...item,
              value: item.assessmentName,
              label: item.assessmentName,
            }))
          );
        }
        setAssessmentLoading(false);
      },
      (err) => {
        setAssessmentLoading(false);
      }
    );
  };
  const practicalCounterHandler = (count, assessment, sub) => {
    if (count.length > 4) {
      swal("Warning", "Maximum one decimal point is allowed");
    } else if (
      parseInt(count) > assessment.assessmentScale.practicalMax ||
      parseInt(count) < 0
    ) {
      swal("Warning", "Input marks is invalid");
    } else {
      let alteredStudentData = studentData.map((item) => {
        if (item.admissionId == assessment.admissionId) {
          return {
            ...item,
            subject: {
              ...item.subject,
              [sub]: [
                {
                  ...item.subject[sub][0],
                  practicalSubjectAssessments: item.subject[
                    sub
                  ][0].practicalSubjectAssessments.map((data) => {
                    if (
                      data.admissionId == assessment.admissionId &&
                      data.uniqueId == assessment.uniqueId
                    ) {
                      return {
                        ...data,
                        practicalObtainedMarks: count,
                      };
                    }
                    return data;
                  }),
                },
              ],
            },
          };
        }
        return item;
      });
      setStudentData(alteredStudentData);
    }
  };

  const theoryCounterHandler = (count, assessment, sub) => {
    if (count.length > 4) {
      swal("Warning", "Maximum one decimal point is allowed");
    } else if (
      parseInt(count) > assessment.assessmentScale.theoryMax ||
      parseInt(count) < 0
    ) {
      swal("Warning", "Input marks is invalid");
    } else {
      let alteredStudentData = studentData.map((item) => {
        if (item.admissionId == assessment.admissionId) {
          return {
            ...item,
            subject: {
              ...item.subject,
              [sub]: [
                {
                  ...item.subject[sub][0],
                  theorySubjectAssessments: item.subject[
                    sub
                  ][0].theorySubjectAssessments.map((data) => {
                    // console.log(
                    //   data,
                    //   assessment,
                    //   data.uniqueId,
                    //   assessment.uniqueId,
                    //   "inside loop"
                    // );
                    if (
                      data.admissionId == assessment.admissionId &&
                      data.uniqueId == assessment.uniqueId
                    ) {
                      return {
                        ...data,
                        theoryObtainedMarks: count,
                      };
                    }
                    return data;
                  }),
                },
              ],
            },
          };
        }
        return item;
      });
      setStudentData(alteredStudentData);
    }
  };

  const handleAssessmentScaleForPracticalLevelChange = (e, assessment, sub) => {
    // assessmentScaleLevelPracticalId

    let alteredStudentData = studentData.map((item) => {
      if (item.admissionId == assessment.admissionId) {
        return {
          ...item,
          subject: {
            ...item.subject,
            [sub]: [
              {
                ...item.subject[sub][0],
                practicalSubjectAssessments: item.subject[
                  sub
                ][0].practicalSubjectAssessments.map((data) => {
                  if (
                    data.admissionId == assessment.admissionId &&
                    data.uniqueId == assessment.uniqueId
                  ) {
                    return {
                      ...data,
                      assessmentScaleLevelPracticalId: e.target.value,
                    };
                  }
                  return data;
                }),
              },
            ],
          },
        };
      }
      return item;
    });
    setStudentData(alteredStudentData);
  };

  const handleAssessmentScaleForTheoryLevelChange = (e, assessment, sub) => {
    let alteredStudentData = studentData.map((item) => {
      if (item.admissionId == assessment.admissionId) {
        return {
          ...item,
          subject: {
            ...item.subject,
            [sub]: [
              {
                ...item.subject[sub][0],
                theorySubjectAssessments: item.subject[
                  sub
                ][0].theorySubjectAssessments.map((data) => {
                  if (
                    data.admissionId == assessment.admissionId &&
                    data.uniqueId == assessment.uniqueId
                  ) {
                    return {
                      ...data,
                      assessmentScaleLevelTheoryId: e.target.value,
                      assessmentScaleTypeCode:
                        assessment.assessmentScaleTypeCode,
                    };
                  }
                  return data;
                }),
              },
            ],
          },
        };
      }
      return item;
    });
    setStudentData(alteredStudentData);
  };

  const handleAssessmentScaleGradePointChange = (e, assessment, sub) => {};

  const handleAssessmentScaleTheoryGradePointChange = (e, assessment, sub) => {
    let alteredStudentData = studentData.map((item) => {
      if (item.admissionId == assessment.admissionId) {
        return {
          ...item,
          subject: {
            ...item.subject,
            [sub]: [
              {
                ...item.subject[sub][0],
                theorySubjectAssessments: item.subject[
                  sub
                ][0].theorySubjectAssessments.map((data) => {
                  if (
                    data.admissionId == assessment.admissionId &&
                    data.uniqueId == assessment.uniqueId
                  ) {
                    return {
                      ...data,
                      assessmentScaleGradePointTheoryId: e.target.value,
                      assessmentScaleTypeCode:
                        assessment.assessmentScaleTypeCode,
                    };
                  }
                  return data;
                }),
              },
            ],
          },
        };
      }
      return item;
    });
    setStudentData(alteredStudentData);
  };

  const handleAssessmentScalePracticalGradePointChange = (
    e,
    assessment,
    sub
  ) => {
    let alteredStudentData = studentData.map((item) => {
      if (item.admissionId == assessment.admissionId) {
        return {
          ...item,
          subject: {
            ...item.subject,
            [sub]: [
              {
                ...item.subject[sub][0],
                practicalSubjectAssessments: item.subject[
                  sub
                ][0].practicalSubjectAssessments.map((data) => {
                  if (
                    data.admissionId == assessment.admissionId &&
                    data.uniqueId == assessment.uniqueId
                  ) {
                    return {
                      ...data,
                      assessmentScaleGradePointPracticalId: e.target.value,
                      assessmentScaleTypeCode:
                        assessment.assessmentScaleTypeCode,
                    };
                  }
                  return data;
                }),
              },
            ],
          },
        };
      }
      return item;
    });
    setStudentData(alteredStudentData);
  };

  const handleIsAbsentForTheory = (e, assessment, sub) => {
    let alteredStudentData = studentData.map((item) => {
      if (item.admissionId == assessment.admissionId) {
        return {
          ...item,
          subject: {
            ...item.subject,
            [sub]: [
              {
                ...item.subject[sub][0],
                theorySubjectAssessments: item.subject[
                  sub
                ][0].theorySubjectAssessments.map((data) => {
                  if (
                    data.admissionId == assessment.admissionId &&
                    data.uniqueId == assessment.uniqueId
                  ) {
                    return {
                      ...data,
                      isTheoryAbsent: e.target.checked,
                      assessmentScaleLevelTheoryId: "",
                      assessmentScaleGradePointTheoryId: "",
                      // theoryObtainedMarks: 0,
                      theoryObtainedMarks: "",
                      assessmentScaleTypeCode:
                        assessment.assessmentScaleTypeCode,
                    };
                  }
                  return data;
                }),
              },
            ],
          },
        };
      }
      return item;
    });
    setStudentData(alteredStudentData);
  };

  const handleIsAbsentForPractical = (e, assessment, sub) => {
    let alteredStudentData = studentData.map((item) => {
      if (item.admissionId == assessment.admissionId) {
        return {
          ...item,
          subject: {
            ...item.subject,
            [sub]: [
              {
                ...item.subject[sub][0],
                practicalSubjectAssessments: item.subject[
                  sub
                ][0].practicalSubjectAssessments.map((data) => {
                  if (
                    data.admissionId == assessment.admissionId &&
                    data.uniqueId == assessment.uniqueId
                  ) {
                    return {
                      ...data,
                      isPracticalAbsent: e.target.checked,
                      assessmentScaleLevelPracticalId: "",
                      assessmentScaleGradePointPracticalId: "",
                      // practicalObtainedMarks: 0,
                      practicalObtainedMarks: "",
                      assessmentScaleTypeCode:
                        assessment.assessmentScaleTypeCode,
                    };
                  }
                  return data;
                }),
              },
            ],
          },
        };
      }
      return item;
    });
    setStudentData(alteredStudentData);
  };

  const getAssessmentSubjectList = (updatedIdObject) => {
    setLoading(true);
    axiosPost(
      URL.getSubjectWithScaleOrComment,
      updatedIdObject,
      (response) => {
        if (response.status === 200) {
          let subjects = response.data.data;
          let temData = {};
          let finalizeCheck = {};

          subjects.forEach((item) => {
            let theory = [];
            let practical = [];

            let t = item.students?.forEach((student) => {
              let finalizeTheory =
                item?.theorySubjectAssessments?.length > 0
                  ? item.theorySubjectAssessments.some(
                      (item) => item.isFinalized
                    )
                  : false;
              let finalizePractical =
                item?.practicalSubjectAssessments?.length > 0
                  ? item.practicalSubjectAssessments.some(
                      (item) => item.isFinalized
                    )
                  : false;
              let finalize = finalizeTheory || finalizePractical;
              // setFinalizeUnFinalizeVal((prev) => {
              //   const newVal = { ...prev, [item.name]: finalize };
              //   return newVal;
              // });

              item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.forEach(
                (d) => {
                  let theoryData = item.theorySubjectAssessments.find(
                    (itm) =>
                      itm.assessmentScaleId == d.id &&
                      itm.admissionId == student.admissionId
                  );
                  if (
                    typeof theoryData == "object" &&
                    typeof theoryData !== null
                  ) {
                    theory.push({
                      ...theoryData,
                      assessmentScale: d,
                      assessmentScaleTypeCode: d.assessmentScaleTypeCode,
                      uniqueId: Math.random(),
                    });
                  } else {
                    theory.push({
                      assessmentScaleId: d.id,
                      admissionId: student.admissionId,
                      isTheoryAbsent: false,
                      uniqueId: Math.random(),
                      assessmentScaleLevelTheoryId: "",
                      assessmentScaleGradePointTheoryId: "",
                      // theoryObtainedMarks: 0,
                      theoryObtainedMarks: "",
                      isFinalized: finalize,
                      assessmentScale: d,
                      assessmentScaleTypeCode: d.assessmentScaleTypeCode,
                    });
                  }
                }
              );
              item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.forEach(
                (d) => {
                  let practicalData = item.practicalSubjectAssessments.find(
                    (itm) =>
                      itm.assessmentScaleId == d.id &&
                      itm.admissionId == student.admissionId
                  );
                  if (
                    typeof practicalData == "object" &&
                    typeof practicalData !== null
                  ) {
                    practical.push({
                      ...practicalData,
                      assessmentScale: d,
                      assessmentScaleTypeCode: d.assessmentScaleTypeCode,
                      uniqueId: Math.random(),
                    });
                  } else {
                    practical.push({
                      assessmentScaleId: d.id,
                      admissionId: student.admissionId,
                      isPracticalAbsent: false,
                      uniqueId: Math.random(),
                      assessmentScaleLevelPracticalId: "",
                      assessmentScaleGradePointPracticalId: "",
                      // practicalObtainedMarks: 0,
                      practicalObtainedMarks: "",
                      isFinalized: finalize,
                      assessmentScale: d,
                      assessmentScaleTypeCode: d.assessmentScaleTypeCode,
                    });
                  }
                }
              );
            });
            temData[item.name] = {
              theory: theory,
              practical: practical,
            };
            finalizeCheck[item.name] =
              item?.theorySubjectAssessments?.length > 0 ||
              item?.practicalSubjectAssessments?.length > 0;
          });

          setSubjectFinalize(finalizeCheck);

          let finalSubjectData = subjects.map((item) => ({
            ...item,
            theorySubjectAssessments: temData[item.name].theory,
            practicalSubjectAssessments: temData[item.name].practical,

            // theorySubjectAssessments: item.students?.map((el) => {
            //   item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
            //     (d) => {
            //       let theoryData = item.theorySubjectAssessments.find(
            //         (item) => item.assessmentScaleId == d.id
            //       );
            //       if (
            //         typeof theoryData == "object" &&
            //         typeof theoryData !== null
            //       ) {
            //         return { ...theoryData, uniqueId: Math.random() };
            //       } else {
            //         return {
            //           admissionId: el.admissionId,
            //           isTheoryAbsent: false,
            //           uniqueId: Math.random(),
            //           assessmentScaleLevelTheoryId: "",
            //           assessmentScaleGradePointTheoryId: "",
            //           theoryObtainedMarks: 0,
            //           isFinalized: finalize,
            //         };
            //       }
            //     }
            //   );

            //   let theoryData = item.theorySubjectAssessments.find(
            //     (item) => item.admissionId == el.admissionId
            //   );
            //   if (typeof theoryData == "object" && typeof theoryData !== null) {
            //     return { ...theoryData, uniqueId: Math.random() };
            //   }

            //   let finalizeTheory =
            //     item?.theorySubjectAssessments?.length > 0
            //       ? item.theorySubjectAssessments.some(
            //           (item) => item.isFinalized
            //         )
            //       : false;
            //   let finalizePractical =
            //     item?.practicalSubjectAssessments?.length > 0
            //       ? item.practicalSubjectAssessments.some(
            //           (item) => item.isFinalized
            //         )
            //       : false;
            //   let finalize = finalizeTheory || finalizePractical;
            //   return {
            //     admissionId: el.admissionId,
            //     isTheoryAbsent: false,
            //     uniqueId: Math.random(),
            //     assessmentScaleLevelTheoryId: "",
            //     assessmentScaleGradePointTheoryId: "",
            //     theoryObtainedMarks: 0,
            //     isFinalized: finalize,
            //   };
            // }),
            // practicalSubjectAssessments: item.students?.map((el) => {
            //   let practicalData = item.practicalSubjectAssessments.find(
            //     (item) => item.admissionId == el.admissionId
            //   );

            //   if (
            //     typeof practicalData == "object" &&
            //     typeof practicalData !== null
            //   ) {
            //     return { ...practicalData, uniqueId: Math.random() };
            //   } else {
            //     let finalizeTheory =
            //       item?.theorySubjectAssessments?.length > 0
            //         ? item.theorySubjectAssessments.some(
            //             (item) => item.isFinalized
            //           )
            //         : false;
            //     let finalizePractical =
            //       item?.practicalSubjectAssessments?.length > 0
            //         ? item.practicalSubjectAssessments.some(
            //             (item) => item.isFinalized
            //           )
            //         : false;
            //     let finalize = finalizeTheory || finalizePractical;
            //     return {
            //       admissionId: el.admissionId,
            //       isPracticalAbsent: false,
            //       uniqueId: Math.random(),
            //       // assessmentScaleLevelPracticalId: "",
            //       assessmentScaleGradePointPracticalId: "",

            //       assessmentScaleLevelPracticalId: "",

            //       practicalObtainedMarks: 0,
            //       isFinalized: finalize,
            //     };
            //   }
            // }),
          }));

          setAllData(finalSubjectData);

          let Students = [];

          let test = finalSubjectData?.forEach((item) =>
            item?.students.forEach((data) => {
              if (
                typeof Students?.find(
                  (el) => el.admissionId == data.admissionId
                ) == "object" &&
                Students?.find((el) => el.admissionId == data.admissionId) !==
                  null
              ) {
                Students = Students.map((std) => {
                  if (std.admissionId === data.admissionId) {
                    return {
                      ...std,
                      subject: {
                        ...std.subject,
                        [item.name]: finalSubjectData
                          .filter((itm) => itm.name == item.name)
                          .map((d) => ({
                            ...d,
                            practicalSubjectAssessments:
                              item.hasPractical &&
                              d.practicalSubjectAssessments?.length > 0
                                ? d.practicalSubjectAssessments.filter(
                                    (itm) => itm.admissionId == data.admissionId
                                  )
                                : [],
                            theorySubjectAssessments:
                              item.hasTheory &&
                              d.theorySubjectAssessments?.length > 0
                                ? d.theorySubjectAssessments.filter(
                                    (itm) => itm.admissionId == data.admissionId
                                  )
                                : [],
                          })),
                      },
                    };
                  }
                  return std;
                });
              } else {
                Students.push({
                  ...data,
                  subject: {
                    [item.name]: finalSubjectData
                      .filter((itm) => itm.name == item.name)
                      .map((d) => ({
                        ...d,
                        practicalSubjectAssessments:
                          item.hasPractical &&
                          d.practicalSubjectAssessments?.length > 0
                            ? d.practicalSubjectAssessments.filter(
                                (itm) => itm.admissionId == data.admissionId
                              )
                            : [],
                        theorySubjectAssessments:
                          item.hasTheory &&
                          d.theorySubjectAssessments?.length > 0
                            ? d.theorySubjectAssessments.filter(
                                (itm) => itm.admissionId == data.admissionId
                              )
                            : [],
                      })),
                  },
                });
              }
            })
          );

          setStudentData(Students);
          setLoading(false);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  const handleFinalize = (item) => {
    setFinalizeLoader(true);
    let practicalFinalize =
      item?.theorySubjectAssessments?.length > 0
        ? item.theorySubjectAssessments.some((item) => item.isFinalized)
        : false;
    let theoryFinalze =
      item?.practicalSubjectAssessments?.length > 0
        ? item.practicalSubjectAssessments.some((item) => item.isFinalized)
        : false;
    let finalizeValue = practicalFinalize || theoryFinalze;

    setFinalizeUnfinalizeClick(finalizeValue);

    setFinalizeUnFinalizeVal((prev) => {
      const newVal = { ...prev, [item.name]: finalizeValue };
      return newVal;
    });
    // finalizeValue ? setFinalizeUnFinalizeVal("Finalize") :
    // setFinalizeUnFinalizeVal("Unfinalize");

    // return  ? "Unfinalize assessment" : "Finalize assessment"

    let param = {
      assessmentId: assessmentSubjectValue.id,
      classroomId: classRooms.id,
      yearLevelId: classRooms.yearLevelId,
      educationLevelId: assessmentSubjectValue.educationLevelId,
      educationLevelDivisionId: assessmentSubjectValue.educationLevelDivisionId,
      subjectId: item.id,
      isFinalized: !finalizeValue,
    };

    if (!finalizeValue) {
      new Promise((resolve, reject) => {
        handleSaveIndividualSubject(item, resolve, reject);
      })
        .then(() => {
          axiosPost(
            URL.updateStudentSubjectAssessmentIsFinalized,
            param,
            (response) => {
              if (response.status === 200) {
                if (finalizeValue) {
                  swal(
                    MESSAGEHEADER.success,
                    "Assessment successfully unfinalized."
                  );
                } else {
                  swal(
                    MESSAGEHEADER.success,
                    "Assessment successfully finalized."
                  );
                }
                // refetchAssessment();
                if (!finalizeValue) {
                  // when clicking finalize
                  refetchFinalizeAssesment();
                } else {
                  // when clicking unfinalize
                  refetchFinalizeAssesment();
                }
              } else {
                showErroMessage(response);
              }
            }
          );
        })
        .catch((error) => {
          setFinalizeLoader(false);
          console.error(error);
        });
    } else {
      axiosPost(
        URL.updateStudentSubjectAssessmentIsFinalized,
        param,
        (response) => {
          if (response.status === 200) {
            if (finalizeValue) {
              swal(
                MESSAGEHEADER.success,
                "Assessment successfully unfinalized."
              );
            } else {
              swal(MESSAGEHEADER.success, "Assessment successfully finalized.");
            }
            // refetchAssessment();
            if (!finalizeValue) {
              // when clicking finalize
              // refetchFinalizeAssesment()
              handleSaveIndividualSubject(item);
            } else {
              // when clicking unfinalize
              refetchFinalizeAssesment();
            }
          } else {
            showErroMessage(response);
          }
        }
      );
    }
  };

  const handleSaveIndividualSubject = (item, resolve, reject) => {
    let result = { success: [], failure: [] };
    function handleRes(r) {
      // refetchAssessment();
      if (r.failure.length > 0) {
        swal({
          title: "Error",
          text: "Error submitting assessment records!",
        });
      } else {
        swal("Success", "Assesment record submitted successfully", "");
      }
    }

    let checkLength = 0;
    let practicalFinalize =
      item?.theorySubjectAssessments?.length > 0
        ? item.theorySubjectAssessments.some((item) => item.isFinalized)
        : false;
    let theoryFinalze =
      item?.practicalSubjectAssessments?.length > 0
        ? item.practicalSubjectAssessments.some((item) => item.isFinalized)
        : false;
    let finalizeValue = practicalFinalize || theoryFinalze;

    if (!finalizeValue) {
      checkLength = checkLength + 1;
    }

    let ran = 0;
    let studentSubjectAssessmentsParam = [];
    studentData.forEach((student) => {
      let filtredStudentSubDetail = student?.subject[item.name]?.[0];
      if (!filtredStudentSubDetail) {
        return;
      }
      let studentSubjectAssessmentParam = {
        assessmentId: assessmentSubjectValue.id,
        classroomId: classRooms.id,
        yearLevelId: classRooms.yearLevelId,
        educationLevelId: assessmentSubjectValue.educationLevelId,
        educationLevelDivisionId:
          assessmentSubjectValue.educationLevelDivisionId,
        subjectId: item.id,
        admissionId: student.admissionId, // student come from for each loop.
        commentText: "",
      };

      let studentSubjectAssessmentsPractical = [];
      let studentSubjectAssessmentsTheory = [];

      if (
        filtredStudentSubDetail?.subjectAssessmentScalesTheoryPractcal
          ?.assessmentScalesForPractical?.length > 0
      ) {
        filtredStudentSubDetail.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.forEach(
          (assessmentScale) => {
            let assessmentDetailPractical =
              filtredStudentSubDetail.practicalSubjectAssessments.filter(
                (val) => val.assessmentScaleId == assessmentScale.id
              )[0];
            let studentSubjectAssessment;
            if (!assessmentDetailPractical) {
              return;
              // studentSubjectAssessment = {
              //   assessmentScalePracticalId: assessmentScale.id,
              //   isPracticalAbsent: false,
              //   assessmentScaleMarkPracticalId: null,
              //   practicalObtainedMarks: 0,
              //   assessmentScaleLevelPracticalId: null,
              //   assessmentScaleGradePointPracticalId: null,
              // };
            } else {
              studentSubjectAssessment = {
                assessmentScalePracticalId: assessmentScale?.id,
                isPracticalAbsent: assessmentDetailPractical.isPracticalAbsent,
                assessmentScaleMarkPracticalId:
                  assessmentScale.assessmentScaleMark
                    ? assessmentScale.assessmentScaleMark.id
                    : null,
                practicalObtainedMarks:
                  assessmentDetailPractical.practicalObtainedMarks,
                assessmentScaleLevelPracticalId:
                  assessmentDetailPractical.assessmentScaleLevelPracticalId ==
                  ""
                    ? null
                    : assessmentDetailPractical.assessmentScaleLevelPracticalId,
                assessmentScaleGradePointPracticalId:
                  assessmentDetailPractical.assessmentScaleGradePointPracticalId ==
                  ""
                    ? null
                    : assessmentDetailPractical.assessmentScaleGradePointPracticalId,
              };
            }

            studentSubjectAssessmentsPractical.push(studentSubjectAssessment);
          }
        );
      } else {
        let a =
          filtredStudentSubDetail?.subjectAssessmentScalesTheoryPractcal?.assessmentScalesForTheory?.forEach(
            (assessmentScale) => {
              let studentSubjectAssessment = {
                assessmentScalePracticalId: null,
                isPracticalAbsent: null,
                assessmentScaleMarkPracticalId: null,
                // practicalObtainedMarks: null,
                practicalObtainedMarks: "",
                assessmentScaleLevelPracticalId: null,
                assessmentScaleGradePointPracticalId: null,
              };
              studentSubjectAssessmentsPractical.push(studentSubjectAssessment);
            }
          );
      }

      studentSubjectAssessmentParam.studentSubjectAssessmentsPractical =
        studentSubjectAssessmentsPractical;

      if (
        filtredStudentSubDetail.subjectAssessmentScalesTheoryPractcal
          ?.assessmentScalesForTheory?.length > 0
      ) {
        filtredStudentSubDetail.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.forEach(
          (assessmentScale) => {
            let assessmentDetailTheory =
              filtredStudentSubDetail.theorySubjectAssessments.filter(
                (val) => val.assessmentScaleId == assessmentScale.id
              )[0];
            let studentSubjectAssessment;
            if (!assessmentDetailTheory) {
              return;
              // studentSubjectAssessment = {
              //   assessmentScaleTheoryId: null,
              //   isTheoryAbsent: null,
              //   assessmentScaleMarkTheoryId: null,
              //   theoryObtainedMarks: null,
              //   assessmentScaleLevelTheoryId: null,
              //   assessmentScaleGradePointTheoryId: null,
              // };
            } else {
              studentSubjectAssessment = {
                assessmentScaleTheoryId: assessmentScale.id,
                isTheoryAbsent: assessmentDetailTheory.isTheoryAbsent,
                assessmentScaleMarkTheoryId: assessmentScale.assessmentScaleMark
                  ? assessmentScale.assessmentScaleMark.id
                  : null,
                theoryObtainedMarks: assessmentDetailTheory.theoryObtainedMarks,
                assessmentScaleLevelTheoryId:
                  assessmentDetailTheory.assessmentScaleLevelTheoryId == ""
                    ? null
                    : assessmentDetailTheory.assessmentScaleLevelTheoryId,
                assessmentScaleGradePointTheoryId:
                  assessmentDetailTheory.assessmentScaleGradePointTheoryId == ""
                    ? null
                    : assessmentDetailTheory.assessmentScaleGradePointTheoryId,
              };
            }

            studentSubjectAssessmentsTheory.push(studentSubjectAssessment);
          }
        );
      } else {
        filtredStudentSubDetail.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.forEach(
          (assessmentScale) => {
            let studentSubjectAssessment = {
              assessmentScaleTheoryId: null,
              isTheoryAbsent: null,
              assessmentScaleMarkTheoryId: null,
              // theoryObtainedMarks: null,
              theoryObtainedMarks: "",
              assessmentScaleLevelTheoryId: null,
              assessmentScaleGradePointTheoryId: null,
            };
            studentSubjectAssessmentsTheory.push(studentSubjectAssessment);
          }
        );
      }
      studentSubjectAssessmentParam.studentSubjectAssessmentsTheory =
        studentSubjectAssessmentsTheory;
      studentSubjectAssessmentsParam.push(studentSubjectAssessmentParam);
    });

    axiosPost(
      URL.insertMultipleStudentSubjectAssessments,
      studentSubjectAssessmentsParam,
      (response) => {
        if (response.status === 200) {
          resolve();
          result.success.push(response.data.message);
          refetchFinalizeAssesment();
          if (ran == checkLength) {
            handleRes(result);
          }
        } else {
          result.failure.push("Error submitting assessment records!");
          if (ran == checkLength) {
            handleRes(result);
          }
        }
      },
      (failure) => {
        reject();
        result.failure.push("Error submitting assessment records!");
        if (ran == checkLength) {
          handleRes(result);
        }
      }
    );
  };

  const refetchFinalizeAssesment = () => {
    const payload = {
      assessmentId: assessmentSubjectValue.id,
      classroomId: classRooms.id,
      educationLevelDivisionId: assessmentSubjectValue.educationLevelDivisionId,
      educationLevelId: assessmentSubjectValue.educationLevelId,
      yearLevelId: classRooms.yearLevelId,
    };
    axiosPost(
      URL.getSubjectWithScaleOrComment,
      payload,
      (response) => {
        setFinalizeLoader(false);
        if (response.status === 200) {
          let subjects = response.data.data;
          let temData = {};
          let finalizeCheck = {};

          subjects.forEach((item) => {
            let theory = [];
            let practical = [];

            let t = item.students?.forEach((student) => {
              let finalizeTheory =
                item?.theorySubjectAssessments?.length > 0
                  ? item.theorySubjectAssessments.some(
                      (item) => item.isFinalized
                    )
                  : false;
              let finalizePractical =
                item?.practicalSubjectAssessments?.length > 0
                  ? item.practicalSubjectAssessments.some(
                      (item) => item.isFinalized
                    )
                  : false;
              let finalize = finalizeTheory || finalizePractical;

              // setFinalizeUnFinalizeVal((prev) => {
              //   const newVal = { ...prev, [item.name]: finalize };
              //   return newVal;
              // });

              item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.forEach(
                (d) => {
                  let theoryData = item.theorySubjectAssessments.find(
                    (itm) =>
                      itm.assessmentScaleId == d.id &&
                      itm.admissionId == student.admissionId
                  );
                  if (
                    typeof theoryData == "object" &&
                    typeof theoryData !== null
                  ) {
                    theory.push({
                      ...theoryData,
                      assessmentScale: d,
                      assessmentScaleTypeCode: d.assessmentScaleTypeCode,
                      uniqueId: Math.random(),
                    });
                  } else {
                    theory.push({
                      assessmentScaleId: d.id,
                      admissionId: student.admissionId,
                      isTheoryAbsent: false,
                      uniqueId: Math.random(),
                      assessmentScaleLevelTheoryId: "",
                      assessmentScaleGradePointTheoryId: "",
                      // theoryObtainedMarks: 0,
                      theoryObtainedMarks: "",
                      isFinalized: finalize,
                      assessmentScale: d,
                      assessmentScaleTypeCode: d.assessmentScaleTypeCode,
                    });
                  }
                }
              );
              item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.forEach(
                (d) => {
                  let practicalData = item.practicalSubjectAssessments.find(
                    (itm) =>
                      itm.assessmentScaleId == d.id &&
                      itm.admissionId == student.admissionId
                  );
                  if (
                    typeof practicalData == "object" &&
                    typeof practicalData !== null
                  ) {
                    practical.push({
                      ...practicalData,
                      assessmentScale: d,
                      assessmentScaleTypeCode: d.assessmentScaleTypeCode,
                      uniqueId: Math.random(),
                    });
                  } else {
                    practical.push({
                      assessmentScaleId: d.id,
                      admissionId: student.admissionId,
                      isPracticalAbsent: false,
                      uniqueId: Math.random(),
                      assessmentScaleLevelPracticalId: "",
                      assessmentScaleGradePointPracticalId: "",
                      // practicalObtainedMarks: 0,
                      practicalObtainedMarks: "",
                      isFinalized: finalize,
                      assessmentScale: d,
                      assessmentScaleTypeCode: d.assessmentScaleTypeCode,
                    });
                  }
                }
              );
            });
            temData[item.name] = {
              theory: theory,
              practical: practical,
            };
            finalizeCheck[item.name] =
              item?.theorySubjectAssessments?.length > 0 ||
              item?.practicalSubjectAssessments?.length > 0;
          });

          setSubjectFinalize(finalizeCheck);

          let finalSubjectData = subjects.map((item) => ({
            ...item,
            theorySubjectAssessments: temData[item.name].theory,
            practicalSubjectAssessments: temData[item.name].practical,
          }));

          setAllData(finalSubjectData);

          let Students = [];

          let test = finalSubjectData?.forEach((item) =>
            item?.students.forEach((data) => {
              if (
                typeof Students?.find(
                  (el) => el.admissionId == data.admissionId
                ) == "object" &&
                Students?.find((el) => el.admissionId == data.admissionId) !==
                  null
              ) {
                Students = Students.map((std) => {
                  if (std.admissionId === data.admissionId) {
                    return {
                      ...std,
                      subject: {
                        ...std.subject,
                        [item.name]: finalSubjectData
                          .filter((itm) => itm.name == item.name)
                          .map((d) => ({
                            ...d,
                            practicalSubjectAssessments:
                              item.hasPractical &&
                              d.practicalSubjectAssessments?.length > 0
                                ? d.practicalSubjectAssessments.filter(
                                    (itm) => itm.admissionId == data.admissionId
                                  )
                                : [],
                            theorySubjectAssessments:
                              item.hasTheory &&
                              d.theorySubjectAssessments?.length > 0
                                ? d.theorySubjectAssessments.filter(
                                    (itm) => itm.admissionId == data.admissionId
                                  )
                                : [],
                          })),
                      },
                    };
                  }
                  return std;
                });
              } else {
                Students.push({
                  ...data,
                  subject: {
                    [item.name]: finalSubjectData
                      .filter((itm) => itm.name == item.name)
                      .map((d) => ({
                        ...d,
                        practicalSubjectAssessments:
                          item.hasPractical &&
                          d.practicalSubjectAssessments?.length > 0
                            ? d.practicalSubjectAssessments.filter(
                                (itm) => itm.admissionId == data.admissionId
                              )
                            : [],
                        theorySubjectAssessments:
                          item.hasTheory &&
                          d.theorySubjectAssessments?.length > 0
                            ? d.theorySubjectAssessments.filter(
                                (itm) => itm.admissionId == data.admissionId
                              )
                            : [],
                      })),
                  },
                });
              }
            })
          );

          setStudentData(Students);
          setLoading(false);
        }
      },
      (err) => {
        setFinalizeLoader(false);
        setLoading(false);
      }
    );
  };

  const handleSubmit = (e) => {
    let result = { success: [], failure: [] };
    // return 1;

    setIsSubmitting(true);
    function handleRes(r) {
      setIsSubmitting(false);
      refetchAssessment();
      if (r.failure.length > 0) {
        swal({
          title: "Error",
          text: "Error submitting assessment records!",
        });
      } else {
        swal("Success", "Assesment record submitted successfully", "");
      }
    }

    let checkLength = 0;
    allData.forEach((item, idx) => {
      let practicalFinalize =
        item?.theorySubjectAssessments?.length > 0
          ? item.theorySubjectAssessments.some((item) => item.isFinalized)
          : false;
      let theoryFinalze =
        item?.practicalSubjectAssessments?.length > 0
          ? item.practicalSubjectAssessments.some((item) => item.isFinalized)
          : false;
      let finalizeValue = practicalFinalize || theoryFinalze;

      if (!finalizeValue) {
        checkLength = checkLength + 1;
      }
    });
    let ran = 0;
    allData.map((item, idx) => {
      let practicalFinalize =
        item?.theorySubjectAssessments?.length > 0
          ? item.theorySubjectAssessments.some((item) => item.isFinalized)
          : false;
      let theoryFinalze =
        item?.practicalSubjectAssessments?.length > 0
          ? item.practicalSubjectAssessments.some((item) => item.isFinalized)
          : false;
      let finalizeValue = practicalFinalize || theoryFinalze;

      if (!finalizeValue) {
        ran += 1;
        let studentSubjectAssessmentsParam = [];
        studentData.forEach((student) => {
          let filtredStudentSubDetail = student?.subject[item.name]?.[0];
          if (!filtredStudentSubDetail) {
            return;
          }
          let studentSubjectAssessmentParam = {
            assessmentId: assessmentSubjectValue.id,
            classroomId: classRooms.id,
            yearLevelId: classRooms.yearLevelId,
            educationLevelId: assessmentSubjectValue.educationLevelId,
            educationLevelDivisionId:
              assessmentSubjectValue.educationLevelDivisionId,
            subjectId: item.id,
            admissionId: student.admissionId, // student come from for each loop.
            commentText: "",
          };

          let studentSubjectAssessmentsPractical = [];
          let studentSubjectAssessmentsTheory = [];

          if (
            filtredStudentSubDetail?.subjectAssessmentScalesTheoryPractcal
              ?.assessmentScalesForPractical?.length > 0
          ) {
            filtredStudentSubDetail.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.forEach(
              (assessmentScale) => {
                let assessmentDetailPractical =
                  filtredStudentSubDetail.practicalSubjectAssessments.filter(
                    (val) => val.assessmentScaleId == assessmentScale.id
                  )[0];
                let studentSubjectAssessment;
                if (!assessmentDetailPractical) {
                  return;
                  // studentSubjectAssessment = {
                  //   assessmentScalePracticalId: assessmentScale.id,
                  //   isPracticalAbsent: false,
                  //   assessmentScaleMarkPracticalId: null,
                  //   practicalObtainedMarks: 0,
                  //   assessmentScaleLevelPracticalId: null,
                  //   assessmentScaleGradePointPracticalId: null,
                  // };
                } else {
                  studentSubjectAssessment = {
                    assessmentScalePracticalId: assessmentScale?.id,
                    isPracticalAbsent:
                      assessmentDetailPractical.isPracticalAbsent,
                    assessmentScaleMarkPracticalId:
                      assessmentScale.assessmentScaleMark
                        ? assessmentScale.assessmentScaleMark.id
                        : null,
                    practicalObtainedMarks:
                      assessmentDetailPractical.practicalObtainedMarks,
                    assessmentScaleLevelPracticalId:
                      assessmentDetailPractical.assessmentScaleLevelPracticalId ==
                      ""
                        ? null
                        : assessmentDetailPractical.assessmentScaleLevelPracticalId,
                    assessmentScaleGradePointPracticalId:
                      assessmentDetailPractical.assessmentScaleGradePointPracticalId ==
                      ""
                        ? null
                        : assessmentDetailPractical.assessmentScaleGradePointPracticalId,
                  };
                }

                studentSubjectAssessmentsPractical.push(
                  studentSubjectAssessment
                );
              }
            );
          } else {
            let a =
              filtredStudentSubDetail?.subjectAssessmentScalesTheoryPractcal?.assessmentScalesForTheory?.forEach(
                (assessmentScale) => {
                  let studentSubjectAssessment = {
                    assessmentScalePracticalId: null,
                    isPracticalAbsent: null,
                    assessmentScaleMarkPracticalId: null,
                    // practicalObtainedMarks: null,
                    practicalObtainedMarks: "",
                    assessmentScaleLevelPracticalId: null,
                    assessmentScaleGradePointPracticalId: null,
                  };
                  studentSubjectAssessmentsPractical.push(
                    studentSubjectAssessment
                  );
                }
              );
          }

          studentSubjectAssessmentParam.studentSubjectAssessmentsPractical =
            studentSubjectAssessmentsPractical;

          if (
            filtredStudentSubDetail.subjectAssessmentScalesTheoryPractcal
              ?.assessmentScalesForTheory?.length > 0
          ) {
            filtredStudentSubDetail.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.forEach(
              (assessmentScale) => {
                let assessmentDetailTheory =
                  filtredStudentSubDetail.theorySubjectAssessments.filter(
                    (val) => val.assessmentScaleId == assessmentScale.id
                  )[0];
                let studentSubjectAssessment;
                if (!assessmentDetailTheory) {
                  return;
                  // studentSubjectAssessment = {
                  //   assessmentScaleTheoryId: null,
                  //   isTheoryAbsent: null,
                  //   assessmentScaleMarkTheoryId: null,
                  //   theoryObtainedMarks: null,
                  //   assessmentScaleLevelTheoryId: null,
                  //   assessmentScaleGradePointTheoryId: null,
                  // };
                } else {
                  studentSubjectAssessment = {
                    assessmentScaleTheoryId: assessmentScale.id,
                    isTheoryAbsent: assessmentDetailTheory.isTheoryAbsent,
                    assessmentScaleMarkTheoryId:
                      assessmentScale.assessmentScaleMark
                        ? assessmentScale.assessmentScaleMark.id
                        : null,
                    theoryObtainedMarks:
                      assessmentDetailTheory.theoryObtainedMarks,
                    assessmentScaleLevelTheoryId:
                      assessmentDetailTheory.assessmentScaleLevelTheoryId == ""
                        ? null
                        : assessmentDetailTheory.assessmentScaleLevelTheoryId,
                    assessmentScaleGradePointTheoryId:
                      assessmentDetailTheory.assessmentScaleGradePointTheoryId ==
                      ""
                        ? null
                        : assessmentDetailTheory.assessmentScaleGradePointTheoryId,
                  };
                }

                studentSubjectAssessmentsTheory.push(studentSubjectAssessment);
              }
            );
          } else {
            filtredStudentSubDetail.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.forEach(
              (assessmentScale) => {
                let studentSubjectAssessment = {
                  assessmentScaleTheoryId: null,
                  isTheoryAbsent: null,
                  assessmentScaleMarkTheoryId: null,
                  // theoryObtainedMarks: null,
                  theoryObtainedMarks: "",
                  assessmentScaleLevelTheoryId: null,
                  assessmentScaleGradePointTheoryId: null,
                };
                studentSubjectAssessmentsTheory.push(studentSubjectAssessment);
              }
            );
          }
          studentSubjectAssessmentParam.studentSubjectAssessmentsTheory =
            studentSubjectAssessmentsTheory;
          studentSubjectAssessmentsParam.push(studentSubjectAssessmentParam);
        });

        axiosPost(
          URL.insertMultipleStudentSubjectAssessments,
          studentSubjectAssessmentsParam,
          (response) => {
            if (response.status === 200) {
              result.success.push(response.data.message);

              if (ran == checkLength) {
                handleRes(result);
              }
            } else {
              result.failure.push("Error submitting assessment records!");
              if (ran == checkLength) {
                handleRes(result);
              }
            }
          },
          (failure) => {
            result.failure.push("Error submitting assessment records!");
            if (ran == checkLength) {
              handleRes(result);
            }
          }
        );
      }
    });
  };

  return (
    <div>
      {isSubmitting ? (
        <div className="fullWindow-Spinner">
          <div>
            <Spinner color="white"></Spinner>
          </div>
          <div style={{ fontSize: "16px", marginTop: "15px" }}>
            Please Wait. Processing...
          </div>
        </div>
      ) : null}
      <div className="d-flex justify-content-between" style={{ gap: "1rem" }}>
        <Select
          className="w-100"
          closeMenuOnSelect={true}
          components={animatedComponents}
          value={classValue}
          options={yearLevel}
          name="class"
          onChange={handleClassChange}
          placeholder="Select Class"
        />

        <Select
          isDisabled={!!!classValue}
          className="w-100"
          closeMenuOnSelect={true}
          components={animatedComponents}
          value={classRooms}
          options={classroomsList}
          name="classRooms"
          onChange={handleClassRoomListChange}
          placeholder={sectionLoading ? "Loading..." : "Select Section"}
        />

        <Select
          isDisabled={!!!classRooms}
          className="w-100"
          closeMenuOnSelect={true}
          components={animatedComponents}
          value={assessmentSubjectValue}
          options={assessmentSubjectList}
          name="assessmentList"
          onChange={handleAssessmentListChange}
          placeholder={assessmentLoading ? "Loading..." : "Select Assessment"}
        />
      </div>
      <div className="mt-4">
        <StudentDetailRevamp
        classValue={classValue}
        classRooms={classRooms}
        assessmentSubjectValue={assessmentSubjectValue}
          finalizeLoader={finalizeLoader}
          loading={loading}
          studentData={studentData}
          allData={allData}
          handleAssessmentScaleForPracticalLevelChange={
            handleAssessmentScaleForPracticalLevelChange
          }
          handleAssessmentScaleForTheoryLevelChange={
            handleAssessmentScaleForTheoryLevelChange
          }
          handleAssessmentScaleGradePointChange={
            handleAssessmentScaleGradePointChange
          }
          handleAssessmentScaleTheoryGradePointChange={
            handleAssessmentScaleTheoryGradePointChange
          }
          handleAssessmentScalePracticalGradePointChange={
            handleAssessmentScalePracticalGradePointChange
          }
          theoryCounterHandler={theoryCounterHandler}
          practicalCounterHandler={practicalCounterHandler}
          handleIsAbsentForTheory={handleIsAbsentForTheory}
          handleIsAbsentForPractical={handleIsAbsentForPractical}
          handleFinalize={handleFinalize}
          handleSubmit={handleSubmit}
          subjectFinalize={subjectFinalize}
          finalizeUnfinalizeClick={finalizeUnfinalizeClick}
          finalizeUnFinalizeVal={finalizeUnFinalizeVal}
        />
      </div>
    </div>
  );
};

export default SubjectAssesmentRevampCollapsible;
