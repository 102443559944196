/**
 * @author      Suyog Manandhar
 * @version     2.0
 * @description This class, AdmissionList, render the List of tuples from the admission table
 * @edited      @author Sandeep Shakya
 */

import React, { Component } from "react";
import { axiosPost } from "../../../utils/AxiosApi";
import {
  URL,
  draggable,
  checkRolePermissions,
  checkWidgetAdminOrNot,
} from "../../../utils/Constants";
import ModalWindow from "../../UI/ModalWindow";
import AdmissionModalBody from "./ModalBody";
import swal from "@sweetalert/with-react";
import { connect } from "react-redux";
import {
  getAdmissionList,
  getAdmissionListPagination,
} from "../../../ducks/AdmissionDucks";
import "../../../assets/scss/admissionList.scss";
import moment from "moment";
import userImage from "../../../assets/images/user-image.png";
import { Spinner } from "reactstrap";
import GranularPermissionModal from "./../../Permissions/GranularPermissionModal";
import Pagination from "../../UI/Pagination";
import { displayNepaliDate, handleError } from "../../../utils/Utils";
import Snackbar from "@material-ui/core/Snackbar";
import { Link } from "react-router-dom";
import { groupBy } from "lodash";
import AdmissionForm from "../Admission/AdmissionForm";
import { adToBs } from "meropatro-dateconverter";

class AdmissionList extends Component {
  state = {
    admissionList: [],
    admissionModal: false,
    admissionListBySelect: [],
    admissionListByNonCluster: [],

    studentContactBySelect: [],
    admissionStatusId: "",
    name: "",
    selectedAdmissionStatus: "",
    admissionModalData: [],
    countries: null,
    yearLevels: null,
    admissionStatuses: null,
    listSize: null,
    nameFields: null,
    searchName: "",
    currentListName: "Current",
    displaySpinner: true,
    searchValue: "",
    countryId: "",
    yearLevelSearchId: "",
    pageSize: 20,
    startIndex: 0,
    endIndex: 20,
    editActivityCode: "edit-admission",
    detailActivityCode: "view-detail-admission",
    genders: [],
    lastNameCode: "",
    currentPage: 1,
    totalRecords: 0,
    offset: 0,
    searchData: "",
    admissionSearch: false,
    linkCopied: false,
    updateDisabled: false,
    showSpinner: false,
    peopleAddress: [],
    admissionData: null,
    admissionId: null,
    peopleId: null,
    admissionStatusId: null,
    permissionEditAdmission: false,
    permissionViewDetailAdmission: false,
  };

  componentDidUpdate(nextProps, prevState) {
    if (nextProps.admissionList !== this.props.admissionList) {
      this.setState(
        {
          totalRecords: this.props.totalRecords,
          admissionList: this.props.admissionList,
          //   admissionListCopy: this.props.admissionList,
        },
        function () {
          this.setDisplayNumber();
          this.setState({ displaySpinner: false });
        }
      );
    }
  }

  /**
   * This method is invoked immediately after a component is mounted (inserted into the tree) and it fetches
   * all the Admission Lists from the database.
   *
   */
  componentDidMount() {
    this.getAdmissionStatus();
    this.getCountryList();
    this.getYearLevels();
    this.getNameFields();
    this.getGenders();
    if (this.props.fromEducationCommunity) {
      this.setState({
        editActivityCode: "edit-student-list",
        detailActivityCode: "detail-student-list",
      });
    }
    this.checkPermissions();
  }

  checkPermissions = () => {
    this.setState({
      permissionEditAdmission: checkRolePermissions(
        this.state.detailActivityCode,
        "activity"
      ),
      permissionViewDetailAdmission: checkRolePermissions(
        this.state.editActivityCode,
        "activity"
      ),
    });
  };

  getGenders = () => {
    axiosPost(URL.getGenders, {}, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({ genders: data });
      }
    });
  };
  /**
   * @author Saurav Sitaula
   */
  getCountryList = () => {
    axiosPost(URL.getCountry, {}, (response) => {
      if (response.status === 200) {
        this.setState({ countries: response.data.data });
      }
    });
  };

  /**
   * @author Saurav Sitaula
   */
  getYearLevels = () => {
    axiosPost(URL.getYearLevel, {}, (response) => {
      if (response.status === 200) {
        this.setState({ yearLevels: response.data.data });
      }
    });
  };

  /**
   * @author Saurav Sitaula
   */
  getNameFields = () => {
    axiosPost(
      URL.getIdentityCluster,
      {
        isConfig: false,
      },
      (response) => {
        if (response.status === 200) {
          if (response.data.data.length > 0) {
            this.setState({
              nameFields: response.data.data,
              searchName: response.data.data[0].id
                ? response.data.data[0].id
                : "",
            });
            let nameFields = response.data.data;
            if (nameFields.length > 0) {
              nameFields.forEach((al) => {
                if (al.fieldName === "Last Name") {
                  this.setState({ lastNameCode: al.id });
                }
              });
            }
          }
        }
      }
    );
  };

  nameFormatter = (name) => {
    let convertedName = "";
    for (let i = 0; i < name.length; i++) {
      if (i === 0) {
        convertedName += name.charAt(i).toUpperCase();
      } else {
        if (name.charAt(i - 1) === " " && name.charAt(i) !== name.length) {
          convertedName += name.charAt(i).toUpperCase();
        } else {
          convertedName += name.charAt(i).toLowerCase();
        }
      }
    }
    return convertedName;
  };

  getAdmissionStatus = () => {
    const params = {
      isVisible: true,
    };
    axiosPost(
      URL.getAdmissionStatus,
      this.props.isVisible ? params : {},
      (response) => {
        if (response.status === 200) {
          this.setState({ admissionStatuses: response.data.data }, function () {
            let status = this.state.admissionStatuses;
            let id = "";
            status.forEach((el) => {
              if (el.name === "Current") {
                id = el.id;
              }
            });
            this.setState({ admissionStatusId: id }, function () {
              this.props.getAdmissionListPagination(
                this.state.admissionStatusId,
                this.state.offset,
                this.state.pageSize
              );
              //   this.props.getAdmissionList(this.state.admissionStatusId);
            });
          });
        }
      }
    );
  };

  /**
   * This methods toggles the model window in the AdmissionList Component
   * @param e is the synthetic event
   * @param el is the corresponding mapped element
   */
  toggleAdmissionModalWithData = (e, el) => {
    this.setState(
      {
        admissionModal: true,
        name: el.people.name,
        admissionModalData: el,
      },
      () => {
        this.getAdmissionListBySelect(el.id);
      }
    );
  };

  toggleAdmissionModal = () => {
    this.setState({
      admissionModal: !this.state.admissionModal,
    });
  };
  /**
   * This method fetches the admission list with respect to the id
   * @param id is the corresponding to the selected toggle button
   */
  getAdmissionListBySelect = (id) => {
    axiosPost(
      URL.selectByKeyAdmissionList,
      {
        id: parseInt(id),
      },
      (response) => {
        if (response.status === 200) {
          const admissionListBySelect =
            response.data.data.people.customTableFieldValues,
            studentContactBySelect = response.data.data.studentContacts;
          const peopleAddress = response.data.data.people.peopleAddresses;
          this.setState(
            {
              admissionListBySelect,
              studentContactBySelect,
              peopleAddress: peopleAddress,
            },
            () => {
              axiosPost(
                URL.selectAdmissionByNonClusterFields,
                {
                  id: parseInt(id),
                },
                (response) => {
                  if (response.status === 200) {
                    const values =
                      response.data.data.people.customTableFieldValues;
                    values.forEach((field) => {
                      if (field.fieldTypeCode === "table") {
                        field.highestLength = 0;
                        field.customFieldTableColumns.forEach((column) => {
                          if (column.customFieldTableSubColumns === null) {
                            column.customFieldTableColumnValues.forEach(
                              (value) => {
                                if (value.value.length > field.highestLength) {
                                  field.highestLength = value.value.length;
                                }
                              }
                            );
                          } else {
                            column.customFieldTableSubColumns.forEach(
                              (subCol) => {
                                subCol.customFieldTableColumnValues.forEach(
                                  (value) => {
                                    if (
                                      value.value.length > field.highestLength
                                    ) {
                                      field.highestLength = value.value.length;
                                    }
                                  }
                                );
                              }
                            );
                          }
                        });
                      }
                    });

                    const groupedValues = groupBy(values, "groupName");
                    this.setState({
                      admissionListByNonCluster: groupedValues,
                    });
                  }
                }
              );
            }
          );
        }
      }
    );
  };

  handleSelectChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState({ [name]: value }, () => {
      if (name === "pageSize") {
        this.setState({ displaySpinner: true, currentPage: 1 }, function () {
          this.handlePageChange(this.state.currentPage);
        });
      }
    });
  };

  handleAdmissionSearch = (alphabet) => {
    let data = {
      admissionStatusId: this.state.admissionStatusId,
      countryId: this.state.countryId,
      gender: this.state.gender,
      yearLevelId: this.state.yearLevelSearchId,
    };
    if (alphabet !== "noAlphabet") {
      data.value = alphabet;
      data.customTableFieldId = this.state.lastNameCode;
    } else {
      data.value = this.state.searchValue;
      data.customTableFieldId = this.state.searchName;
    }
    let statusIndex = this.state.admissionStatuses.findIndex(
      (i) => i.id === parseInt(this.state.admissionStatusId)
    );
    statusIndex !== -1 &&
      this.setState({
        currentListName: this.state.admissionStatuses[statusIndex].name,
      });
    data.offset = 0;
    data.rowCountLimit = this.state.pageSize;
    this.setState(
      {
        displaySpinner: true,
        searchData: data,
        offset: 0,
        currentPage: 1,
        admissionSearch: true,
      },
      function () {
        this.searchAPIPagination(data);
      }
    );
  };

  searchAPIPagination = (data) => {
    axiosPost(URL.searchAdmissionListByStatus, data, (response) => {
      if (response.status === 200) {
        let totalRecords = response.data.totalRecordsCount;
        const admissionList = response.data.data;
        admissionList.forEach((el) => {
          el.status = false;
        });
        this.setState(
          { admissionList: response.data.data, totalRecords },
          () => {
            this.setState(
              {
                displaySpinner: false,
              },
              function () {
                this.setDisplayNumber();
              }
            );
          }
        );
      }
    });
  };

  editAdmissionStatus = (idx) => {
    let admissionList = [...this.state.admissionList];

    admissionList.forEach((el) => {
      el.status = false;
    });
    admissionList[idx].status = true;
    this.setState({
      admissionList: admissionList,
      selectedAdmissionStatus: admissionList[idx].admissionStatusId,
    });
  };

  closeAdmissionStatus = () => {
    let admissionList = [...this.state.admissionList];
    admissionList.forEach((el) => {
      el.status = false;
    });
    this.setState({
      admissionList: admissionList,
      selectedAdmissionStatus: "",
    });
  };

  copyToClipboard = (data) => {
    let dummy1 = document.createElement("input");
    document.body.appendChild(dummy1);
    dummy1.setAttribute("value", data);
    dummy1.select();
    document.execCommand("copy");
    document.body.removeChild(dummy1);
  };

  renderLinkCopyText = () => {
    this.setState({ linkCopied: true });
  };

  updateAdmissionList = (idx, el) => {
    if (this.state.selectedAdmissionStatus !== "") {
      let admissionList = [...this.state.admissionList];
      admissionList[idx].status = false;
      let data = {
        id: el.id,
        admissionStatusId: parseInt(this.state.selectedAdmissionStatus),
        yearLevelId: el.yearLevelId,
        academicYearId: el.academicYearId,
      };
      this.setState({ updateDisabled: true, showSpinner: true }, () => {
        axiosPost(
          URL.updateAdmissionStatus,
          data,
          (response) => {
            if (response.status === 200) {
              this.setState({ showSpinner: false });
              const updatedUser = response.data.data.updatedUser;
              updatedUser && updatedUser !== null
                ? swal({
                  title: `Updated Successfully`,
                  content: (
                    <div>
                      {response.data.data.updatedUser &&
                        response.data.data.updatedUser !== null &&
                        response.data.data.updatedUser !== "" ? (
                        <>
                          {response.data.data.updatedUser}
                          <br></br>

                          {!response.data.data.updatedUser.includes(
                            "enabled again"
                          ) && (
                              <button
                                className="tt-button tt-button-primary"
                                onClick={() => {
                                  this.copyToClipboard(
                                    `${response.data.data.updatedUser}`
                                  );
                                  this.renderLinkCopyText();
                                }}
                              >
                                {this.state.linkCopied ? "Copied" : "Copy"}
                              </button>
                            )}
                        </>
                      ) : (
                        <div>Status updated successfully</div>
                      )}
                    </div>
                  ),
                })
                : swal({
                  title: "Success",
                  text: "User updated",
                  allowOutsideClick: false,
                  closeOnClickOutside: false,
                });
              this.setState(
                {
                  admissionList,
                  displaySpinner: true,
                  // admissionStatusId: 4,
                  updateDisabled: false,
                },
                function () {
                  if (this.state.admissionSearch) {
                    let data = this.state.searchData;
                    data.offset = this.state.offset;
                    this.searchAPIPagination(data);
                  } else {
                    this.props.getAdmissionListPagination(
                      this.state.admissionStatusId,
                      this.state.offset,
                      this.state.pageSize
                    );
                  }
                }
              );
            }
          },
          (err) => {
            this.setState({ updateDisabled: false, showSpinner: false });
            swal({
              title: "Error",
              text: "Error updating User",
              allowOutsideClick: false,
              closeOnClickOutside: false,
            });
          }
        );
      });
    } else {
      swal({
        title: "Error",
        text: "Please choose admission status",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
    }
  };

  renderAlphabets = () => {
    let max = 91;
    let letters = [];
    for (let base = 65; base < max; base++) {
      letters.push(`${String.fromCharCode(base)}`);
    }
    // return letters;
    return letters.map((letter) => {
      return (
        <th
          className="alphabet-search-header"
          onClick={(e) => this.saveLetter(e, letter)}
        >
          {letter}
        </th>
      );
    });
  };

  saveLetter = (e, letter) => {
    this.handleAdmissionSearch(letter);
  };

  handlePageChange = (page) => {
    this.reRenderAdmissionList(page);

    this.setState({ currentPage: page }, function () {
      this.setDisplayNumber();
    });
  };

  reRenderAdmissionList = (page) => {
    let offset =
      page * parseInt(this.state.pageSize) - parseInt(this.state.pageSize);
    this.setState({ displaySpinner: true, offset: offset }, function () {
      if (this.state.admissionSearch) {
        let data = this.state.searchData;
        data.offset = this.state.offset;
        this.searchAPIPagination(data);
      } else {
        this.props.getAdmissionListPagination(
          this.state.admissionStatusId,
          offset,
          this.state.pageSize
        );
      }
    });
  };

  handlePreviousPage = () => {
    if (this.state.currentPage !== 1)
      this.reRenderAdmissionList(this.state.currentPage - 1);
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage - 1,
      }),
      function () {
        this.setDisplayNumber();
      }
    );
  };

  handleNextPage = () => {
    let nextPage = this.state.currentPage + 1;
    if (nextPage > Math.ceil(this.state.totalRecords / this.state.pageSize))
      return;
    else {
      this.reRenderAdmissionList(nextPage);
      this.setState({ currentPage: nextPage }, function () {
        this.setDisplayNumber();
      });
    }
  };

  setDisplayNumber = () => {
    var pageNumber = this.state.currentPage,
      pageSize = this.state.pageSize;
    var startIndex = (pageNumber - 1) * pageSize + 1;
    var offValue = pageSize - 1;
    var endIndex = parseInt(startIndex) + parseInt(offValue);
    if (endIndex > this.state.totalRecords) {
      endIndex = this.state.totalRecords;
    }
    this.setState({
      startIndex: startIndex,
      endIndex: endIndex,
    });
  };

  handleEditModal = (e, el) => {
    this.setState(
      {
        admissionData: el,
        peopleId: el.people.id,
        admissionStatusId: el.admissionStatusId,
        admissionId: el.id,
      },
      () => {
        this.setState({
          editModal: !this.state.editModal,
        });
      }
    );
  };

  toggleEditModal = () => {
    this.setState({ editModal: !this.state.editModal, admissionData: "" });
  };

  devanagariDigits = {
    '0': '०',
    '1': '१',
    '2': '२',
    '3': '३',
    '4': '४',
    '5': '५',
    '6': '६',
    '7': '७',
    '8': '८',
    '9': '९',
  };

  convertNepaliDate = (date) => {
    let formatDate = moment(date).format("YYYY-MM-DD");
    let convertDate = adToBs(formatDate);
    let nepaliDate = convertDate.replace(/[0-9]/g, (match) => this.devanagariDigits[match]);
    return nepaliDate;
  }

  render() {
    const admissionList = this.state.admissionList;
    return (
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header ">
          Students
          {checkWidgetAdminOrNot(
            this.props.fromEducationCommunity
              ? "Academic Administration"
              : "Admission"
          ) === true ? (
            <button
              className="tt-button tt-button-primary float-right permissionBtnCSS"
              onClick={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
            >
              Permissions
            </button>
          ) : null}
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.linkCopied}
          autoHideDuration={1000}
          onClose={() => this.setState({ linkCopied: false })}
          message="Copied to Clipboard"
        />
        <div className="tt-admissionListBox">
          <div>
            <table className="table table-bordered table-striped mb-0">
              <thead className="tt-group-header-dark text-center">
                <tr>{this.renderAlphabets()}</tr>
              </thead>
            </table>
          </div>
          <div className="student-search container-fluid">
            <strong className="search-header">
              Search on {this.state.totalRecords} Students
            </strong>
            <div className="row search-form mt-2">
              <div className="col-md-2" style={{ paddingRight: 0 }}>
                <select
                  id="name-search"
                  name="searchName"
                  onChange={this.handleSelectChange}
                  value={this.state.searchName}
                  style={{ width: "95.5%" }}
                >
                  <option value="" disabled selected>
                    Choose Field Name
                  </option>
                  {this.state.nameFields &&
                    this.state.nameFields.map((field) => (
                      <option value={field.id}>{field.fieldName}</option>
                    ))}
                </select>
                <span style={{ position: "relative", left: "10px" }}>:</span>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  name="searchValue"
                  id="name-search"
                  value={this.state.searchValue}
                  onChange={this.handleSelectChange}
                />
              </div>
              <div className="col-md-4">
                <label className="mr-2">Status :</label>
                <select
                  id="status-search"
                  value={this.state.admissionStatusId}
                  name="admissionStatusId"
                  onChange={this.handleSelectChange}
                >
                  {this.state.admissionStatuses &&
                    this.state.admissionStatuses.map((status) => (
                      <option value={status.id}>{status.name}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className="row search-form">
              {/* <div className="col-md-2">
              <select
                id="status-search"
                name="countryId"
                value={this.state.countryId}
                onChange={this.handleSelectChange}
              >
                <option value="" disabled selected>
                  Choose Nationality
                </option>
                <option value="">None</option>
                {this.state.countries &&
                  this.state.countries.map((country) => (
                    <option value={country.id}>
                      {country.nationality ? country.nationality : country.name}
                    </option>
                  ))}
              </select>
            </div> */}
              <div className="col-md-3">
                <select
                  id="status-search"
                  name="gender"
                  value={this.state.gender}
                  onChange={this.handleSelectChange}
                  style={{ width: "100%" }}
                >
                  <option value="" disabled selected>
                    Choose gender
                  </option>
                  {/* {this.state.genders.map((gender) => (
                  <option value={gender.id}>{gender.gender}</option>
                ))} */}
                  <option value="">All Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
              </div>
              {/* <div className="col-md-3">
                <select
                  id="status-search"
                  name="yearLevelSearchId"
                  value={this.state.yearLevelSearchId}
                  onChange={this.handleSelectChange}
                  style={{ width: "100%" }}
                >
                  <option value="" disabled selected>
                    Choose {localStorage.getItem("yearLevel")}
                  </option>
                  <option value="">None</option>
                  {this.state.yearLevels &&
                    this.state.yearLevels.map((yearLevel) => (
                      <option value={yearLevel.id}>{yearLevel.name}</option>
                    ))}
                </select>
              </div> */}
              <div className="col-md-5">
                <label className="mr-2">Show :</label>
                <select
                  id="status-search"
                  className="mr-4"
                  name="pageSize"
                  onChange={this.handleSelectChange}
                  value={this.state.pageSize}
                  style={{ marginLeft: "4px" }}
                >
                  <option>10</option>
                  <option>20</option>
                  <option>50</option>
                  <option>100</option>
                  <option>250</option>
                  <option>500</option>
                </select>
                <button
                  className="tt-button tt-button-primary"
                  onClick={() => this.handleAdmissionSearch("noAlphabet")}
                >
                  Go
                </button>
              </div>
            </div>
          </div>
          <div className="container-fluid index-info1">
            <div className="row">
              <div className="col-md-12 p-2">
                <strong className="ml-3">
                  {admissionList.length === 0
                    ? `0 Students found`
                    : `Displaying ${this.state.startIndex} to ${this.state.endIndex} of ${this.state.totalRecords} [
              ${this.state.currentListName}]`}
                </strong>
              </div>
            </div>
          </div>
          <table className="table text-center table-bordered table-striped tt-listTable">
            <thead className="tt-group-header">
              <tr>
                <th width="20%">Name</th>
                <th>Details</th>
                <th width="15%">Admission Status</th>
                {this.state.permissionEditAdmission ||
                  this.state.permissionViewDetailAdmission ? (
                  <th width="11%">Options</th>
                ) : null}
              </tr>
            </thead>
            {this.state.displaySpinner ? (
              <tbody>
                <tr>
                  <td colSpan={4} className="mt-3 mb-3">
                    <Spinner color="primary" />
                    <br />
                    Loading Data ...
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {admissionList ? (
                  admissionList.length > 0 ? (
                    admissionList.map((el, idx) => {
                      return (
                        <tr key={idx}>
                          <td style={{ width: "20%" }} className="text-left">
                            <div className="tt-pp">
                              <div className="tt-ppHolder">
                                <img
                                  src={
                                    el.photo
                                      ? URL.imageSelectURL + el.people.photo
                                      : userImage
                                  }
                                  onError={(e) => handleError(e)}
                                  alt=""
                                ></img>
                              </div>
                              <div className="tt-ppName">
                                {el.isUser ? (
                                  <Link
                                    to={{
                                      pathname: `/profile/${el.people.username}`,
                                    }}
                                  >
                                    <strong className="user-details">
                                      {this.nameFormatter(el.people.name)}
                                    </strong>
                                  </Link>
                                ) : (
                                  <strong className="user-details">
                                    {this.nameFormatter(el.people.name)}
                                  </strong>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="text-left" style={{ width: "40%" }}>
                            <div className="user-details">
                              <strong className="mr-1">Birthday:</strong>
                              <span>
                                {el.people.dateOfBirth &&
                                  moment(el.people.dateOfBirth).format(
                                    "Do MMMM, YYYY"
                                  )}
                              </span>
                              <span className="ml-2">
                                ( {this.convertNepaliDate(el.people.dateOfBirth)} )
                                {/* {adbs.ad2bs(moment(el.people.dateOfBirth).format("YYYY/MM/DD"))} */}
                              </span>
                            </div>
                            <div className="user-details">
                              <strong className="mr-1">Age:</strong>
                              {el.people.dateOfBirth &&
                                moment().diff(
                                  el.people.dateOfBirth.substring(0, 10),
                                  "years"
                                )}{" "}
                              {el.people.dateOfBirth && "years old"}
                            </div>
                            <div className="user-details">
                              <strong className="mr-1">Gender:</strong>
                              {el.people.gender}
                            </div>
                            {/* <div className="user-details">
                            <strong className="mr-1">Nationality:</strong>
                          </div>
                          <div className="user-details">
                            <strong className="mr-1">Telephone:</strong>
                          </div> */}
                          </td>
                          <td>
                            {el.status ? (
                              <select
                                className="form-control"
                                name="selectedAdmissionStatus"
                                value={this.state.selectedAdmissionStatus}
                                onChange={this.handleSelectChange}
                              >
                                <option value="" disabled>
                                  Choose Admission Status
                                </option>
                                {this.state.admissionStatuses.map(
                                  (status, idx) => {
                                    return (
                                      <option key={idx} value={status.id}>
                                        {status.name}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            ) : (
                              el.admissionStatusName
                            )}
                          </td>
                          {this.state.permissionEditAdmission ||
                            this.state.permissionViewDetailAdmission ? (
                            <td style={{ width: "20%" }}>
                              {el.status ? (
                                <>
                                  <button
                                    className="tt-button tt-button-primary mr-2"
                                    onClick={(e) =>
                                      this.updateAdmissionList(idx, el)
                                    }
                                    disabled={this.state.updateDisabled}
                                  >
                                    Update
                                  </button>
                                  <button
                                    className="tt-button tt-button-primary"
                                    onClick={this.closeAdmissionStatus}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  {this.state.permissionEditAdmission ? (
                                    <button
                                      className="tt-button tt-button-primary tt-button-fullWidth"
                                      onClick={(e) =>
                                        this.handleEditModal(e, el)
                                      }
                                    >
                                      Edit
                                    </button>
                                  ) : null}
                                  {this.state.permissionViewDetailAdmission ? (
                                    <button
                                      className="tt-button tt-button-primary tt-button-fullWidth"
                                      onClick={(e) =>
                                        this.toggleAdmissionModalWithData(e, el)
                                      }
                                    >
                                      Details
                                    </button>
                                  ) : null}
                                  {this.state.permissionEditAdmission ? (
                                    <button
                                      className="tt-button tt-button-primary tt-button-fullWidth"
                                      onClick={(e) =>
                                        this.editAdmissionStatus(idx)
                                      }
                                    >
                                      Change Status
                                    </button>
                                  ) : null}
                                </>
                              )}
                            </td>
                          ) : null}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}>No Students to display</td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan={4}>No Students to display</td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              itemsCount={this.state.totalRecords}
              pageSize={this.state.pageSize}
              currentPage={this.state.currentPage}
              onPageChange={this.handlePageChange}
              previousPage={this.handlePreviousPage}
              nextPage={this.handleNextPage}
            />
          </div>
          <ModalWindow
            fullWidth={true}
            modal={this.state.admissionModal}
            toggleModal={this.toggleAdmissionModal}
            modalClass="tt-peopleList"
            modalBody={
              <AdmissionModalBody
                admissionListBySelect={this.state.admissionListBySelect}
                admissionListByNonCluster={this.state.admissionListByNonCluster}
                studentContactBySelect={this.state.studentContactBySelect}
                admissionModalData={this.state.admissionModalData}
                peopleAddress={this.state.peopleAddress}
              />
            }
            modalHeader={this.state.name}
          ></ModalWindow>
          {this.props.fromEducationCommunity ? (
            <ModalWindow
              modal={this.state.permissionModal}
              size="lg"
              id="tt-permissionModal"
              toggleModal={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
              modalHeader={"Assign permission to user"}
              modalBody={
                <GranularPermissionModal
                  widgetName="Academic Administration"
                  moduleName="Education Community"
                  header="View Students"
                  activityName="select-students"
                />
              }
            ></ModalWindow>
          ) : (
            <ModalWindow
              modal={this.state.permissionModal}
              size="lg"
              id="tt-permissionModal"
              toggleModal={() => {
                this.setState({
                  permissionModal: !this.state.permissionModal,
                });
              }}
              modalHeader={"Assign permission to user"}
              modalBody={
                <GranularPermissionModal
                  widgetName="Admission"
                  moduleName="Admission"
                  header="List Students"
                  activityName="select-admission-list"
                />
              }
            ></ModalWindow>
          )}
          {this.state.peopleId ? (
            <ModalWindow
              backdrop="static"
              keyboard={false}
              modal={this.state.editModal}
              toggleModal={this.toggleEditModal}
              size="xl"
              modalHeader="Edit Admission"
              modalBody={
                <AdmissionForm
                  editMode={true}
                  admissionData={this.state.admissionData}
                  peopleId={this.state.peopleId}
                  admissionStatusId={this.state.admissionStatusId}
                  admissionId={this.state.admissionId}
                  handleAdmissionSearch={this.handleAdmissionSearch}
                  toggleEditModal={this.toggleEditModal}
                ></AdmissionForm>
              }
            ></ModalWindow>
          ) : null}
          <div>
            <table className="table table-bordered table-striped mb-0">
              <thead className="tt-group-header-dark text-center">
                <tr>{this.renderAlphabets()}</tr>
              </thead>
            </table>
          </div>
        </div>
        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Updating User Student ...
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  admissionList: state.admission.admissionList,
  totalRecords: state.admission.totalRecords,
});
const mapActionToProps = {
  getAdmissionList,
  getAdmissionListPagination,
};

export default connect(mapStateToProps, mapActionToProps)(AdmissionList);
