import React, { useEffect, useState } from 'react'
import { URL } from '../../utils/Constants';
import { showErroMessage } from '../../utils/Utils';
import swal from "sweetalert";
import { axiosPost } from '../../utils/AxiosApi';
import { MESSAGEHEADER } from '../../utils/DisplayMessage';
import ModalWindow from "../../UI/ModalWindow";
import moment from 'moment';
import JitsiMeet from './jitsiMeet';
import VideoConferencing from './VideoConferencing';
import { Spinner } from "reactstrap";

function VideoConferenceList() {

    const [conferData, setConferData] = useState(null);
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [roomId, setRoomId] = useState(null);
    const [userName, setUserName] = useState(null);
    const [password, setPassword] = useState(null);
    const [isHost, setIsHost] = useState(false);
    const [videoConferenceId, setVideoConferenceId] = useState(null);
    const [jitsiModel, setJitsiModel] = useState(false);
    const [jitsiUpdateModel, setJitsiUpdateModel] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);


    const getVideoConferenceList = () => {
        setFetchingData(true);
        const people_id = localStorage.getItem('peopleId');
        setLoggedInUser(parseInt(people_id));
        let data = {
            profilePeopleId: people_id
        };
        axiosPost(
            URL.getVideoConference,
            data,
            (response) => {
                if (response.status === 200) {
                    setConferData(response?.data?.data);
                    setFetchingData(false);
                } else {
                    showErroMessage(response);
                    setFetchingData(false);
                }
            },
            (error) => {
                swal({
                    title: MESSAGEHEADER.error,
                    text: `${error ? (error.response ? error.response.data.error : error) : error}`,
                });
                setFetchingData(false);
            }
        );
    }

    useEffect(() => {
        getVideoConferenceList();
    }, []);

    const handleJoin = (data) => {
        let roomId = data?.jitsiRoomId;
        let userName = data?.peopleName;
        let password = data?.password;
        let isHost = loggedInUser === data?.createdByPeopleId;
        let videoConId = data?.id
        setRoomId(roomId);
        setUserName(userName);
        setPassword(password);
        setIsHost(isHost);
        setVideoConferenceId(videoConId);
        toggleJitsiModal();
    }

    const toggleJitsiModal = () => {
        setJitsiModel(!jitsiModel)
    };

    const handleUpdate = (data) => {
        toggleJitsiUpdateModal();
        setVideoConferenceId(data?.id);
    }

    const toggleJitsiUpdateModal = () => {
        setJitsiUpdateModel(!jitsiUpdateModel)
    };

    return (
        <>
            <div className="tt-widgetContent-tab-holder"
                style={{ height: "79.5vh" }}
            >
                <div className="tt-group-header">
                    List Video Conference

                </div>
                <div className="tt-newListBox container-fluid">
                    <div style={{ height: "60vh" }}>
                        {fetchingData ?
                            (
                                <div className='text-center mt-2'>
                                    <>
                                        <Spinner color="primary"></Spinner>
                                        <br></br>Loading Data...
                                    </>
                                </div>
                            )
                            :
                            (
                                <>
                                    {conferData && conferData?.length > 0 ?
                                        (
                                            <table className='table table-bordered'>
                                                <thead className='tt-group-header'>
                                                    <tr>
                                                        <th>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                S.N.
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                Session Name
                                                            </div>
                                                        </th>
                                                        {/* 
                                                        <th>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                Session Start Time
                                                            </div>
                                                        </th> */}
                                                        {/* <th>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                            Session End Time
                                                            </div>
                                                            </th> */}
                                                        <th>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                Joining Time
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                Subject
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                Description
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                Password
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                Action
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {conferData.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-around'>
                                                                    {index + 1}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-around'>
                                                                    {item?.sessionName}
                                                                </div>
                                                            </td>
                                                            {/* <td>
                                                                <div className='d-flex align-items-center justify-content-around'>
                                                                    {moment(item?.startDateTime).format("YYYY-MM-DD , h:mm A")}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-around'>
                                                                    {moment(item?.endDateTime).format("YYYY-MM-DD , h:mm A")}
                                                                </div>
                                                            </td> */}
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-around'>
                                                                    <p>
                                                                        {moment(item?.startDateTime).format("YYYY-MM-DD , h:mm A")}
                                                                        {" "}
                                                                        to
                                                                        {" "}
                                                                        {moment(item?.endDateTime).format("YYYY-MM-DD , h:mm A")}
                                                                    </p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-around'>
                                                                    {item?.subject?.name ? item?.subject?.name : "-"}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-around'>
                                                                    {item?.description}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-around'>
                                                                    {item?.password}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-around'>
                                                                    {/* currently not needed this update btn/logic */}
                                                                    {/* {loggedInUser && (loggedInUser === item.createdByPeopleId) &&
                                                                        (
                                                                            <button
                                                                                className="mr-2 tt-button tt-button-primary float-right permissionBtnCSS"
                                                                                onClick={() => handleUpdate(item)}
                                                                            >Update
                                                                            </button>
                                                                        )
                                                                    } */}
                                                                    {
                                                                        moment(item.endDateTime).isBefore(moment()) ? (
                                                                            moment(item.endDateTime).diff(moment(), 'days') > 1 ? (
                                                                                <button
                                                                                    className="mr-2 tt-button tt-button-disabled float-right permissionBtnCSS"
                                                                                    disabled
                                                                                >
                                                                                    Meeting Link Expired
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    className="mr-2 tt-button tt-button-disabled float-right permissionBtnCSS"
                                                                                    disabled
                                                                                >
                                                                                    Join Time Exceeded
                                                                                </button>
                                                                            )
                                                                        ) : (
                                                                            <button
                                                                                className="mr-2 tt-button tt-button-primary float-right permissionBtnCSS"
                                                                                onClick={() => handleJoin(item)}
                                                                            >
                                                                                Join
                                                                            </button>
                                                                        )
                                                                    }
                                                                    {/* <button
                                                                        className="mr-2 tt-button tt-button-primary float-right permissionBtnCSS"
                                                                        onClick={() => handleJoin(item)}>Join
                                                                    </button> */}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )
                                        :
                                        (
                                            <div className="w-100 mt-2 d-flex align-items-center justify-content-start">
                                                There are no video conference added at the moment.
                                            </div>
                                        )}
                                </>
                            )}

                    </div>
                </div>
                {roomId && userName && password && (
                    <>
                        <ModalWindow
                            modal={jitsiModel}
                            toggleModal={toggleJitsiModal}
                            modalHeader="Jitsi Video Conferencing for Imagine Inquiry Sys"
                            size="xl"
                            modalBody={
                                <JitsiMeet
                                    isHost={isHost}
                                    getMeetingId={roomId}
                                    getDispNme={userName}
                                    toggleModal={toggleJitsiModal}
                                    getPassword={password}
                                    videoConferenceId={videoConferenceId}
                                ></JitsiMeet>
                            }
                        ></ModalWindow>
                    </>

                )}
                {/* for update data  currently not needed*/}
                {/* <ModalWindow
                    modal={jitsiUpdateModel}
                    toggleModal={setJitsiUpdateModel}
                    modalHeader="Update Video Conferencing"
                    size="xl"
                    modalBody={
                        <VideoConferencing
                            videoConferenceId={videoConferenceId}
                            conferData={conferData}
                            update={true}
                        />
                    }
                ></ModalWindow> */}
            </div>
        </>
    )
}

export default VideoConferenceList