import React, { useEffect, useState } from 'react'
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { Spinner } from "reactstrap";
import GeneralAssesmentListRevamp from './GeneralAssesmentListRevamp';
import { URL } from '../../../../utils/Constants';
import { axiosPost } from '../../../../utils/AxiosApi';
import { showErroMessage } from '../../../../utils/Utils';

// import CreatableSelect from "react-select/creatable";
const animatedComponents = makeAnimated();

function GeneralAssessmentCollapsibleRevamp(props) {

  const [loading, setLoading] = useState(false);
  const [classValue, setClassValue] = useState(null);
  const [yearLevel, setYearLevel] = useState(null);
  const [classroomsList, setClassroomsList] = useState(null);
  const [classRooms, setClassRooms] = useState(null);
  const [assessmentSubjectList, setAssessmentSubjectList] = useState(null);
  const [assessmentSubjectValue, setAssessmentSubjectValue] = useState(null);
  const [allData, setAllData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [subjectFinalize, setSubjectFinalize] = useState(false);
  const [finalizeClickable, setFinalizeClickable] = useState(false);
  const [assessmentList, setAssessmentList] = useState([]);

  const getClassrooms = (yearLevelId) => {
    axiosPost(
      URL.selectClassroom,
      {
        yearLevelId: yearLevelId,
      },
      (response) => {
        if (response.status === 200) {
          let classrooms = response.data.data;
          setClassroomsList(
            classrooms.map((item) => ({
              ...item,
              value: item.name,
              label: item.name,
            }))
          );
        }
      }
    );
  };

  const getAssessmentWithScaleOrComment = (idObject) => {
    axiosPost(URL.generalAssessmentSelectByYearAndClass, idObject, (response) => {
      if (response.status === 200) {
        let assessments = response.data.data;
        setAssessmentList(assessments);
        setAssessmentSubjectList(
          assessments.map((item) => ({
            ...item,
            value: item.assessment?.assessmentName,
            label: item.assessment?.assessmentName,
          }))
        );
      }
    });
  };


  useEffect(() => {
    let param = {
      assessmentModuleCode: props?.code,
    };
    axiosPost(
      URL.getYearLevelByAssessmentSubjectConfig,
      param,
      (response) => {
        if (response.status === 200) {
          const yearLevels = response.data.data;
          setYearLevel(
            yearLevels.map((item) => ({
              ...item,
              value: item.name,
              label: item.name,
            }))
          );
        }
      }
    );
  }, [])

  const handleClassChange = (value) => {
    setClassValue(value);
    if (value?.id) {
      getClassrooms(value?.id);
    }
  };

  const handleClassRoomListChange = (value) => {
    setClassRooms(value);
    const payload = {
      yearLevelId: value?.yearLevelId,
      educationLevelId: value?.educationLevelId,
      classroomId: value?.id,
    };
    if (value?.id) {
      getAssessmentWithScaleOrComment(payload);
    }
  };

  const handleAssessmentListChange = (value) => {
    setAssessmentSubjectValue(value);
    setAllData([]);
    setStudentData([]);
    const payloadStudentsList = {
      // educationLevelDivisionId: value.educationLevelDivisionId,
      // educationLevelId: value.educationLevelId,
      classroomId: classRooms?.id,
      yearLevelId: classRooms?.yearLevelId,
    };
    const payloadIsFinalized = {
      assessmentId: value?.assessmentId,
      classroomId: classRooms?.id,
      yearLevelId: classRooms?.yearLevelId,
    }
    const payloadIsFinalizeClickable = {
      assessmentId: value?.assessmentId,
      classroomId: classRooms?.id,
      yearLevelId: classRooms?.yearLevelId,
    }
    if (value?.assessmentId) {
      props.getStudentsList(payloadStudentsList);
      getIsFinalized(payloadIsFinalized);
      getIsFinalizeClickable(payloadIsFinalizeClickable);
    }
  };

  //  const getStudentsList = (data) => {
  //     setLoading(true);
  //     axiosPost(
  //       URL.getAssignedStudents,
  //       data,
  //       (response) => {
  //         if (response.status === 200) {
  //           let students = response.data.data;
  //           setStudentData(students);
  //           setLoading(false);
  //         }
  //       },
  //       (err) => {
  //         setLoading(false);
  //       }
  //     );
  //   };

  const getIsFinalized = (data) => {
    setLoading(true);
    axiosPost(
      URL.selectGeneralAssessmentIsFinalized,
      data,
      (response) => {
        if (response.status === 200) {
          if (response.data.data.length > 0) {
            if (response.data.data[0].isFinalized) {
              setSubjectFinalize(true);
            } else {
              setSubjectFinalize(false);
            }
          } else {
            setSubjectFinalize(false);
          }
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getIsFinalizeClickable = (data) => {
    setLoading(true);
    axiosPost(
      URL.selectFinalizeClickable,
      data,
      (response) => {
        if (response.status === 200) {
          const data = response.data.data.finalizeClickable;
          setFinalizeClickable(data);
        } else {
          showErroMessage(response);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-between" style={{ gap: "1rem" }}>
          <Select
            className="w-100"
            closeMenuOnSelect={true}
            components={animatedComponents}
            value={classValue}
            options={yearLevel}
            name="class"
            onChange={handleClassChange}
            placeholder="Select Class"
            isClearable
          />
          <Select
            isDisabled={!!!classValue}
            className="w-100"
            closeMenuOnSelect={true}
            components={animatedComponents}
            value={classRooms}
            options={classroomsList}
            name="classRooms"
            onChange={handleClassRoomListChange}
            placeholder="Select Section"
            isClearable
          />
          <Select
            isDisabled={!!!classRooms}
            className="w-100"
            closeMenuOnSelect={true}
            components={animatedComponents}
            value={assessmentSubjectValue}
            options={assessmentSubjectList}
            name="assessmentList"
            onChange={handleAssessmentListChange}
            placeholder="Select Assessment"
            isClearable
          />
        </div>
        <div className="mt-4">
          <GeneralAssesmentListRevamp
            loading={loading}
            generalAssessmentList={props.generalAssessmentList}
            classValue={classValue}
            classRooms={classRooms}
            assessmentSubjectValue={assessmentSubjectValue}
            code={props.code}
            studentData={props?.studentList}

            handleToggleClassRoom={props.handleToggleClassRoom}
            assessmentList={assessmentList}
            handleSectionToggle={props.handleSectionToggle}
            studentList={props.studentList}
            studentListSpinner={props.studentListSpinner}
            handleStudentClick={props.handleStudentClick}
            assessmentModuleCode={props.code}
            widgetName={props.widgetName}
            name={props?.name}
            subjectFinalize={subjectFinalize}
            finalizeClickables={finalizeClickable}
            setSubjectFinalize={setSubjectFinalize}
            setFinalizeClickable={setFinalizeClickable}
          />
        </div>
      </div>
    </>
  )
}

export default GeneralAssessmentCollapsibleRevamp