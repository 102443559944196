import { faFile, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import Spinner from "reactstrap/lib/Spinner";
import swal from "sweetalert";
import { axiosPost } from "../../utils/AxiosApi";
import { draggable, URL } from "../../utils/Constants";
import { displayErrorAlert } from "../../utils/Utils";
import isURL from "validator/lib/isURL";
import JoditEditor from "jodit-react";

const config = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  fullSize: false,
  width: "100%",
  removeButtons: [
    "source",
    "hr",
    "|",
    "about",
    "fullsize",
    "eraser",
    "undo",
    "redo",
    "table",
    "brush",
    "image",
    "file",
    "print",
    "link",
    "preview",
    "copyformat",
  ],

  limitChars: 60000,
  showTooltip: true,
  toolbarButtonSize: "small",
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  height: "100px",
  uploader: {
    insertImageAsBase64URI: true,
    // url: "www.google.com"
  },
};

class CourseSyllabusResource extends Component {
  state = {
    link: "",
    contents: [],
    selectedLinks: [], //links
    showSpinner: false,
    error: false,
    updateFiles: [],
    description: "",
    title: "",
  };

  handleTextEditor = (value) => {
    this.setState({ description: value });
  };

  handleChange = (e) => {
    let name = e.target.name,
      value = e.target.value;
    this.setState({ [name]: value, error: false });
  };

  addLinks = () => {
    let selectedLinks = [...this.state.selectedLinks];
    if (isURL(this.state.link)) {
      selectedLinks.push(this.state.link);
      this.setState({ selectedLinks, link: "" });
    } else {
      this.setState({ error: true });
      swal({
        title: "Error",
        text: "Invalid Link",
        allowOutsideClick: false,
        closeOnClickOutside: false,
      });
      draggable();
      return false;
    }
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter" && this.state.link !== "") {
      this.addLinks();
    }
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let data = e.target.files;
    let contents = [...this.state.contents];
    Object.values(data).forEach((datum) => {
      var extension = datum.name.split(".").pop();
      if (datum.type.includes("image")) {
        if (
          e.target.accept.includes(datum.type)
        ) {
          contents.push(datum);
        }
      } else {
        if (extension === "pdf") {
          contents.push(datum);
        } else {
          swal("Error", "File type not supported");
        }
      }
    });
    this.setState({ contents });
  };

  deleteFileContent = (e, idx) => {
    let contents = [...this.state.contents];
    contents.splice(idx, 1);
    this.setState({ contents });
  };

  deleteAssignmentResources = (e, idx) => {
    let updateFiles = [...this.state.updateFiles];
    updateFiles.splice(idx, 1);
    this.setState({ updateFiles });
  };

  deleteLink = (idx) => {
    let selectedLinks = [...this.state.selectedLinks];
    selectedLinks.splice(idx, 1);
    this.setState({ selectedLinks });
  };

  handleUpdate = () => {
    if (this.props.assignment) {
      this.props.updateResources(
        this.state.contents,
        this.state.selectedLinks,
        this.state.updateFiles
      );
    }
  };

  handleSubmit = () => {
    // if (
    //   this.state.selectedLinks.length === 0 &&
    //   this.state.contents.length === 0 &&
    //   (!this.props.assignment ||
    //     (this.props.assignment && this.props.isSubmission))
    // ) {
    //   swal({
    //     title: "Error",
    //     text: "There are no contents to be added",
    //     closeOnClickOutside: false,
    //     allowOutsideClick: false,
    //   });
    //   draggable();
    //   return false;
    // }
    // if (this.props.assignment) {
    //   this.props.saveResources(this.state.contents, this.state.selectedLinks);
    // } else if (this.props.submission) {
    //   this.props.submitAssignment(
    //     this.state.contents,
    //     this.state.selectedLinks
    //   );
    // } else {
    //   let formData = new FormData();
    //   for (const key of Object.keys(this.state.contents)) {
    //     formData.append("contents", this.state.contents[key]);
    //   }
    //   formData.append(
    //     "jsonData",
    //     JSON.stringify({
    //       courseClassId: this.props.courceClassId,
    //       links: this.state.selectedLinks,
    //     })
    //   );
    //   this.setState({ showSpinner: true }, function () {
    //     axiosPost(
    //       URL.insertCourseClassResources,
    //       formData,
    //       (response) => {
    //         if (response.status === 200) {
    //           swal({
    //             title: "Success",
    //             text: "Resources successfully added",
    //             closeOnClickOutside: false,
    //             allowOutsideClick: false,
    //           });
    //           draggable();
    //           this.props.toggleAddResourceModal();
    //           this.props.getCourseClasses();
    //           this.setState({ showSpinner: false });
    //         }
    //       },
    //       (error) => {
    //         this.setState({ showSpinner: false });
    //         displayErrorAlert(error);
    //       }
    //     );
    //   });
    // }
    var editorContent = [];
    if (this.state.title !== "" && this.state.description !== "") {
      editorContent = [
        {
          title: this.state.title,
          description: this.state.description,
        },
      ];
    }

    let formData = new FormData();
    for (const key of Object.keys(this.state.contents)) {
      formData.append("content", this.state.contents[key]);
    }
    if (this.state.contents.length <= 0) {
      formData.append("content", []);
    }

    formData.append(
      "jsonData",
      JSON.stringify({
        coursePlanId: this.props.coursePlanId,
        coursePlanSyllabusId: this.props.coursePlanSyllabusId,
        links: this.state.selectedLinks,
        coursePlanResourcesContents: editorContent,
      })
    );
    this.setState({ showSpinner: true }, function () {
      axiosPost(
        URL.insertCoursePlanResources,
        formData,
        (response) => {
          if (response.status === 200) {
            swal({
              title: "Success",
              text: "Resources successfully added",
              closeOnClickOutside: false,
              allowOutsideClick: false,
            });
            draggable();
            this.props.toggleAddResourceModal();
            this.setState({ showSpinner: false });
          }
        },
        (error) => {
          this.setState({ showSpinner: false });
          displayErrorAlert(error);
        }
      );
    });
  };

  render() {
    return (
      <div className="tt-widgetContent-tab-holder">
        <div className="row">
          <div className="col">
            <input
              type="text"
              className={
                this.state.error
                  ? " form-control form-control-warn"
                  : "form-control"
              }
              name="link"
              value={this.state.link}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
            ></input>
          </div>
          <div
            className="col-md-auto text-right mt-1"
            style={{ borderRight: "1px solid #ccc" }}
          >
            <button
              className="tt-button tt-button-primary"
              onClick={this.addLinks}
              disabled={this.state.link === ""}
            >
              Add Links
            </button>
          </div>
          <div className="col-md-auto text-right mt-1">
            <input
              id="myInput"
              type="file"
              ref={(ref) => (this.upload = ref)}
              onChange={this.handleImageChange}
              style={{ display: "none" }}
              multiple
              accept="image/png, image/jpeg, .pdf"
            />
            <button
              className="tt-button tt-button-primary"
              onClick={() => {
                this.upload.click();
              }}
            >
              Choose Files
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div
              className="tt-courseFileSelectHolder"
              style={{ height: "auto" }}
            >
              <strong>Selected Files:</strong>
              <div className="tt-courseFilesBox">
                <div className="tt-courseFiles">
                  {this.props.updateAssignment ? (
                    <>
                      {this.state.updateFiles.length > 0
                        ? this.state.updateFiles.map((el, idx) => {
                            return (
                              <div key={idx}>
                                {el.type === "IMG" ? (
                                  <div
                                    className="tt-courseFileHolder"
                                    style={{ padding: 0 }}
                                  >
                                    <img
                                      src={el.src}
                                      alt="assignemtResourceImage"
                                    ></img>
                                    <FontAwesomeIcon
                                      icon={faTimesCircle}
                                      className="tt-courseFileDelete"
                                      onClick={() => {
                                        this.deleteAssignmentResources(
                                          el.id,
                                          idx
                                        );
                                      }}
                                    ></FontAwesomeIcon>
                                  </div>
                                ) : (
                                  <div className="tt-courseFileHolder">
                                    <FontAwesomeIcon
                                      icon={faFile}
                                      className="tt-courseFileIcon"
                                    ></FontAwesomeIcon>
                                    <div>{el.fileName}</div>
                                    <FontAwesomeIcon
                                      icon={faTimesCircle}
                                      className="tt-courseFileDelete"
                                      onClick={() => {
                                        this.deleteAssignmentResources(
                                          el.id,
                                          idx
                                        );
                                      }}
                                    ></FontAwesomeIcon>
                                  </div>
                                )}
                              </div>
                            );
                          })
                        : ""}
                    </>
                  ) : (
                    ""
                  )}

                  {this.state.contents.length > 0 ? (
                    this.state.contents.map((file, idx) => {
                      return (
                        <div key={idx}>
                          {file.type.includes("image") ? (
                            <div
                              className="tt-courseFileHolder"
                              style={{ padding: 0 }}
                            >
                              <img
                                src={window.URL.createObjectURL(file)}
                                alt="courseFile"
                              ></img>
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                className="tt-courseFileDelete"
                                onClick={(e) => {
                                  this.deleteFileContent(e, idx);
                                }}
                              ></FontAwesomeIcon>
                            </div>
                          ) : (
                            <div className="tt-courseFileHolder">
                              <FontAwesomeIcon
                                icon={faFile}
                                className="tt-courseFileIcon"
                              ></FontAwesomeIcon>
                              <div>{file.name}</div>
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                className="tt-courseFileDelete"
                                onClick={(e) => {
                                  this.deleteFileContent(e, idx);
                                }}
                              ></FontAwesomeIcon>
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center">
                      {this.props.updateAssignment ? (
                        <>
                          {this.state.updateFiles.length > 0
                            ? ""
                            : "No Files uploaded"}
                        </>
                      ) : (
                        "No Files uploaded"
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-2">
                <strong>Selected Links:</strong>
                <div className="tt-courseLinks">
                  <div className="row">
                    <div className="col">
                      <table>
                        <tbody>
                          {this.state.selectedLinks.length > 0 ? (
                            this.state.selectedLinks.map((lnk, idx) => {
                              return (
                                <tr key={idx} style={{ verticalAlign: "top" }}>
                                  <td width="100%">{lnk}</td>
                                  <td>
                                    <FontAwesomeIcon
                                      icon={faTimesCircle}
                                      onClick={() => this.deleteLink(idx)}
                                      style={{ cursor: "pointer" }}
                                    ></FontAwesomeIcon>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={2} className="text-center">
                                No links added
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <strong>Content:</strong>
                <div className="tt-courseFilesBox tt-editor">
                  <div>
                    <strong>Title:</strong>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={this.state.title}
                      onChange={this.handleChange}
                    ></input>
                  </div>
                  <div className="mt-2">
                    <strong>Description:</strong>
                    <JoditEditor
                      value={this.state.description}
                      onChange={(e) => this.handleTextEditor(e)}
                      config={config}
                      className="rounded-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row button-bg-color mt-2">
          <div className="col text-right">
            {this.props.updateAssignment ? (
              <>
                <button
                  className="tt-button tt-button-primary mr-3"
                  onClick={this.handleUpdate}
                >
                  Update
                </button>
                <button
                  className="tt-button tt-button-primary mr-3"
                  onClick={this.props.toggleSyllabusResourcesModal}
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <button
                  className="tt-button tt-button-primary mr-3"
                  onClick={this.handleSubmit}
                >
                  Save
                </button>
                <button
                  className="tt-button tt-button-primary mr-3"
                  onClick={this.props.toggleSyllabusResourcesModal}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>

        {this.state.showSpinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Uploading Resources ...
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default CourseSyllabusResource;
