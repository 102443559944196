import React, { Component } from "react";
import CustomCollapse from "../../UI/CustomCollapse";
import { displayConfirmDeleteAlert } from "../../../utils/Utils";
import Chip from "@material-ui/core/Chip";

class UpdateSubjects extends Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    return (
      <>
        <tr key={this.props.idx}>
          <td>
            {this.props.subjectList.name}
            {this.props.subjectList.hasTheory ||
              this.props.subjectList.hasPractical ? (

              <Chip
                color={"default"}
                className="override-chip"
                label={
                  this.props.subjectList.hasTheory &&
                    this.props.subjectList.hasPractical
                    ? "Theory-Practical "
                    : this.props.subjectList.hasTheory
                      ? "Theory"
                      : "Practical"
                }
              />
            ) : null
            }
            <br></br>
            {this.props.subjectList.subjectTheoryCode ||
              this.props.subjectList.subjectPracticalCode ? (
              <Chip
                color={"default"}
                className="override-chip"
                  label={
                    this.props.subjectList.subjectTheoryCode ==
                      this.props.subjectList.subjectPracticalCode ? "Subject Code: " + this.props.subjectList.subjectTheoryCode :
                      this.props.subjectList.subjectTheoryCode &&
                        this.props.subjectList.subjectPracticalCode
                        ? "Theory :" + this.props.subjectList.subjectTheoryCode + " - Practical: " + this.props.subjectList.subjectPracticalCode
                        : this.props.subjectList.subjectTheoryCode
                          ? "Theory: " + this.props.subjectList.subjectTheoryCode
                          : "Practical: " + this.props.subjectList.subjectPracticalCode
                  }
              />
            ) : null
            }

          </td>

          <td>{this.props.subjectList.subjectAreaName}</td>
          <td className="text-left">
            {this.props.subjectList.description !== ""
              ? this.props.subjectList.description
              : "No description avaialble"}
          </td>

          {this.props.permissionEditSubject ||
          this.props.permissionDeleteSubject ||
          this.props.permissionSettingSubject ||
          this.props.permissionAssignTeacher ? (
            <td>
              {this.props.permissionEditSubject ? (
                <button
                  className="tt-button tt-button-primary mt-0 tt-button-fullWidth"
                  onClick={() =>
                    this.props.handleUpdateSubjectBtnClick(
                      this.props.idx,
                      this.props.subjectList.id
                    )
                  }
                >
                  Update
                </button>
              ) : null}
              {this.props.permissionDeleteSubject ? (
                <button
                  className="tt-button tt-button-primary mt-1 tt-button-fullWidth"
                  onClick={(e) =>
                    displayConfirmDeleteAlert(
                      {
                        e,
                        id: this.props.subjectList.id,
                      },
                      this.props.deleteList
                    )
                  }
                >
                  Delete
                </button>
              ) : null}
              {this.props.permissionSettingSubject ? (
                <button
                  className="tt-button tt-button-primary mt-1 tt-button-fullWidth"
                  onClick={() =>
                    this.props.toggleSettingModal(
                      this.props.subjectList.name,
                      this.props.subjectList.id
                    )
                  }
                >
                  Settings
                </button>
              ) : null}
              {this.props.permissionAssignTeacher ? (
                <button
                  className="tt-button tt-button-primary mt-1 tt-button-fullWidth"
                  onClick={() =>
                    this.props.toggleAssignModal(
                      this.props.subjectList.name,
                      this.props.subjectList.id
                    )
                  }
                >
                  Assign Teacher
                </button>
              ) : null}
            </td>
          ) : null}
        </tr>
        <div className="tt-divRow">
          <td colSpan="4">
            <CustomCollapse
              isOpen={this.props.subjectList.isOpen}
              collapsibleBody={this.props.createSubjectContent(
                this.props.subjectList.id
              )}
            ></CustomCollapse>
          </td>
        </div>
      </>
    );
  }
}

export default UpdateSubjects;
