import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Spinner } from 'reactstrap';
import { axiosPost } from '../../utils/AxiosApi';
import { smsSentStatus, URL } from '../../utils/Constants';
import SmsDetails from './SmsDetails';
import ModalWindow from '../UI/ModalWindow';
import swal from "sweetalert";
import { displayErrorAlert } from '../../utils/Utils';


const ListSMSLog = () => {
  const [smsLogData, setSmsLogData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [detailLog, setDetailLog] = useState([]);
  const [detailsModal, setDetailsModal] = useState(false);
  const[detailsSentStatus, setDetailsSentStatus] = useState(false);
  const[detailsMessage, setDetailsMessage] = useState("");
  const [smsStatus, setSmsStatus] = useState("");
  const [sentDate, setSentDate] = useState("");
  const [searchText, setSearchText] = useState("");
  const dataColumns = [
    {key: "message", name: "Message"},
    {key: "sentDate", name: "Sent On"},
    {key: "creditUsed", name: "Credit Used"},
    {key: "status", name: "Status"},
    {key: "action", name: "Action"}
  ];
  const dataRows = [
    {message: "This is a test message", sentDate: "2021-08-23 12:00 PM", creditUsed: 1, status: "Sent", action: "Details"},
    {message: "This is a test message", sentDate: "2021-08-23 12:00 PM", creditUsed: 1, status: "Draft", action: "Details"},
    {message: "This is a test message", sentDate: "2021-08-23 12:00 PM", creditUsed: 1, status: "Queued", action: "Details"},
    {message: "This is a test message", sentDate: "2021-08-23 12:00 PM", creditUsed: 1, status: "Failed", action: "Details"}
  ]



  useEffect(() => {
    getSmsLog(smsStatus,sentDate,searchText);
  }, [])

  const getSmsLog = (smsStatusParam,sentDateParam,searchTextParam) => {
    let url = URL.listSmsLog;
    let param = {
      sentStatus: smsStatusParam == "" ? "" : smsStatus,
      sentDate: sentDateParam == "" ? "" :  sentDate,
      searchText: searchTextParam == "" ? "" :  searchText
    }
    setLoadingSpinner(true);
    axiosPost(url, param, (response) => {
      if (response.status === 200) {
        setSmsLogData(response.data.data);
      }
      setLoadingSpinner(false);
    })
  }

  const toggleDetailsModal = () => {
    setDetailsModal(!detailsModal);
  }


  const handleSendSms = (smslog) => {
    let url = URL.sendSms;
    let data = {
      smsLogId: smslog.id
    }
    axiosPost(url, data, (response) => {
      if (response.status === 200) {
        swal("Success", "SMS has been queued for sending.");
        getSmsLog(smsStatus,sentDate,searchText);
      } else {
        displayErrorAlert(response);
      }
    })

  }

  const renderSentStatus = (logData) => {
    return (
      <div
        className={logData.sentStatus == smsSentStatus.Sent
            ? "tt-chip-green": logData.sentStatus == smsSentStatus.Draft ? "tt-chip-yellow" : logData.sentStatus == smsSentStatus.Queued ? "tt-chip-blue" : "tt-chip-red"
        }
      >
        {logData.sentStatus}
      </div>
    )
  }

  const handleResetSearch = () => {
    setSmsStatus("");
    setSentDate("");
    setSearchText("");
    getSmsLog("","","");
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case "smsStatus":
        setSmsStatus(value);
        break;
      case "sentDate":
        setSentDate(value);
        break;
      case "searchText":
        setSearchText(value);
        break;
    }
  };
  return (
    <>
      {spinner ? (
        <div className="fullWindow-Spinner">
          <div>
            <Spinner color="white"></Spinner>
          </div>
          <div style={{ fontSize: "16px", marginTop: "15px" }}>
            Please wait !! Processing.....
          </div>
        </div>
      ) : null}
      {/* <x dataColumns={dataColumns} dataRows={smsLogData} /> */}
      <div className="tt-widgetContent-tab-holder" style={{ height: "79.5vh" }}>
        <div className="tt-group-header">Search SMS</div>

        <div className="row mt-2 p-2">
            <div className="col-md-4">
              <div>
                <strong>Search Text</strong>
              </div>
              <input
            type="text"
            className="form-control"
            name="searchText"
            onChange={handleChange}
            value={searchText}
            placeholder="Enter text to search"
          ></input>
            </div>
            <div className="col-md-4">
              <div>
                <strong>Sent Date</strong>
              </div>
              <input
            type="date"
            className="form-control"
            name="sentDate"
            onChange={handleChange}
            value={sentDate}
          />
            </div>

            <div className="col-md-4">
              <div>
                <strong>Status</strong>
              </div>
              <select
            name="smsStatus"
            onChange={(e) =>handleChange(e)}
            className="form-control"
            value={smsStatus}
          >
            <option value="" selected disabled>
              Choose Sent Status
            </option>
            <option value="Sent">Sent</option>
            <option value="Draft">Draft</option>
          </select>
            </div>

      </div>
      <div className="row mt-2 p-2">
        <div className="col text-right mt-2 d-flex justify-content-end align-items-end">
          <button
            className="tt-button tt-button-danger"
            onClick={handleResetSearch}
          >
            Reset
          </button>
          <button
            className="tt-button tt-button-primary"
            onClick={getSmsLog}
          >
            Search
          </button>
        </div>
</div>
        <div
          className="tt-newListBox mb-1"
          style={{
            height: "60.5vh",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {loadingSpinner ? (
            <div className="text-center">
              <Spinner color="primary"></Spinner>
            </div>
          ) : smsLogData.length > 0 ? (
            <>
              <table className="table table-bordered table-striped">
                <thead className="tt-group-header">
                  <tr>
                    <th width="210px">Message</th>
                    <th width="210px">Sent On</th>

                    <th width="165px">Credit Used</th>
                    <th width="165px">Status</th>

                    <th width="120px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {smsLogData?.map((logData) => (
                    <tr>
                      <td>{logData.message}</td>
                      <td>
                        {logData?.sentDate && moment(logData?.sentDate).format("yyyy-MM-DD, hh:mm A")}
                      </td>
                      <td>{logData.creditUsed}</td>
                      <td>
                        {renderSentStatus(logData)}
                        {logData.sentStatus == smsSentStatus.Draft &&(

                          <button
                          className="tt-button tt-button-primary ml-3"
                        onClick={() => handleSendSms(logData)}
                        >
                          Send
                        </button>
                        )}
                      </td>
                      <td>
                        <button
                          className="tt-button tt-button-primary ml-3"
                        onClick={() =>
                          {
                            setDetailsMessage(logData.message);
                            setDetailsSentStatus(logData.sentStatus);
                            setDetailLog(logData.sendSmsTo);
                            toggleDetailsModal();
                          }
                        }
                        >
                          Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/*
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Pagination
                itemsCount={this.state.totalRecords}
                pageSize={this.state.pageSize}
                currentPage={this.state.currentPage}
                onPageChange={this.handlePageChange}
                previousPage={this.handlePreviousPage}
                nextPage={this.handleNextPage}
              />
            </div> */}
            </>
          ) : (
            <div className="text-center p-2">
              No records found for your search parameters!!
            </div>
          )}
        </div>

        {/* <ModalWindow
        modal={this.state.editRemModal}
        modalHeader="Update Reminder"
        toggleModal={this.toggleUpdateModal}
        modalBody={
          <Reminder
            updateRemData={this.state.updateRemData}
            editMode={true}
            toggleUpdateModal={this.toggleUpdateModal}
            getAllNotifications={this.getAllNotifications}
          />
        }
        size="lg"
      ></ModalWindow>
      */}
        <ModalWindow
          modal={detailsModal}
          toggleModal={toggleDetailsModal}
          modalHeader="Sms Details"
          modalBody={
            <SmsDetails
              detailLog={detailLog}
              sentStatus = {detailsSentStatus}
              message={detailsMessage}
            />
          }
          size="xl"
        ></ModalWindow>
      </div>
    </>
  )
}

export default ListSMSLog