import React, { useState, useEffect, useRef } from "react";

import {
  checkRolePermissions,
  checkSubsTeacherPermissions,
  scaleTypeCode,
  URL,
} from "../../../../utils/Constants";
// import userImage from "../../../../assets/images/user-image.png";
import userImage from "../../../../assets/images//user-image.png";
import { handleError } from "../../../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faMinusSquare,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-select/dist/css/bootstrap-select.min.css";
import "bootstrap-select/dist/js/bootstrap-select.min.js";
import { useReactToPrint } from "react-to-print";

const StudentReportGeneration = (props) => {
  const componentRef = useRef();
  const printFunction = useReactToPrint({
    content: () => componentRef.current,
    orientation: "landscape",
    removeAfterPrint: true,
    documentTitle: `${props?.assessmentSubjectValue?.label} Marks Ledger_${props.classValue?.label}_${props?.classRooms?.label}`,
  });

  const [commentIdx, setCommentIdx] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [finalizeReportPermission, setFinalizeReportPermission] =
    useState(null);
  const [unFinalizeReportPermission, setUnFinalizeReportPermission] =
    useState(null);
  const [students, setStudents] = useState([]);
  useEffect(() => {
    checkPermissions();
  }, []);

  const checkPermissions = () => {
    let finalizeReportPermissionCheck =
      checkRolePermissions(
        "finalize-subject-assessment",
        "activity",
        props.widgetName
      ) ||
      checkSubsTeacherPermissions(
        "finalize-subject-assessment",
        "activity",
        props.widgetName
      );

    let unFinalizeReportPermissionCheck =
      checkRolePermissions(
        "unfinalize-subject-assessment",
        "activity",
        props.widgetName
      ) ||
      checkSubsTeacherPermissions(
        "unfinalize-subject-assessment",
        "activity",
        props.widgetName
      );

    setFinalizeReportPermission(finalizeReportPermissionCheck);
    setUnFinalizeReportPermission(unFinalizeReportPermissionCheck);
  };

  const getTheoryPracticalLength = (item) => {
    if (item.hasTheory && item.hasPractical) {
      return (
        item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical
          .length +
        item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory
          .length
      );
    } else if (item.hasTheory) {
      return item.subjectAssessmentScalesTheoryPractcal
        .assessmentScalesForTheory.length;
    } else if (item.hasPractical) {
      return item.subjectAssessmentScalesTheoryPractcal
        .assessmentScalesForPractical.length;
    } else {
      return 0;
    }
  };

  const getTheoryPracticalTitle = (item) => {
    if (item.hasTheory && item.hasPractical) {
      return (
        <>
          {item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
            (el) => (
              <th
                rowSpan={1}
                style={{ color: "black", border: "1px solid black" }}
              >
                {" "}
                Th {`${el.theoryMax ? `(${el.theoryMax})` : ""}`}
              </th>
            )
          )}
          {item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.map(
            (el) => (
              <th
                rowSpan={1}
                style={{ color: "black", border: "1px solid black" }}
              >
                Pr {`${el.practicalMax ? `(${el.practicalMax})` : ""}`}
              </th>
            )
          )}
        </>
      );
    } else if (item.hasTheory) {
      return item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
        (el) => (
          <th rowSpan={1} style={{ color: "black", border: "1px solid black" }}>
            Th {`${el.theoryMax ? `(${el.theoryMax})` : ""}`}
          </th>
        )
      );
    } else if (item.hasPractical) {
      return item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.map(
        (el) => (
          <th rowSpan={1} style={{ color: "black", border: "1px solid black" }}>
            Pr {`${el.practicalMax ? `(${el.practicalMax})` : ""}`}
          </th>
        )
      );
    } else {
      return <></>;
    }
  };

  const getTheoryPracticalTitleData = (item, student) => {
    const filtredStudentSub = student?.subject?.[item.name]?.[0];

    if (!filtredStudentSub) {
      let finalPracticalAssessmentDetail =
        item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.map(
          (el) => "N/A"
        );

      let finalTheoryAssessmentDetail =
        item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
          (el) => {
            return "N/A";
          }
        );

      if (item.hasTheory && item.hasPractical) {
        return (
          <>
            {finalPracticalAssessmentDetail.map((assessment) => (
              <td className="text-center">N/A</td>
            ))}
            {finalTheoryAssessmentDetail.map((assessment) => (
              <td className="text-center">N/A</td>
            ))}
          </>
        );
      } else if (item.hasTheory) {
        return (
          <>
            {finalTheoryAssessmentDetail.map((assessment) => (
              <td className="text-center">N/A</td>
            ))}
          </>
        );

        // return item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
        //   (el) => <td>td {`(${el.scaleName})`}</td>
        // );
      } else if (item.hasPractical) {
        return item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.map(
          (el) => <td>N/A</td>
        );
      } else {
        return <></>;
      }

      // console.log(finalTheoryAssessmentDetail,"final theory assessent detaujl ")

      return;
    }

    // let theoryAssesmentDetail =filtredStudentSub?.theorySubjectAssessments?.filter(
    //     (data) => data.admissionId == student.admissionId
    //   ) ?? [];

    let finalTheoryAssessmentDetail =
      filtredStudentSub?.theorySubjectAssessments;
    // let finalTheoryAssessmentDetail =
    //   item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
    //     (el) => {
    //       let theroyDetail = theoryAssesmentDetail.find(
    //         (itm) => itm.assessmentScaleId == el.id
    //       );
    //       return {
    //         ...theroyDetail,
    //         assessmentScale: el,
    //         assessmentScaleTypeCode: el.assessmentScaleTypeCode,
    //       };
    //     }
    //   );

    let finalPracticalAssessmentDetail =
      filtredStudentSub.practicalSubjectAssessments;
    // let finalPracticalAssessmentDetail =
    //   item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.map(
    //     (el) => {
    //       let practicalDetail = practicalAssesmentDetail.find(
    //         (itm) => itm.assessmentScaleId == el.id
    //       );
    //       return {
    //         ...practicalDetail,
    //         assessmentScale: el,
    //         assessmentScaleTypeCode: el.assessmentScaleTypeCode,
    //       };
    //     }
    //   );

    if (item.hasTheory && item.hasPractical) {
      return (
        <>
          {finalTheoryAssessmentDetail.map((assessment) => (
            <td className="text-center">
              {assessment.isTheoryAbsent ? null : (
                <div>
                  {assessment.assessmentScaleTypeCode === scaleTypeCode.level
                    ? !assessment?.assessmentScaleLevelTheoryId
                      ? "-"
                      : assessment?.assessmentScale.assessmentScaleLevels
                          ?.filter(
                            (item) =>
                              item.scaleLevel.id ===
                              assessment.assessmentScaleLevelTheoryId
                          )
                          ?.map((item) => {
                            if (item.levelType === "colourStickers") {
                              return (
                                <div
                                  style={{
                                    backgroundColor: item.level,
                                    color: "#fff",
                                    fontSize: "25px",
                                  }}
                                >
                                  {item.level}
                                </div>
                              );
                            } else if (item.levelType === "image") {
                              return (
                                <img
                                  src={URL.imageSelectURL + item.level}
                                  class="img-responsive"
                                  height="42"
                                  width="42"
                                />
                              );
                            } else {
                              return item.level;
                            }
                          })
                    : // <select
                    //   style={{ Width: "50%" }}
                    //   value={assessment?.assessmentScaleLevelTheoryId}
                    //   onChange={(e) =>
                    //     props.handleAssessmentScaleForTheoryLevelChange(
                    //       e,
                    //       assessment,
                    //       item.name
                    //     )
                    //   }
                    //   className="form-control selectpicker"
                    //   disabled={assessment.isFinalized ? true : false}
                    // >
                    //   <option value="" disabled>
                    //     {"Select Level"}
                    //   </option>
                    //   {assessment.assessmentScale.assessmentScaleLevels
                    //     ? assessment.assessmentScale.assessmentScaleLevels.map(
                    //       (scaleLevel) => (
                    //         <>
                    //           {scaleLevel.levelType === "colourStickers" ? (
                    //             <option
                    //               value={scaleLevel && scaleLevel.id}
                    //               style={{
                    //                 backgroundColor: scaleLevel.level,
                    //                 color: "#fff",
                    //                 fontSize: "25px",
                    //               }}
                    //             >
                    //               {scaleLevel.level}
                    //             </option>
                    //           ) : scaleLevel.levelType === "image" ? (
                    //             <option
                    //               value={scaleLevel.id}
                    //               data-content={`<img src=${URL.imageSelectURL + scaleLevel.level
                    //                 } class="img-responsive" height="42" width="42"/>`}
                    //             ></option>
                    //           ) : (
                    //             <option value={scaleLevel.id}>
                    //               {scaleLevel.level}
                    //             </option>
                    //           )}
                    //         </>
                    //       )
                    //     )
                    //     : null}
                    // </select>
                    assessment.assessmentScale.assessmentScaleTypeCode ===
                      scaleTypeCode.mark
                    ? assessment.assessmentScale && (
                        <div className="d-flex justify-content-center">
                          <div>
                            {assessment?.theoryObtainedMarks == "" ||
                            assessment?.theoryObtainedMarks == null ||
                            assessment?.theoryObtainedMarks == undefined
                              ? "-"
                              : assessment?.theoryObtainedMarks}
                          </div>
                        </div>
                      )
                    : assessment.assessmentScale.assessmentScaleTypeCode ===
                      scaleTypeCode.gradePoint
                    ? assessment?.assessmentScaleGradePointTheoryId
                      ? assessment.assessmentScale.assessmentScaleGradePoints.filter(
                          (item) =>
                            item.id ==
                            assessment.assessmentScaleGradePointTheoryId
                        )[0]?.grade
                      : "-"
                    : // <select
                      //   value={
                      //     assessment?.assessmentScaleGradePointTheoryId ?? ""
                      //   }
                      //   onChange={(e) =>
                      //     props.handleAssessmentScaleTheoryGradePointChange(
                      //       e,
                      //       assessment,
                      //       item.name
                      //     )
                      //   }
                      //   style={{ Width: "50%" }}
                      //   disabled={assessment.isFinalized ? true : false}
                      // >
                      //   <option value="" disabled>
                      //     {"Select Grade Point"}
                      //   </option>
                      //   {assessment.assessmentScale.assessmentScaleGradePoints
                      //     ? assessment.assessmentScale.assessmentScaleGradePoints.map(
                      //         (scaleGradePoint) => (
                      //           <option value={scaleGradePoint.id}>
                      //             {scaleGradePoint.grade}
                      //           </option>
                      //         )
                      //       )
                      //     : null}
                      // </select>
                      null}
                </div>
              )}

              <div
              //   className="mt-2"
              >
                {assessment.isTheoryAbsent ? "Abs" : ""}
              </div>
            </td>
          ))}

          {finalPracticalAssessmentDetail.map((assessment) => (
            <td className="text-center">
              {}
              {assessment.isPracticalAbsent ? null : (
                <div>
                  {assessment.assessmentScale.assessmentScaleTypeCode ===
                  scaleTypeCode.level ? (
                    <select
                      style={{ width: "50%" }}
                      value={assessment?.assessmentScaleLevelPracticalId}
                      onChange={(e) =>
                        props.handleAssessmentScaleForPracticalLevelChange(
                          e,
                          assessment,
                          item.name
                        )
                      }
                      className="form-control selectpicker"
                      disabled={assessment.isFinalized ? true : false}
                    >
                      <option value="" disabled>
                        {"Select Level"}
                      </option>

                      {assessment?.assessmentScale?.assessmentScaleLevels.map(
                        (scaleLevel) => (
                          <>
                            {scaleLevel.levelType === "colourStickers" ? (
                              <option
                                value={scaleLevel.id}
                                style={{
                                  backgroundColor: scaleLevel.level,
                                  color: "#fff",
                                  fontSize: "25px",
                                }}
                              >
                                {scaleLevel.level}
                              </option>
                            ) : scaleLevel.levelType === "image" ? (
                              <option
                                value={scaleLevel.id}
                                data-content={`<img src=${
                                  URL.imageSelectURL + scaleLevel.level
                                } class="img-responsive" height="42" width="42"/>`}
                              ></option>
                            ) : (
                              <option value={scaleLevel.id}>
                                {scaleLevel.level}
                              </option>
                            )}
                          </>
                        )
                      )}
                    </select>
                  ) : assessment.assessmentScale.assessmentScaleTypeCode ===
                    scaleTypeCode.mark ? (
                    <>
                      {/* <input
                        type="number"
                        max={
                          assessment.assessmentScale
                            ? assessment.assessmentScale.practicalMax
                              ? assessment.assessmentScale.practicalMax
                              : 100
                            : 100
                        }
                        // count={
                        //   assess?.practicalObtainedMarks
                        // }
                        value={assessment?.practicalObtainedMarks ?? 0}
                       disabled={assessment.isFinalized || (props.finalizeUnFinalizeVal[item.name] && !props.finalizeUnFinalizeVal[item.name]===true)}
                        style={{
                          border:(assessment.isFinalized ||(props.finalizeUnFinalizeVal[item.name] && !props.finalizeUnFinalizeVal[item.name]===true))
                            ? "1px solid grey"
                            : "1px solid black",
                          height: "38px",
                          width: "55px",
                          textAlign: "center",

                        //   background:(assessment.isFinalized ||(props.finalizeUnFinalizeVal[item.name] && !props.finalizeUnFinalizeVal[item.name]===true)) ? "#dddddd" : "",
                        //   color:(assessment.isFinalized ||(props.finalizeUnFinalizeVal[item.name] && !props.finalizeUnFinalizeVal[item.name]===true)) ? "grey" : "",
                        }}
                        onChange={(count) =>
                          props.practicalCounterHandler(
                            count.target.value,
                            assessment,
                            item.name
                          )
                        }
                        step={0.5}
                      /> */}
                      {assessment?.practicalObtainedMarks == "" ||
                      assessment?.practicalObtainedMarks == null ||
                      assessment?.practicalObtainedMarks == undefined
                        ? "-"
                        : assessment?.practicalObtainedMarks}
                    </>
                  ) : assessment.assessmentScale.assessmentScaleTypeCode ===
                    scaleTypeCode.gradePoint ? (
                    assessment?.assessmentScaleGradePointPracticalId ? (
                      assessment.assessmentScale.assessmentScaleGradePoints.filter(
                        (item) =>
                          item.id ==
                          assessment.assessmentScaleGradePointPracticalId
                      )[0]?.grade
                    ) : (
                      "-"
                    )
                  ) : // <select
                  //   value={
                  //     assessment?.assessmentScaleGradePointPracticalId ?? ""
                  //   }
                  //   onChange={(e) =>
                  //     props.handleAssessmentScalePracticalGradePointChange(
                  //       e,
                  //       assessment,
                  //       item.name
                  //     )
                  //   }
                  //   style={{ Width: "50%" }}
                  //   disabled={assessment.isFinalized ? true : false}
                  // >
                  //   <option value="" disabled>
                  //     {"Select Grade Point"}
                  //   </option>
                  //   {assessment.assessmentScale.assessmentScaleGradePoints
                  //     ? assessment.assessmentScale.assessmentScaleGradePoints.map(
                  //       (scaleGradePoint) => (
                  //         <option value={scaleGradePoint.id}>
                  //           {scaleGradePoint.grade}
                  //         </option>
                  //       )
                  //     )
                  //     : null}
                  // </select>
                  null}
                </div>
              )}

              <div
              //   className="mt-2"
              >
                {assessment?.isPracticalAbsent ? "Abs" : ""}
                {/* <input
                  // id={
                  //   "isPracticalAbsent" +
                  //   assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  type="checkbox"
                  name="isPracticalAbsent"
                  checked={assessment?.isPracticalAbsent}
                 disabled={assessment.isFinalized || (props.finalizeUnFinalizeVal[item.name] && !props.finalizeUnFinalizeVal[item.name]===true)}
                  onChange={(e) =>
                    props.handleIsAbsentForPractical(e, assessment, item.name)
                  }
                />
                <label
                  // htmlFor={
                  //   "isPracticalAbsent" +
                  //   assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  style={{
                    paddingLeft: "5px",
                    position: "relative",
                    top: "-3px",
                  }}
                >
                  <strong>Is Absent</strong>
                </label> */}
              </div>
            </td>
          ))}
        </>
      );
    } else if (item.hasTheory) {
      return (
        <>
          {finalTheoryAssessmentDetail.map((assessment) => (
            <td className="text-center">
              {assessment.isTheoryAbsent ? null : (
                <div>
                  {assessment.assessmentScaleTypeCode === scaleTypeCode.level
                    ? assessment.assessmentScaleLevelTheoryId
                      ? assessment?.assessmentScale.assessmentScaleLevels
                          ?.filter(
                            (item) =>
                              item.id ===
                              assessment.assessmentScaleLevelTheoryId
                          )
                          ?.map((item) => {
                            if (!item.level) {
                              return "-";
                            } else if (item.levelType === "colourStickers") {
                              return (
                                <div
                                  style={{
                                    backgroundColor: item?.level,
                                    color: "#fff",
                                    fontSize: "25px",
                                  }}
                                >
                                  {item?.level}
                                </div>
                              );
                            } else if (item.levelType === "image") {
                              return (
                                <img
                                  src={URL.imageSelectURL + item?.level}
                                  class="img-responsive"
                                  height="42"
                                  width="42"
                                />
                              );
                            } else {
                              return item?.level;
                            }
                          })
                      : "-"
                    : // assessment.assessmentScaleLevelTheoryId ? assessment.assessmentScale.assessmentScaleLevels.filter(item => item.id == assessment.assessmentScaleLevelTheoryId)[0]?.level : "-"
                    // <select
                    //   style={{ Width: "50%" }}
                    //   value={assessment?.assessmentScaleLevelTheoryId}
                    //   onChange={(e) =>
                    //     props.handleAssessmentScaleForTheoryLevelChange(
                    //       e,
                    //       assessment,
                    //       item.name
                    //     )
                    //   }
                    //   className="form-control selectpicker"
                    //   disabled={assessment.isFinalized ? true : false}
                    // >
                    //   <option value="" disabled>
                    //     {"Select Level"}
                    //   </option>
                    //   {assessment.assessmentScale.assessmentScaleLevels
                    //     ? assessment.assessmentScale.assessmentScaleLevels.map(
                    //         (scaleLevel) => (
                    //           <>
                    //             {scaleLevel.levelType === "colourStickers" ? (
                    //               <option
                    //                 value={scaleLevel && scaleLevel.id}
                    //                 style={{
                    //                   backgroundColor: scaleLevel.level,
                    //                   color: "#fff",
                    //                   fontSize: "25px",
                    //                 }}
                    //               >
                    //                 {scaleLevel.level}
                    //               </option>
                    //             ) : scaleLevel.levelType === "image" ? (
                    //               <option
                    //                 value={scaleLevel.id}
                    //                 data-content={`<img src=${
                    //                   URL.imageSelectURL + scaleLevel.level
                    //                 } class="img-responsive" height="42" width="42"/>`}
                    //               ></option>
                    //             ) : (
                    //               <option value={scaleLevel.id}>
                    //                 {scaleLevel.level}
                    //               </option>
                    //             )}
                    //           </>
                    //         )
                    //       )
                    //     : null
                    //     }
                    // </select>
                    assessment.assessmentScale.assessmentScaleTypeCode ===
                      scaleTypeCode.mark
                    ? assessment.assessmentScale && (
                        <div className="d-flex justify-content-center">
                          <div>
                            {assessment?.theoryObtainedMarks == "" ||
                            assessment?.theoryObtainedMarks == null ||
                            assessment?.theoryObtainedMarks == undefined
                              ? "-"
                              : assessment?.theoryObtainedMarks}
                            {/* <input
                            type="number"
                            min={0}
                            max={assessment.assessmentScale?.theoryMax}
                            value={assessment?.theoryObtainedMarks ?? 0}
                           disabled={assessment.isFinalized || (props.finalizeUnFinalizeVal[item.name] && !props.finalizeUnFinalizeVal[item.name]===true)}
                            style={{
                              border:(assessment.isFinalized ||(props.finalizeUnFinalizeVal[item.name] && !props.finalizeUnFinalizeVal[item.name]===true))
                                ? "1px solid grey"
                                : "1px solid black",
                              height: "38px",
                              width: "55px",
                              textAlign: "center",
                            //   background:(assessment.isFinalized ||(props.finalizeUnFinalizeVal[item.name] && !props.finalizeUnFinalizeVal[item.name]===true))
                            //     ? "#dddddd"
                            //     : "",
                            //   color:(assessment.isFinalized ||(props.finalizeUnFinalizeVal[item.name] && !props.finalizeUnFinalizeVal[item.name]===true)) ? "grey" : "",
                            }}
                            onChange={(count) =>
                              props.theoryCounterHandler(
                                count.target.value,
                                assessment,
                                item.name
                              )
                            }
                            step={0.5}
                          /> */}
                          </div>
                        </div>
                      )
                    : assessment.assessmentScale.assessmentScaleTypeCode ===
                      scaleTypeCode.gradePoint
                    ? assessment?.assessmentScaleGradePointTheoryId
                      ? assessment.assessmentScale.assessmentScaleGradePoints.filter(
                          (item) =>
                            item.id ==
                            assessment.assessmentScaleGradePointTheoryId
                        )[0]?.grade
                      : "-"
                    : // <select
                      //   value={
                      //     assessment?.assessmentScaleGradePointTheoryId ?? ""
                      //   }
                      //   onChange={(e) =>
                      //     props.handleAssessmentScaleTheoryGradePointChange(
                      //       e,
                      //       assessment,
                      //       item.name
                      //     )
                      //   }
                      //   style={{ Width: "50%" }}
                      //   disabled={assessment.isFinalized ? true : false}
                      // >
                      //   <option value="" disabled>
                      //     {"Select Grade Point"}
                      //   </option>
                      //   {assessment.assessmentScale.assessmentScaleGradePoints
                      //     ? assessment.assessmentScale.assessmentScaleGradePoints.map(
                      //         (scaleGradePoint) => (
                      //           <option value={scaleGradePoint.id}>
                      //             {scaleGradePoint.grade}
                      //           </option>
                      //         )
                      //       )
                      //     : null}
                      // </select>
                      null}
                </div>
              )}

              <div
              //   className="mt-2"
              >
                {assessment.isTheoryAbsent ? "Abs" : ""}
                {/* <input
                  // id={
                  //   "isTheoryAbsent" +
                  //   assessment.assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  type="checkbox"
                  name="isTheoryAbsent"
                  checked={assessment.isTheoryAbsent}
                  disabled={
                    assessment.isFinalized ||
                    (props.finalizeUnFinalizeVal[item.name] &&
                      !props.finalizeUnFinalizeVal[item.name] === true)
                  }
                  onChange={(e) =>
                    props.handleIsAbsentForTheory(e, assessment, item.name)
                  }
                />
                <label
                  // htmlFor={
                  //   "isTheoryAbsent" +
                  //   assessment.assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  style={{
                    paddingLeft: "5px",
                    position: "relative",
                    top: "-3px",
                  }}
                >
                  <strong>Is Absent</strong>
                </label>
              </div> */}
              </div>
            </td>
          ))}
        </>
      );

      // return item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForTheory.map(
      //   (el) => <td>td {`(${el.scaleName})`}</td>
      // );
    } else if (item.hasPractical) {
      // return item.subjectAssessmentScalesTheoryPractcal.assessmentScalesForPractical.map(
      //   (el) => <td>Pr {`(${el.scaleName})`}</td>
      // );
      return (
        <>
          {finalPracticalAssessmentDetail.map((assessment) => (
            <td className="text-center">
              {assessment.isPracticalAbsent ? null : (
                <div>
                  {assessment.assessmentScaleTypeCode ===
                  scaleTypeCode.level ? (
                    <select
                      style={{ Width: "50%" }}
                      value={assessment?.assessmentScaleLevelPracticalId}
                      onChange={(e) =>
                        props.handleAssessmentScaleForPracticalLevelChange(
                          e,
                          assessment,
                          item.name
                        )
                      }
                      className="form-control selectpicker"
                      disabled={assessment.isFinalized ? true : false}
                    >
                      <option value="" disabled>
                        {"Select Level"}
                      </option>
                      {assessment.assessmentScale.assessmentScaleLevels
                        ? assessment.assessmentScale.assessmentScaleLevels.map(
                            (scaleLevel) => (
                              <>
                                {scaleLevel.levelType === "colourStickers" ? (
                                  <option
                                    value={scaleLevel && scaleLevel.id}
                                    style={{
                                      backgroundColor: scaleLevel.level,
                                      color: "#fff",
                                      fontSize: "25px",
                                    }}
                                  >
                                    {scaleLevel.level}
                                  </option>
                                ) : scaleLevel.levelType === "image" ? (
                                  <option
                                    value={scaleLevel.id}
                                    data-content={`<img src=${
                                      URL.imageSelectURL + scaleLevel.level
                                    } class="img-responsive" height="42" width="42"/>`}
                                  ></option>
                                ) : (
                                  <option value={scaleLevel.id}>
                                    {scaleLevel.level}
                                  </option>
                                )}
                              </>
                            )
                          )
                        : null}
                    </select>
                  ) : assessment.assessmentScale.assessmentScaleTypeCode ===
                    scaleTypeCode.mark ? (
                    assessment.assessmentScale && (
                      <div className="d-flex justify-content-center">
                        <div>
                          {assessment?.practicalObtainedMarks == "" ||
                          assessment?.practicalObtainedMarks == null ||
                          assessment?.practicalObtainedMarks == undefined
                            ? "-"
                            : assessment?.practicalObtainedMarks}
                          {/* <input
                            type="number"
                            min={0}
                            max={assessment.assessmentScale?.practicalMax}
                            value={assessment?.practicalObtainedMarks ?? 0}
                            disabled={
                              assessment.isFinalized ||
                              (props.finalizeUnFinalizeVal[item.name] &&
                                !props.finalizeUnFinalizeVal[item.name] ===
                                  true)
                            }
                            style={{
                              border:
                                assessment.isFinalized ||
                                (props.finalizeUnFinalizeVal[item.name] &&
                                  !props.finalizeUnFinalizeVal[item.name] ===
                                    true)
                                  ? "1px solid grey"
                                  : "1px solid black",
                              height: "38px",
                              width: "55px",
                              textAlign: "center",
                              //   background:(assessment.isFinalized ||(props.finalizeUnFinalizeVal[item.name] && !props.finalizeUnFinalizeVal[item.name]===true))
                              //     ? "#dddddd"
                              //     : "",
                              //   color:(assessment.isFinalized ||(props.finalizeUnFinalizeVal[item.name] && !props.finalizeUnFinalizeVal[item.name]===true)) ? "grey" : "",
                            }}
                            onChange={(count) =>
                              props.practicalCounterHandler(
                                count.target.value,
                                assessment,
                                item.name
                              )
                            }
                            step={0.5}
                          /> */}
                        </div>
                      </div>
                    )
                  ) : assessment.assessmentScale.assessmentScaleTypeCode ===
                    scaleTypeCode.gradePoint ? (
                    assessment.assessmentScale.assessmentScaleGradePoints.filter(
                      (item) =>
                        item.id ==
                        assessment.assessmentScaleGradePointPracticalId
                    )[0]?.grade
                  ) : // <select
                  //   value={
                  //     assessment?.assessmentScaleGradePointPracticalId ?? ""
                  //   }
                  //   onChange={(e) =>
                  //     props.handleAssessmentScalePracticalGradePointChange(
                  //       e,
                  //       assessment,
                  //       item.name
                  //     )
                  //   }
                  //   style={{ Width: "50%" }}
                  //   disabled={assessment.isFinalized ? true : false}
                  // >
                  //   <option value="" disabled>
                  //     {"Select Grade Point"}
                  //   </option>
                  //   {assessment.assessmentScale.assessmentScaleGradePoints
                  //     ? assessment.assessmentScale.assessmentScaleGradePoints.map(
                  //         (scaleGradePoint) => (
                  //           <option value={scaleGradePoint.id}>
                  //             {scaleGradePoint.grade}
                  //           </option>
                  //         )
                  //       )
                  //     : null}
                  // </select>
                  null}
                </div>
              )}

              <div className="mt-2">
                <input
                  // id={
                  //   "isTheoryAbsent" +
                  //   assessment.assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  type="checkbox"
                  name="isPracticalAbsent"
                  checked={assessment.isPracticalAbsent}
                  disabled={
                    assessment.isFinalized ||
                    (props.finalizeUnFinalizeVal[item.name] &&
                      !props.finalizeUnFinalizeVal[item.name] === true)
                  }
                  onChange={(e) =>
                    props.handleIsAbsentForPractical(e, assessment, item.name)
                  }
                />
                <label
                  // htmlFor={
                  //   "isTheoryAbsent" +
                  //   assessment.assessmentScale.assessmentScaleTypeCode +
                  //   i +
                  //   idx
                  // }
                  style={{
                    paddingLeft: "5px",
                    position: "relative",
                    top: "-3px",
                  }}
                >
                  <strong>Is Absent</strong>
                </label>
              </div>
            </td>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  const getFinalizeText = (item) => {
    let practicalFinalize =
      item?.theorySubjectAssessments?.length > 0
        ? item.theorySubjectAssessments.some((item) => item.isFinalized)
        : false;
    let theoryFinalze =
      item?.practicalSubjectAssessments?.length > 0
        ? item.practicalSubjectAssessments.some((item) => item.isFinalized)
        : false;
    return practicalFinalize || theoryFinalze ? "Unfinalize" : "Finalize";
  };

  return (
    <>
      {" "}
      {props.loading || props.finalizeLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <Spinner color="primary" />

          <p> Loading Data ...</p>
        </div>
      ) : !props.allData.length > 0 ? (
        <div className="container-fluid">
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No Records found
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-end align-items-center mb-2">
            <span className="d-flex justify-content-center align-items-center">
              Print / Download
            </span>
            <button
              onClick={printFunction}
              className="tt-button tt-button-primary"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                style={{
                  color: "white",
                  fontSize: "1rem",
                  marginLeft: "4px",
                  padding: "0.5rem",
                }}
              />
            </button>
          </div>

          <div style={{display:"none"}}>
            <div
              ref={componentRef}
              id="printRoutine"
              className="container-fluid landscape"
            >
              <div
                className="d-flex"
                style={{
                  flexDirection: "column",
                  gap: "0.5rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p1 style={{ fontSize: "1.5rem", fontWeight: "700" }}>
                  {props?.assessmentSubjectValue?.label} Marks Ledger
                </p1>
                <p1
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "500",
                    marginBottom: "1rem",
                  }}
                >
                  {props.classValue?.label} - {props?.classRooms?.label}
                </p1>
              </div>

              <div className="row">
                <div
                  className="col tt-widgetContent-tab-holder"
                  style={{
                    maxHeight: "100%",
                    marginBottom: "10px",
                    overflow: "hidden",
                    maxWidth: "100%",
                  }}
                >
                  <table className="table table-striped table-bordered">
                    <thead
                      className="tt-group-header text-center"
                      style={{ position: "sticky", top: "-1px", zIndex: 1 }}
                    >
                      <tr>
                        <th
                          style={{ color: "black", width: "50px" }}
                          rowSpan={2}
                        >
                          SN
                        </th>
                        <th
                          rowSpan={2}
                          width="100px"
                          style={{ color: "black" }}
                        >
                          Student Name
                        </th>
                        {props.allData.map((item) => (
                          <th colSpan={getTheoryPracticalLength(item)}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column-reverse",
                                gap: "0.5rem",
                              }}
                            >
                              <span style={{ color: "black" }}>
                                {item?.name}
                              </span>
                            </div>
                          </th>
                        ))}
                      </tr>

                      <tr>
                        {props.allData.map((item) =>
                          getTheoryPracticalTitle(item)
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {!props.studentData.length > 0 ? (
                        <>No data found</>
                      ) : (
                        props.studentData.map((student, idx) => (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>
                              {student.people
                                ? student.people.name
                                : "Student Name"}
                            </td>
                            {props.allData.map((item) =>
                              getTheoryPracticalTitleData(item, student)
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StudentReportGeneration;
