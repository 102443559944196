import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import "../../assets/scss/calendarDisplay.scss";
import "../../assets/scss/calendarWidget.scss";
import { axiosPost } from "../../utils/AxiosApi";
import ReactTooltip from "react-tooltip";
import { URL } from "../../utils/Constants";
import { connect } from "react-redux";
import { getCalendarEvents } from "../../ducks/AcademicAdministrationDucks";
import { displayMessage, displayNepaliDate } from "../../utils/Utils";
import $ from "jquery";
import moment from "moment";
import NepaliDateViewer from "../UI/NepaliDateViewer";
// const { adToBs } = require('@sbmdkl/nepali-date-converter');
const { adToBs, bsToAd, calculateAge } = require("meropatro-dateconverter");

class CalendarDisplay extends Component {
  calendarComponentRef = React.createRef();
  state = {
    events: [],
    minimize: false,
    currentAcYrId: "",
    currentAcYr: "",
    currentAcYrName: "",
    systemDefaultAcYr: "",
    calendarDate: "",
  };


  componentDidMount() {
    this.getUserCurrentAcademicYear();
    ReactTooltip.rebuild();
    this.addMinimizeClass();

  }

  addMinimizeClass = () => {
    let self = this;
    $(window).on("load", function () {
      if ($(window).width() <= 768) {
        self.setState({ minimize: true });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.assessmentModuleandEducationLevel !==
      this.props.assessmentModuleandEducationLevel
    ) {
      this.props.getCalendarEvents(
        this.props.assessmentModuleandEducationLevel
      );
      ReactTooltip.rebuild();
    }
    this.renderNepaliDates();
  }

  filterSameCalendarEvents = () => {
    let events = [...this.props.calendarEvents];
    const unique = [];
    events.map((x) =>
      unique.filter(
        (a) =>
          a.color == x.color &&
          a.title == x.title &&
          a.start == x.start &&
          a.end == x.end
      ).length > 0
        ? null
        : unique.push(x)
    );
    // this.setState({ uniqueCalendarEvents: unique });
    return unique;
  };

  getUserCurrentAcademicYear = () => {
    axiosPost(URL.selectUserAcademicYear, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data) {
          this.setState({
            currentAcYr: response.data.data,
            currentAcYrId: response.data.data.academicYearId,
            currentAcYrName: response.data.data.academicYearName,
          });
        } else {
          this.getCurrentAcademicYear();
        }
      }
    });
  };

  getCurrentAcademicYear = () => {
    let data = {
      isCurrent: "true",
    };

    axiosPost(URL.getAcademicYears, data, (response) => {
      if (response.status === 200) {
        if (response.data.data && response.data.data.length > 0) {
          this.setState({
            currentAcYr: response.data.data[0],
            currentAcYrId: response.data.data[0].id,
            currentAcYrName: response.data.data[0].name,
          });
        }
      }
    });
  };

  //   getEducationLevels = () => {
  //     axiosPost(URL.getEducationLevel, {}, response => {
  //       this.setState({ educationLevels: response.data.data }, () => {
  //         let modifiedEvents = [...this.state.events];
  //         let newEvents = [];
  //         for (let i = 0; i < modifiedEvents.length - 1; i++) {
  //           let educationLevelNames = "";
  //           this.state.educationLevels.forEach(element => {
  //             if (element.id === modifiedEvents[i].educationLevelId) {
  //               educationLevelNames = educationLevelNames + element.name + " ";
  //             }
  //           });
  //           if (modifiedEvents[i].title !== modifiedEvents[i + 1].title) {
  //             modifiedEvents[
  //               i
  //             ].description = `${modifiedEvents[i].description} For education levels ${educationLevelNames}`;
  //             newEvents.push(modifiedEvents[i]);
  //           }
  //         }
  //         this.setState({ events: newEvents });
  //       });
  //     });
  //   };
  //   componentDidUpdate(prevProps, prevState) {
  //     if (prevProps.calendarEvents !== this.props.calendarEvents) {
  //       this.setState({ events: this.props.calendarEvents }, () => {
  //         this.getEducationLevels();
  //       });
  //     }
  //   }

  handleEventPositioned = (arg) => {
    if (arg.event.extendedProps.description) {
      arg.el.setAttribute("data-tip", arg.event.extendedProps.description);
      ReactTooltip.rebuild();
    }
  };

  renderEvents = (arg) => {
    if (arg.event.start.getMonth() !== arg.view.currentStart.getMonth()) {
      return false;
    }
  };

  //   getEvents = () => {
  //     axiosPost(URL.getEvents, {}, response => {
  //       if (response.status === 200) {
  //         let modifiedEvents = [...response.data.data];
  //         modifiedEvents.forEach((event, id) => {
  //           event.start = event.start.substring(0, event.start.length - 18);
  //           event.end = event.end.substring(0, event.end.length - 18);
  //         });
  //         this.setState({ events: modifiedEvents });
  //       }
  //     });
  //   };

  minimizeCalendarContainer = () => {
    // this.renderNepaliDates();
    this.setState({ minimize: !this.state.minimize });
  };

  // handleChange = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   if (value !== "") {
  //     this.setState({ [name]: value });
  //   } else {
  //     if (name === "calendarDate" && value === "") {
  //       let calendarApi = this.calendarComponentRef.current.getApi();
  //       calendarApi.gotoDate(new Date());
  //       $('.fc-day[data-date="' + this.state.calendarDate + '"]').css(
  //         "background-color",
  //         "white"
  //       );
  //     }
  //     this.setState({ [name]: value });
  //   }
  // };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    this.setState((prevState) => {
      // if (value !== "") {
      //   return { [name]: value };
      // }
      if (name === "calendarDate" && value !== "") {
        let calendarApi = this.calendarComponentRef.current.getApi();
        calendarApi.gotoDate(new Date());
        if (prevState.calendarDate !== "") {
          $('.fc-day[data-date="' + prevState.calendarDate + '"]').css(
            "background-color",
            "white"
          );
        }
      }
      if (value !== "") {
        return { [name]: value };
      }
      if (value === "") {
        if (prevState.calendarDate !== "") {
          $('.fc-day[data-date="' + prevState.calendarDate + '"]').css(
            "background-color",
            "white"
          );
          return { [name]: value };
        }
      }
      // return { [name]: value };
    });
  };

  goToDate = (e) => {
    e.preventDefault();
    if (this.state.calendarDate !== "") {
      let calendarApi = this.calendarComponentRef.current.getApi();
      const todayDate = calendarApi.gotoDate(new Date());

      calendarApi.gotoDate(this.state.calendarDate);

      $('.fc-day[data-date="' + this.state.calendarDate + '"]').css(
        "background-color",
        "rgba(66, 118, 234, 0.3)"
      );
    }
    // else if (){

    // }
    else {
      displayMessage("Error", "Please select date first");
    }
  };

  today = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      if (prevState.calendarDate !== "") {
        $('.fc-day[data-date="' + prevState.calendarDate + '"]').css(
          "background-color",
          "white"
        );
      }

      return { calendarDate: new Date().toISOString().substring(0, 10) };
    });
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.gotoDate(new Date());
  };

  devanagariDigits = {
    '0': '०',
    '1': '१',
    '2': '२',
    '3': '३',
    '4': '४',
    '5': '५',
    '6': '६',
    '7': '७',
    '8': '८',
    '9': '९',
  };

  nepaliMonths = [
    'बैशाख', // April-May
    'जेष्ठ', // May-June
    'असार', // June-July
    'श्रावण', // July-August
    'भाद्र', // August-September
    'आश्विन', // September-October
    'कार्तिक', // October-November
    'मंसिर', // November-December
    'पौष', // December-January
    'माघ', // January-February
    'फाल्गुन', // February-March
    'चैत्र', // March-April
  ];

  renderNepaliDates = () => {
    const dates = document.querySelectorAll('.fc-day');
    // const currentDate = new Date();
    const currentDate = new Date(this.state?.infoDate?.view.currentStart);
    const currentMonth = currentDate?.getMonth();
    const currentYear = currentDate?.getFullYear();

    dates.forEach((date) => {
      const dateStr = date.getAttribute('data-date');
      const nepalTime = new Date(dateStr);
      const formattedDate = moment(nepalTime).format("YYYY-MM-DD");

      // const nepaliDate = moment(adToBs(formattedDate)).format("DD");
      const nepaliDate = adToBs(formattedDate).split("-")[2];

      const nepaliDateDevanagari = nepaliDate.replace(/[0-9]/g, (match) => this.devanagariDigits[match]);
      const existingDiv = date.querySelector('span');
      if (existingDiv) {
        existingDiv.remove();
      }
      const newDiv = document.createElement("span");
      newDiv.style.position = "absolute";
      newDiv.style.bottom = "0";
      newDiv.style.marginLeft = "2px";

      if (nepalTime?.getMonth() !== currentMonth || nepalTime.getFullYear() !== currentYear) {
        newDiv.style.opacity = "0.3";

      }

      newDiv.innerText = nepaliDateDevanagari;
      date.append(newDiv);
    });

    if (this.state.infoDate != null) {
      const currentDate =
        moment(new Date(this.state?.infoDate?.view.currentStart)).format("YYYY-MM-DD");

      const currentDateEnd =

        moment(new Date(this.state?.infoDate?.view.currentEnd)).format("YYYY-MM-DD");

      const nepaliDate = adToBs(currentDate);
      const nepaliDateEnd = adToBs(currentDateEnd);

      const nepaliMonth = this.nepaliMonths[moment(nepaliDate).format("MM") - 1];
      const nepaliMonthEnd = this.nepaliMonths[moment(nepaliDateEnd).format("MM") - 1];
      const nepaliMonthYearNp = `(${nepaliMonth}-${nepaliMonthEnd}, ${moment(nepaliDate).format("YYYY").replace(/[0-9]/g, (match) => this.devanagariDigits[match])})`;

      // // Append the Nepali month and year to the header title
      const h2Header = document.getElementsByTagName('h2');
      const existingSpan = h2Header[0]?.querySelector('span');

      if (existingSpan) {
        existingSpan.remove();
      }

      const newSpan = document.createElement("span");
      newSpan.innerText = nepaliMonthYearNp;
      if (h2Header[0]) {
        h2Header[0].append(newSpan);
      }
    }

    //  insert day values in nepali
    let days = document.querySelectorAll('.fc-day-header.fc-widget-header.fc-sun, .fc-day-header.fc-widget-header.fc-mon, .fc-day-header.fc-widget-header.fc-tue, .fc-day-header.fc-widget-header.fc-wed, .fc-day-header.fc-widget-header.fc-thu, .fc-day-header.fc-widget-header.fc-fri, .fc-day-header.fc-widget-header.fc-sat');
    let nepaliDays = ['आइत', 'सोम', 'मंगल', 'बुध', 'बिही', 'शुक्र', 'शनि'];
    // let secondWeekDays = Array.prototype.slice.call(days, 7);
    days.forEach((day, index) => {
      let nepaliDay = nepaliDays[index % 7];
      let words = day.textContent.split('/');
      let filteredWords = words.filter(word => word !== nepaliDay);
      day.textContent = filteredWords.join('/') + (filteredWords.length > 0 ? '/' : '') + nepaliDay;
    });
  };


  render() {
    return (
      <div
        id="tt-calendarContainer"
        className={
          this.state.minimize
            ? "tt-group-container minimize"
            : "tt-group-container tt-calendar-container"
        }
      >
        <div
          className="tt-group-header"
          onDoubleClick={this.minimizeCalendarContainer}
          data-tip="Double click to expand and collapse this widget"
        >
          Calendar
        </div>
        <div className="tt-calendarBox">
          <FullCalendar
            header={{
              left: "prev",
              center: "title",
              right: "next",
            }}
            validRange={{
              start: this.state.currentAcYr.startDate,
              end: this.state.currentAcYr.endDate,
            }}
            eventLimit={3}
            // showNonCurrentDates={false}
            ref={this.calendarComponentRef}
            defaultView="dayGridMonth"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            weekends={true}
            fullDay={true}
            events={this.filterSameCalendarEvents() || []}
            eventPositioned={this.handleEventPositioned}
            displayEventTime={false}
            eventClick={this.eventClick}
            eventRender={this.renderEvents}
            datesRender={(info) => {
              window.setTimeout(() => {
                this.renderNepaliDates();
                this.setState({ infoDate: info })
                const dates = document.querySelectorAll('.fc-day');

                // const currentNpDate = new Date();
                const currentNpDate = new Date(this.state?.infoDate?.view.currentStart);

                const currentMonth = currentNpDate?.getMonth();
                const currentYear = currentNpDate?.getFullYear();

                dates.forEach((date) => {
                  const dateStr = date.getAttribute('data-date');
                  const nepalTime = new Date(dateStr);

                  const formattedDate = moment(nepalTime).format("YYYY-MM-DD");

                  // const nepaliDate = moment(adToBs(formattedDate)).format("DD");

                  const nepaliDate = adToBs(formattedDate).split("-")[2];
                  const nepaliDateDevanagari = nepaliDate.replace(/[0-9]/g, (match) => this.devanagariDigits[match]);

                  const existingDiv = date.querySelector('span');
                  if (existingDiv) {
                    existingDiv.remove();
                  }

                  const newDiv = document.createElement("span");
                  newDiv.style.position = "absolute";
                  newDiv.style.bottom = "0";
                  newDiv.style.marginLeft = "2px";

                  if (nepalTime?.getMonth() !== currentMonth || nepalTime?.getFullYear() !== currentYear) {
                    newDiv.style.opacity = "0.3";

                  }

                  newDiv.innerText = nepaliDateDevanagari;
                  date.append(newDiv)
                });

                // Get the current date
                const currentDate = moment(new Date(info.view.currentStart)).format("YYYY-MM-DD");
                const currentDateEnd = moment(new Date(info.view.currentEnd)).format("YYYY-MM-DD");
                const nepaliDateHeader = adToBs(currentDate);
                const nepaliDateHeaderNextYear = parseInt(moment(nepaliDateHeader).format("YYYY")) + 1;
                const nepaliDateEndHeader = adToBs(currentDateEnd);
                const nepaliMonthHeader = this.nepaliMonths[moment(nepaliDateHeader).format("MM") - 1];
                // const nepaliMonthHeader = this.nepaliMonths[nepaliDateHeader?.getMonth()];
                const nepaliMonthEndHeader = this.nepaliMonths[moment(nepaliDateEndHeader).format("MM") - 1];
                // const nepaliMonthEndHeader = this.nepaliMonths[nepaliDateEndHeader?.getMonth()];
                const nepaliMonthYearNpHeader = nepaliMonthEndHeader === 'बैशाख' && nepaliMonthHeader === 'चैत्र'
                  ? `(${nepaliMonthHeader}-${nepaliMonthEndHeader}, ${moment(nepaliDateHeader).format("YYYY").replace(/[0-9]/g, (match) => this.devanagariDigits[match])} / ${nepaliDateHeaderNextYear.toString().replace(/[0-9]/g, (match) => this.devanagariDigits[match])})
                `

                  : `(${nepaliMonthHeader}-${nepaliMonthEndHeader}, ${moment(nepaliDateHeader).format("YYYY").replace(/[0-9]/g, (match) => this.devanagariDigits[match])})`;

                // Append the Nepali month and year to the header title
                const h2Header = document.getElementsByTagName('h2');

                const existingSpan = h2Header[0]?.querySelector('span');

                if (existingSpan) {
                  existingSpan.remove();
                }
                const newSpan = document.createElement("span");
                newSpan.innerText = nepaliMonthYearNpHeader;
                if (h2Header[0]) {
                  h2Header[0].append(newSpan);
                }

                let days = document.querySelectorAll('.fc-day-header.fc-widget-header.fc-sun, .fc-day-header.fc-widget-header.fc-mon, .fc-day-header.fc-widget-header.fc-tue, .fc-day-header.fc-widget-header.fc-wed, .fc-day-header.fc-widget-header.fc-thu, .fc-day-header.fc-widget-header.fc-fri, .fc-day-header.fc-widget-header.fc-sat');
                let nepaliDays = ['आइत', 'सोम', 'मंगल', 'बुध', 'बिही', 'शुक्र', 'शनि'];
                // let secondWeekDays = Array.prototype.slice.call(days, 7);
                days.forEach((day, index) => {
                  let nepaliDay = nepaliDays[index % 7];
                  let words = day.textContent.split('/');
                  let filteredWords = words.filter(word => word !== nepaliDay);
                  day.textContent = filteredWords.join('/') + (filteredWords.length > 0 ? '/' : '') + nepaliDay;
                });
              }, 100);
            }}
          />
        </div>
        <div className="tt-group-header" style={{ marginTop: "1rem" }}>
          <div className="row">
            <div className="col calendarGOTODATE">
              <input
                type="date"
                name="calendarDate"
                className="form-control"
                value={this.state.calendarDate}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-auto col-sm-auto mt-1 calendarGOTODATEBUTTON">
              <button
                className="tt-button tt-button-primary mr-2"
                onClick={this.goToDate}
              >
                Go to Date
              </button>
              <button
                className="tt-button tt-button-primary todayCalendar"
                onClick={this.today}
              >
                Today
              </button>
            </div>
          </div>
        </div>
        <ReactTooltip globalEventOff="click"></ReactTooltip>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  calendarEvents: state.academicAdministration.calendarEvents,
});

const mapActionToProps = {
  getCalendarEvents,
};

export default connect(mapStateToProps, mapActionToProps)(CalendarDisplay);
