import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "../../../assets/scss/calendarWidget.scss";
import moment from "moment";
import { checkWidgetAdminOrNot, URL } from "../../../utils/Constants";
import { axiosPost } from "../../../utils/AxiosApi";
import ListAllCalendarEvents from "./ListAllCalendarEvents";
import * as htmlToImage from "html-to-image";
import { Spinner } from "reactstrap";
import { groupBy } from "lodash";
import ModalWindow from "../../UI/ModalWindow";
import GranularPermissionModal from "../../Permissions/GranularPermissionModal";
import { adToBs } from "meropatro-dateconverter";

class DisplayAllCalendar extends Component {
  calendarComponentRef = React.createRef();
  state = {
    widgetEvents: [],
    currentAcYrId: "",
    currentAcYr: "",
    currentAcYrName: "",
    dates: [],
    filteredWidgetEvents: [],
    allCalendarEventTitle: [],
    allHolidayEvents: [],
    spinner: false,
    eventhavingSameName: "",
    permissionModal: false,
    schoolInfos: null,
    downloadClicked: false,
  };

  componentDidMount = () => {
    // this.getWidgetEvents();
    // this.getAllDates();
    this.getUserCurrentAcademicYear();
    this.getWidgetEvents();
    this.getSchoolInfo();
    this.renderNepaliDates();
  };

  componentDidUpdate = () => {
    this.renderNepaliDates();
    if (this.state.downloadClicked) {
      var i;
      let todayBorder = document.getElementsByClassName("fc-day");

      for (i = 0; i < todayBorder.length; i++) {
        todayBorder[i].classList.remove("fc-today");
      }
    }
  }

  getUserCurrentAcademicYear = () => {
    axiosPost(URL.selectUserAcademicYear, {}, (response) => {
      if (response.status === 200) {
        if (response.data.data) {
          this.setState(
            {
              currentAcYr: response.data.data,
              currentAcYrId: response.data.data.academicYearId,
              currentAcYrName: response.data.data.academicYearName,
            },
            function () {
              this.getAcYrDate();
            }
          );
        } else {
          this.getCurrentAcademicYear();
        }
      }
    });
  };
  getAcYrDate = () => {
    let startDates = moment(this.state.currentAcYr.startDate).format(
      "YYYY-MM-DD"
    );
    let x = startDates.split("-");
    let endDates = moment(this.state.currentAcYr.endDate).format("YYYY-MM-DD");
    let y = endDates.split("-");
    let newDates = [...this.state.dates];
    if (parseInt(x[0]) < parseInt(y[0]) || parseInt(x[0]) === parseInt(y[0])) {
      if (parseInt(x[0]) === parseInt(y[0])) {
        if (
          parseInt(x[1]) < parseInt(y[1]) ||
          parseInt(x[1]) === parseInt(y[1])
        ) {
          for (let i = parseInt(x[1]); i <= parseInt(y[1]); i++) {
            if (i < 10) {
              newDates.push(x[0] + "-0" + i.toString() + "-01");
            } else {
              newDates.push(x[0] + "-" + i.toString() + "-01");
            }
          }
        }
      } else {
        for (let i = parseInt(x[0]); i <= parseInt(y[0]); i++) {
          let mx, my;
          if (i === parseInt(x[0])) {
            mx = parseInt(x[1]);
            my = 12;
          } else if (i === parseInt(y[0])) {
            mx = 1;
            my = parseInt(y[1]);
          } else {
            mx = 1;
            my = 12;
          }
          for (let j = mx; j <= my; j++) {
            if (j < 10) {
              newDates.push(i.toString() + "-0" + j.toString() + "-01");
            } else {
              newDates.push(i.toString() + "-" + j.toString() + "-01");
            }
          }
        }
      }
    }
    this.setState({
      dates: newDates,
    });
    // ReactTooltip.rebuild();
  };

  getCurrentAcademicYear = () => {
    let data = {
      isCurrent: "true",
    };
    axiosPost(URL.getAcademicYears, data, (response) => {
      if (response.status === 200) {
        this.setState(
          {
            currentAcYr: response.data.data[0],
            currentAcYrId: response.data.data[0].id,
            currentAcYrName: response.data.data[0].name,
          },
          function () {
            this.getAcYrDate();
          }
        );
      }
    });
  };

  getWidgetEvents = () => {
    axiosPost(URL.getEvents, {}, async (responseWidgets) => {
      if (responseWidgets.status === 200) {
        axiosPost(URL.getWeekDaysByEduDiv, {}, (response) => {
          if (response.status === 200) {
            const weekDays = [0, 1, 2, 3, 4, 5, 6];
            const days = response.data.data.map(({ id }) => id);
            let filteredDays = weekDays.filter((el) => !days.includes(el));
            let anEvent = [];
            anEvent.push({
              daysOfWeek: filteredDays,
              rendering: "background",
              color: `#668ef5`,
              overLap: false,
              allDay: true,
            });

            let newEvents = [...responseWidgets.data.data];
            newEvents.forEach((event) => {
              event.end = new Date(event.end).toISOString();
            });
            this.setState(
              {
                widgetEvents: [...anEvent, ...newEvents],
              },
              () => {
                this.manipulateWidgetEvent();
              }
            );
          }
        });
      }
    });
  };
  //Axios Get request to get values from the database
  getAllDates = () => {
    let newDates = [...this.state.dates];
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        newDates.push("0" + i.toString());
      } else {
        newDates.push(i.toString());
      }
    }
    this.setState({ dates: newDates });
  };

  handleEventPositioned = (arg) => {
    arg.el.setAttribute("data-tip", arg.event.extendedProps.description);
    // ReactTooltip.rebuild();
  };

  closeModal = (e) => {
    this.setState({
      modal: !this.state.modal,
      calendarTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      checked: false,
      selectedEvent: "",
    });
  };

  manipulateWidgetEvent = () => {
    let widgetEventsCopy = [...this.state.widgetEvents];
    const unique = [];
    widgetEventsCopy.map((x) =>
      unique.filter(
        (a) =>
          a.color == x.color &&
          a.title == x.title &&
          a.start == x.start &&
          a.end == x.end
      ).length > 0
        ? null
        : unique.push(x)
    );

    const reqEvent = unique.map((el) => ({
      eventTypeName: el.eventTypeName,
      color: el.color,
      start: el.start,
      end: el.end,
      title: el.title,
      holiday: el.isHoliday,
    }));

    const filteredReqEvent = [];
    reqEvent.map((x) =>
      filteredReqEvent.filter((a) => a.color == x.color).length > 0
        ? null
        : filteredReqEvent.push(x)
    );

    const filterNullValue = filteredReqEvent.filter(
      (el) => el.eventTypeName !== null && el.eventTypeName !== undefined
    );
    let dataWithSameEventName = [];
    unique.forEach((el, id) => {
      filterNullValue.forEach((evnt, id) => {
        if (evnt.eventTypeName === el.eventTypeName) {
          return dataWithSameEventName.push(el);
        }
      });
    });

    const groupByEventTypeName = groupBy(
      dataWithSameEventName,
      (d) => d.eventTypeName
    );

    const allHolidayEvents = unique.filter((el) => el.isHoliday === true);

    this.setState({
      filteredWidgetEvents: unique,
      allCalendarEventTitle: filterNullValue,
      allHolidayEvents: allHolidayEvents,
      eventhavingSameName: groupByEventTypeName,
    });
  };

  handleCalendarEventDownload = async () => {
    var i;
    let todayBorder = document.getElementsByClassName("fc-day");

    for (i = 0; i < todayBorder.length; i++) {
      todayBorder[i].classList.remove("fc-today");
    }

    this.setState({ spinner: true, downloadClicked: true });
    await htmlToImage
      .toJpeg(document.getElementById("printEvent"), {
        quality: 1.0,
        backgroundColor: "white",
      })
      .then((dataUrl) => {
        var link = document.createElement("a");
        link.download = "Calendar.jpeg";
        link.href = dataUrl;
        link.click();
        this.setState({ spinner: false, downloadClicked: false });
      });
  };

  renderNepaliDates = () => {
    const dates = document.querySelectorAll('.fc-day');

    // const currentNpDate = new Date();
    const currentNpDate = new Date(this.state?.infoDate?.view.currentStart);

    const currentMonth = currentNpDate?.getMonth();
    const currentYear = currentNpDate?.getFullYear();

    dates.forEach((date) => {
      const dateStr = date.getAttribute('data-date');
      const nepalTime = new Date(dateStr);

      const formattedDate = moment(nepalTime).format("YYYY-MM-DD");
      // const nepaliDate = moment(adToBs(formattedDate)).format("DD");
      const nepaliDate = adToBs(formattedDate).split("-")[2];;

      const nepaliDateDevanagari = nepaliDate.replace(/[0-9]/g, (match) => this.devanagariDigits[match]);

      const existingDiv = date.querySelector('span');
      if (existingDiv) {
        existingDiv.remove();
      }

      const newDiv = document.createElement("span");
      newDiv.style.position = "absolute";
      newDiv.style.bottom = "0";
      newDiv.style.marginLeft = "2px";

      if (date.classList.contains('fc-other-month')) {
        newDiv.style.opacity = "0.3";
      } else {
        newDiv.style.opacity = "1";
      }


      newDiv.innerText = nepaliDateDevanagari;
      date.append(newDiv)
    });

    if (this.state.infoDate != null) {

      // Get the current date
      const currentDate = moment(new Date(this.state?.infoDate?.view?.currentStart)).format("YYYY-MM-DD");
      const currentDateEnd = moment(new Date(this.state?.infoDate?.view?.currentEnd)).format("YYYY-MM-DD");
      const nepaliDateHeader = adToBs(currentDate);
      const nepaliDateEndHeader = adToBs(currentDateEnd);
      const nepaliMonthHeader = this.nepaliMonths[moment(nepaliDateHeader).format("MM") - 1];
      // const nepaliMonthHeader = this.nepaliMonths[nepaliDateHeader?.getMonth()];
      const nepaliMonthEndHeader = this.nepaliMonths[moment(nepaliDateEndHeader).format("MM") - 1];
      // const nepaliMonthEndHeader = this.nepaliMonths[nepaliDateEndHeader?.getMonth()];
      const nepaliMonthYearNpHeader = `(${nepaliMonthHeader}-${nepaliMonthEndHeader}, ${moment(nepaliDateHeader).format("YYYY").replace(/[0-9]/g, (match) => this.devanagariDigits[match])})`;

      // Append the Nepali month and year to the header title
      const h2Header = document.getElementsByClassName('fc-center');

      // Exclude the first element and map the rest
      const nepaliMonths = Array.prototype.slice.call(h2Header, 1).map((element) => {
        const englishMonthYear = element.innerText;
        const nepaliMonthYear = convertToNepaliMonthYear(englishMonthYear);
        return `(${nepaliMonthYear})`;
      });

      // Function to convert English month and year to Nepali month and year
      function convertToNepaliMonthYear(englishMonthYear) {
        const months = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const nepaliMonths = [
          'पौष', 'माघ', 'फाल्गुन', 'चैत', 'बैशाख', 'जेष्ठ',
          'असार', 'श्रावण', 'भाद्र', 'आश्विन', 'कार्तिक', 'मंसिर'
        ];
        const year = parseInt(englishMonthYear.split(' ')[1]);
        const nepaliYear = year + 57;
        const monthIndex = months.indexOf(englishMonthYear.split(' ')[0]);
        const nepaliMonth = nepaliMonths[monthIndex];
        return `${nepaliMonth}/${nepaliMonths[(monthIndex + 1) % 12]}`;
        // return `${nepaliMonth}/${nepaliMonths[(monthIndex + 1) % 12]} ${nepaliYear}`;
      }

      // Append the Nepali month and year values to the elements
      nepaliMonths.forEach((nepaliMonthYear, index) => {
        const existingSpan = h2Header[index + 1].querySelector('span');
        if (existingSpan) {
          existingSpan.remove();
        }
        const newSpan = document.createElement("span");
        newSpan.innerText = nepaliMonthYear;
        newSpan.style.marginLeft = "0em";
        h2Header[index + 1].append(newSpan);
      });
    }
  }

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolByKey, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          schoolInfos: response.data.data,
        });
      }
    });
  };

  devanagariDigits = {
    '0': '०',
    '1': '१',
    '2': '२',
    '3': '३',
    '4': '४',
    '5': '५',
    '6': '६',
    '7': '७',
    '8': '८',
    '9': '९',
  };

  nepaliMonths = [
    'बैशाख', // April-May
    'जेष्ठ', // May-June
    'असार', // June-July
    'श्रावण', // July-August
    'भाद्र', // August-September
    'आश्विन', // September-October
    'कार्तिक', // October-November
    'मंसिर', // November-December
    'पौष', // December-January
    'माघ', // January-February
    'फाल्गुन', // February-March
    'चैत्र', // March-April
  ];

  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="tt-group-header mt-2">
            All Calendars
            {checkWidgetAdminOrNot("Academic Administration") ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>
          <div className="row mt-2 mb-2">
            <div className="col text-right">
              {this.state.widgetEvents?.length > 0 ? (
                <button
                  disabled={this.state.downloadClicked}
                  className="tt-button tt-button-primary"
                  onClick={() => this.handleCalendarEventDownload()}
                >Download
                </button>
              ) : null}
            </div>
          </div>
          <div className="row" id="printEvent">
            <div
              className="col-md tt-adjustPadding tt-widgetContent-tab-holder"
              style={{ height: "auto" }}
            >
              <div className="tt-adjustPadding">
                <div className="container-fluid row calendar-print-header my-3">
                  <div className="col-md-3 d-flex align-items-center justify-content-center ml-5">
                    <img
                      src={
                        URL.imageSelectURL + this.state.schoolInfos?.imageName
                      }
                      className="img-fluid"
                    />
                  </div>

                  <div className="col-md-5 d-flex align-items-center justify-content-center flex-column ml-3">
                    <h2>{this.state.schoolInfos?.name}</h2>
                    <h5>{this.state.schoolInfos?.address}</h5>
                    <h6>{this.state.currentAcYrName}</h6>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div
                  className="tt-displayDate d-flex"
                  style={{
                    height: "auto",
                    overflowY: "visible",
                    justifyContent: "space-evenly",
                  }}
                >
                  {this.state.dates.map((date, idx) => {
                    return (
                      <div
                        className="tt-widgetContent-tab-holder allCalendarDisplay m-3"
                        style={{ width: "350px" }}
                        key={idx}
                      >
                        <FullCalendar
                          defaultDate={date}
                          header={{ left: "", center: "title", right: "" }}
                          defaultView="dayGridMonth"
                          plugins={[dayGridPlugin]}
                          weekends={true}
                          fullDay={true}
                          columnHeader={false}
                          events={this.state.filteredWidgetEvents || []}
                          validRange={{
                            start: this.state.currentAcYr.startDate,
                            end: this.state.currentAcYr.endDate,
                          }}
                          displayEventTime={false}
                          eventLimit={4}
                          datesRender={(info) => {
                            window.setTimeout(() => {
                              // this.renderNepaliDates();
                              this.setState({ infoDate: info })
                              const dates = document.querySelectorAll('.fc-day');

                              // const currentNpDate = new Date();
                              const currentNpDate = new Date(this.state?.infoDate?.view.currentStart);

                              const currentMonth = currentNpDate?.getMonth();
                              const currentYear = currentNpDate?.getFullYear();

                              dates.forEach((date) => {
                                const dateStr = date.getAttribute('data-date');
                                const nepalTime = new Date(dateStr);

                                const formattedDate = moment(nepalTime).format("YYYY-MM-DD");
                                // const nepaliDate = moment(adToBs(formattedDate)).format("DD");
                                const nepaliDate = adToBs(formattedDate).split("-")[2];

                                const nepaliDateDevanagari = nepaliDate.replace(/[0-9]/g, (match) => this.devanagariDigits[match]);

                                const existingDiv = date.querySelector('span');
                                if (existingDiv) {
                                  existingDiv.remove();
                                }

                                const newDiv = document.createElement("span");
                                newDiv.style.position = "absolute";
                                newDiv.style.bottom = "0";
                                newDiv.style.marginLeft = "2px";

                                if (date.classList.contains('fc-other-month')) {
                                  newDiv.style.opacity = "0.3";
                                } else {
                                  newDiv.style.opacity = "1";
                                }


                                newDiv.innerText = nepaliDateDevanagari;
                                date.append(newDiv)
                              });

                              // Get the current date
                              const currentDate = moment(new Date(info.view.currentStart)).format("YYYY-MM-DD");
                              const currentDateEnd = moment(new Date(info.view.currentEnd)).format("YYYY-MM-DD");
                              const nepaliDateHeader = adToBs(currentDate);
                              const nepaliDateEndHeader = adToBs(currentDateEnd);
                              const nepaliMonthHeader = this.nepaliMonths[moment(nepaliDateHeader).format("MM") - 1];
                              // const nepaliMonthHeader = this.nepaliMonths[nepaliDateHeader?.getMonth()];
                              const nepaliMonthEndHeader = this.nepaliMonths[moment(nepaliDateEndHeader).format("MM") - 1];
                              // const nepaliMonthEndHeader = this.nepaliMonths[nepaliDateEndHeader?.getMonth()];
                              const nepaliMonthYearNpHeader = `(${nepaliMonthHeader}-${nepaliMonthEndHeader}, ${moment(nepaliDateHeader).format("YYYY").replace(/[0-9]/g, (match) => this.devanagariDigits[match])})`;

                              // Append the Nepali month and year to the header title
                              const h2Header = document.getElementsByClassName('fc-center');

                              // Exclude the first element and map the rest
                              const nepaliMonths = Array.prototype.slice.call(h2Header, 1).map((element) => {
                                const englishMonthYear = element.innerText;
                                const nepaliMonthYear = convertToNepaliMonthYear(englishMonthYear);
                                return `(${nepaliMonthYear})`;
                              });

                              // Function to convert English month and year to Nepali month and year
                              function convertToNepaliMonthYear(englishMonthYear) {
                                const months = [
                                  'January', 'February', 'March', 'April', 'May', 'June',
                                  'July', 'August', 'September', 'October', 'November', 'December'
                                ];
                                const nepaliMonths = [
                                  'पौष', 'माघ', 'फाल्गुन', 'चैत', 'बैशाख', 'जेष्ठ',
                                  'असार', 'श्रावण', 'भाद्र', 'आश्विन', 'कार्तिक', 'मंसिर'
                                ];
                                const year = parseInt(englishMonthYear.split(' ')[1]);
                                const nepaliYear = year + 57;
                                const monthIndex = months.indexOf(englishMonthYear.split(' ')[0]);
                                const nepaliMonth = nepaliMonths[monthIndex];
                                return `${nepaliMonth}/${nepaliMonths[(monthIndex + 1) % 12]}`;
                                // return `${nepaliMonth}/${nepaliMonths[(monthIndex + 1) % 12]} ${nepaliYear}`;
                              }

                              // Append the Nepali month and year values to the elements
                              nepaliMonths.forEach((nepaliMonthYear, index) => {
                                const existingSpan = h2Header[index + 1].querySelector('span');
                                if (existingSpan) {
                                  existingSpan.remove();
                                }
                                const newSpan = document.createElement("span");
                                newSpan.innerText = nepaliMonthYear;
                                newSpan.style.marginLeft = "0em";
                                h2Header[index + 1].append(newSpan);
                              });

                            }, 100);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <ListAllCalendarEvents
                events={this.state.filteredWidgetEvents}
                eventsTitle={this.state.allCalendarEventTitle}
                holidayEvents={this.state.allHolidayEvents}
                eventsWithSameEventType={this.state.eventhavingSameName}
              />
            </div>
          </div>
        </div>
        {this.state.spinner ? (
          <div className="fullWindow-Spinner">
            <div>
              <Spinner color="white"></Spinner>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please wait... Downloading Academic Events
            </div>
          </div>
        ) : null}

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Academic Administration"
              moduleName="Calendar"
              header="View All Calendars"
              activityName="display-all-calendar"
            />
          }
        ></ModalWindow>
      </>
    );
  }
}
export default DisplayAllCalendar;
