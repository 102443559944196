import React, { useState } from 'react';
import { Spinner } from 'reactstrap';
import Chip from "@material-ui/core/Chip";

function CreateSymbolNumber(props) {

    return (
        <>

            <>
                {(props?.students && props?.students?.length > 0) &&
                    (
                        <>
                            <table className='table table-bordered table-striped'>
                                <thead className='tt-group-header'>
                                    <tr>
                                        <th>Student Name</th>
                                        <th>Symbol Number</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props?.students?.map((item) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <div className='d-flex flex-column align-items-center justify-content-center'>
                                                        {item?.people?.name}
                                                        <Chip
                                                            size="small"
                                                            color={"default"}
                                                            className="override-chip"
                                                            label={
                                                                `Class ${item?.yearLevel?.name}, ${item?.classRoom?.name}`
                                                            }
                                                        />
                                                        {/* <small className='font-weight-bold'>Class {item?.yearLevel?.name}, {item?.classRoom?.name}</small> */}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <input
                                                            className='form-control'
                                                            type="text"
                                                            placeholder='Enter Symbol Number'
                                                            value={props.symbolVal[item.id] || item.symbolNo || ''}
                                                            onChange={(e) => props?.handleSymbolNoChange(e, item.id)}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <btn
                                                            className="tt-button tt-button-primary"
                                                            onClick={() => props?.handleSave(item.id)}
                                                        >
                                                            {props.studentSave && item.id === props.studentSaveId ? (
                                                                <>
                                                                 <Spinner size ="sm" color="primary"></Spinner>
                                                                </>
                                                            ) : (
                                                                "Save"
                                                            )}
                                                        </btn>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>

                        </>)}
            </>

        </>
    )
}

export default CreateSymbolNumber