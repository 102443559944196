import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { URL } from "../../utils/Constants";
import { axiosPost } from "../../utils/AxiosApi";
import CreateSymbolNumber from "./CreateSymbolNumber";
import { Spinner } from "reactstrap";
import swal from "sweetalert";

const animatedComponents = makeAnimated();

function SymbolNumber() {
  const [isLoading, setIsLoading] = useState(false);
  // for education level
  const [classValue, setClassValue] = useState(null);
  const [educationLevel, setEducationLevel] = useState({});
  const [educationLevelAllData, setEducationLevelAllData] = useState(null);

  // for assessment type
  const [assessmentSubjectValue, setAssessmentSubjectValue] = useState(null);
  const [assessmentAllData, setAssessmentAllData] = useState(null);
  const [assessmentSubjectList, setAssessmentSubjectList] = useState({});

  // for class
  const [classRooms, setClassRooms] = useState(null);
  const [classroomsList, setClassroomsList] = useState([]);

  // for class section
  const [classSections, setClassSections] = useState(null);
  const [classSectionsList, setClassSectionsList] = useState([]);

  // for students
  const [student, setStudent] = useState(null);
  const [studentInfos, setStudentInfos] = useState(null);
  const [studentInfosNew, setStudentInfosNew] = useState(studentInfos);

  // for symbol number
  const [symbolVal, setSymbolVal] = useState("");
  // loading
  const [studentSave, setStudentSave] = useState(false);
  const [studentSaveId, setStudentSaveId] = useState(null);

  const handleClassChange = (value) => {
    setClassValue(value);
    getAssessments(value?.id);
    setAssessmentSubjectValue(null);
    setClassRooms(null);
    setClassSections(null);
  };

  const getAssessments = (param) => {
    let data = {
      educationLevelId: param,
    };
    axiosPost(URL.getAssessmentWithScaleOrComment, data, (response) => {
      if (response.data.status === 200) {
        setAssessmentAllData(response.data.data);
      }
    });
  };

  const handleAssessmentListChange = (value) => {
    setAssessmentSubjectValue(value);
    setClassRooms(null);
    setClassSections(null);
    setStudentInfos(null);
  };

  const getStudentList = (assessId, yearLevelId, classroomId) => {
    setStudentInfosNew(null);
    setStudentInfos(null);
    setIsLoading(true);
    let data = {
      assessmentId: assessId,
      educationLevelId: classValue?.id,
      yearLevelId: yearLevelId ? yearLevelId : null,
      classroomId: classroomId ? classroomId : null,
    };
    axiosPost(
      URL.getStudentWithAssessmentRoutine,
      data,
      (response) => {
        if (response.status === 200) {
          setStudentInfos(response.data.data);
          setIsLoading(false);
        }
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  const handleClassRoomListChange = (value) => {
    setClassRooms(value);
    setClassSections(null);
    if (value?.id) {
      getStudentList(assessmentSubjectValue?.id, value?.id, null);
      getSectionsList(value?.id);
    } else {
      getStudentList(assessmentSubjectValue?.id, null, null);
      setClassSectionsList({});
    }
  };
  const handleClassSectionListChange = (value) => {
    setClassSections(value);
    if (value?.id) {
      getStudentList(assessmentSubjectValue?.id, classRooms?.id, value?.id);
    } else {
      getStudentList(assessmentSubjectValue?.id, classRooms?.id, null);
    }
  };

  useEffect(() => {
    getEducationLevels();
  }, []);

  useEffect(() => {
    let assessmentallDatas = assessmentAllData;
    let selectedData = classValue;
    if (assessmentallDatas && selectedData) {
      let assessmentOptions = assessmentallDatas?.map((item) => ({
        ...item,
        value: item.id,
        label: item.assessmentName,
      }));
      setAssessmentSubjectList(assessmentOptions);
    }
  }, [assessmentAllData]);

  useEffect(() => {
    let allData = educationLevelAllData;
    let selectedData = classValue;
    if (allData && selectedData) {
      let yearLevels = allData.filter((item) => item.id === selectedData.id)[0]
        ?.yearLevels;
      let YearLevelOptions = yearLevels?.map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      }));
      setClassroomsList(YearLevelOptions);
    } else {
      setClassroomsList({});
    }
  }, [classValue]);

  useEffect(() => {
    if (studentInfos) {

      setSymbolVal((prevSymbolVal) => {
        let newSymbolVal = {};
        studentInfos.forEach((student) => {
          newSymbolVal[student.id] = student.symbolNo;
        });
        return newSymbolVal;
      });
    }
  }, [studentInfos]);

  const getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        setEducationLevelAllData(data);
        setEducationLevel(
          data?.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        );
      }
    });
  };

  const handleSymbolNoChange = (e, id) => {
    const { name, value } = e.target;
    let students = studentInfos;
    let changedId = id;
    let changedSymbolNo = students?.filter((item) => item.id === changedId)[0];
    changedSymbolNo.symbolNo = value;
    setStudentInfosNew([...studentInfos, changedSymbolNo]);
    setSymbolVal((prevSymbolVal) => ({ ...prevSymbolVal, [id]: value }));
  };

  const handleSave = (id) => {
    setStudentSaveId(id);
    setStudentSave(true);
    let sympbolStudent = studentInfosNew?.filter((item) => item?.id === id)[0];
    let data = [
      {
        assessmentId: assessmentSubjectValue?.id,
        educationLevelId: classValue?.id,
        yearLevelId: classRooms ? classRooms?.id : null,
        classroomId: sympbolStudent?.classRoom?.id,
        admissionId: id,
        symbolNo: sympbolStudent ? sympbolStudent?.symbolNo : null,
      },
    ];
    axiosPost(URL.insertStudentAssessmentSymbolNumber, data,
      (response) => {
        if (response.status === 200) {
          setStudentSave(false);
          let updatedStudentInfos = studentInfosNew?.map((student) => {
            if (student.id === id) {
              return { ...student, symbolNo: sympbolStudent.symbolNo };
            }
            return student;
          });
          swal({
            title: "Success",
            text: `Student assessment symbol number saved successfully.`,
          });
          setStudentInfosNew(updatedStudentInfos);
          if (updatedStudentInfos) {
            setSymbolVal((prevSymbolVal) => {
              let newSymbolVal = {};
              updatedStudentInfos.forEach((student) => {
                newSymbolVal[student.id] = student.symbolNo;
              });
              return newSymbolVal;
            });
          }
          setStudentInfosNew(studentInfos);
          setSymbolVal((prevSymbolVal) => {
            let newSymbolVal = {};
            studentInfos.forEach((student) => {
              newSymbolVal[student.id] = student.symbolNo;
            });
            return newSymbolVal;
          });
        } else {
          setStudentSave(false);
          swal({
            title: "Error",
            text: `Failed to save student assessment symbol number, Please try again.`,
          });
        }
      },
      (error) => {
        setStudentSave(false);
        if (error.response) {
          swal({
            title: `Error: ${error?.response?.data?.error}`,
            text: `${error?.response?.data?.message}`,
          });
        } else {
          swal({
            title: "Error",
            text: `Failed to save student assessment symbol number. An error occurred.`,
          });
        }
      }
    );
  };

  const handleSaveAll = () => {
    setIsLoading(true);
    let data = studentInfos?.map((item) => ({
      assessmentId: assessmentSubjectValue?.id,
      educationLevelId: classValue?.id,
      yearLevelId: classRooms ? classRooms?.id : null,
      classroomId: item?.classRoom?.id,
      admissionId: item?.id,
      symbolNo: item?.symbolNo,
    }));

    axiosPost(URL.insertStudentAssessmentSymbolNumber, data,
      (response) => {
        if (response.status === 200) {
          setIsLoading(false);
          swal({
            title: "Success",
            text: `All students assessment symbol number saved successfully.`,
          });
          let updatedStudentInfos = studentInfos?.map((student) => {
            return { ...student, symbolNo: student.symbolNo };
          });
          setStudentInfosNew(updatedStudentInfos);
          setSymbolVal((prevSymbolVal) => {
            let newSymbolVal = {};
            updatedStudentInfos.forEach((student) => {
              newSymbolVal[student.id] = student.symbolNo;
            });
            return newSymbolVal;
          });
          getStudentList(assessmentSubjectValue?.id, classRooms?.id, classSections?.id)
        } else {
          setIsLoading(false);
          swal({
            title: "Error",
            text: `Failed to save all students assessment symbol number. Please try again.`,
          });
        }
      },
      (error) => {
        setIsLoading(false);
        if (error.response) {
          swal({
            title: `Error: ${error?.response?.data?.error}`,
            text: `${error?.response?.data?.message}`,
          });
        } else {
          swal({
            title: "Error",
            text: `Failed to save all students assessment symbol number. An error occurred.`,
          });
        }
      }
    );
  };

  const getSectionsList = (value) => {
    let data = {
      yearLevelId: value,
    };
    axiosPost(URL.selectClassroom, data, (response) => {
      if (response.status === 200) {
        let sectionOptions = response?.data?.data?.map((item) => ({
          ...item,
          value: item?.id,
          label: item?.name,
        }));
        setClassSectionsList(sectionOptions);
      }
    });
  };

  return (
    <div
      className="card-setup-container tt-widgetContent-tab-holder"
      style={{ height: "79.5vh" }}
    >
      <div className="row">
        <div className="col-md-12 col-12">
          <div className="tt-group-header">Configure Symbol Number</div>
          <div className="tt-group-body">
            <div className="d-flex justify-content-between">
              <div className="w-100 mr-1">
                <strong>Education Level:</strong>
                <Select
                  className="w-100 mr-1"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={classValue}
                  options={educationLevel}
                  name="class"
                  onChange={handleClassChange}
                  placeholder="Select Level"
                  isClearable
                />
              </div>
              <div className="w-100 mx-1">
                <strong>Assessment:</strong>
                <Select
                  isDisabled={!!!classValue}
                  className="w-100"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={assessmentSubjectValue}
                  options={assessmentSubjectList}
                  name="assessmentList"
                  onChange={handleAssessmentListChange}
                  placeholder="Select Assessment"
                  isClearable
                />
              </div>
              <div className="w-100 ml-1">
                <strong>Class</strong>
                <Select
                  isDisabled={!!!assessmentSubjectValue}
                  className="w-100"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={classRooms}
                  options={classroomsList}
                  name="classRooms"
                  onChange={handleClassRoomListChange}
                  placeholder="Select Class"
                  isClearable
                />
              </div>
              <div className="w-100 ml-1">
                <strong>Section</strong>
                <Select
                  isDisabled={!!!classRooms}
                  className="w-100"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={classSections}
                  options={classSectionsList}
                  name="section"
                  onChange={handleClassSectionListChange}
                  placeholder="Select Section"
                  isClearable
                />
              </div>
            </div>
            {isLoading ? (
              <div className="mt-4 d-flex align-items-center justify-content-center ">
                {" "}
                <Spinner color="primary"></Spinner>{" "}
              </div>
            ) : !!classValue &&
              !!assessmentSubjectValue &&
              !!classRooms &&
              studentInfos?.length > 0 ? (
              <div className="mt-1 d-flex flex-column align-items-center justify-content-center">
                <CreateSymbolNumber
                  isLoading={isLoading}
                  students={studentInfos}
                  symbolVal={symbolVal}
                  studentSave={studentSave}
                  handleSave={handleSave}
                  handleSymbolNoChange={handleSymbolNoChange}
                  studentSaveId={studentSaveId}
                />
                <div className="mt-1 d-flex align-items-center justify-content-center sticky-bottom w-100 py-1">
                  <btn
                    onClick={() => handleSaveAll()}
                    className="tt-button tt-button-primary"
                  >
                    Save all
                  </btn>
                </div>
              </div>
            ) : (
              <>
                {!classValue ? (
                  <div className="mt-4 d-flex align-items-center justify-content-center ">
                    Select the above field to view the records.
                  </div>
                ) : (
                  <div className="mt-4 d-flex align-items-center justify-content-center">
                    No Records Found
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SymbolNumber;
