import React, { Component } from "react";
import { axiosPost } from "../../utils/AxiosApi";
import { checkWidgetAdminOrNot, URL, userRole, userRoleCode } from "../../utils/Constants";
import ModalWindow from "../UI/ModalWindow";

import "../../assets/scss/ListClassRoutine.scss";
import { groupBy, orderBy } from "lodash";
import swal from "sweetalert";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment";

import ClassRoutineFormat from "./ClassRoutineFormat";
import GranularPermissionModal from "../Permissions/GranularPermissionModal";
import { compareByName } from "../../utils/Utils";

class ListClassRoutine extends Component {
  state = {
    mergeData: "",

    weekDays: [],
    educationLevels: [],
    selectedEducationLevelId: "",

    yearLevels: [],
    selectedYearLevel: "",

    classrooms: [],
    selectedClassrooms: "",

    classRoutineUpdateModal: false,

    routine1: [],
    subjects: [],

    teachers: [],
    insertedRoutine: "",
    render: false,
    permissionModal: false,
    schoolInfos: null,
    selectedEducationLevelName: "",
    selectedYearLevelName: "",
    selectedClassroomName: "",
    peopleId: localStorage.getItem("peopleId"),
    studentList: [],
    selectedStudent: null
  };
  componentDidMount = () => {
    this.getSchoolInfo();
    if (userRole.toString() == userRoleCode.roleStudent) {
      this.getStudentDetail();
    } else if (userRole.toString() == userRoleCode.roleStudentContact) {
      this.getStudentByContactPeople();
    }
    else {
      // Need to getTeachers not understood here, commented as no difference seen current in list page
      // explanation by bigyan  : this api fetches teachers list for class routine update interface.
      this.getTeachers();
      this.getEducationLevels();
    }
  };

  getSchoolInfo = () => {
    axiosPost(URL.getSchoolByKey, {}, (response) => {
      if (response.status === 200) {
        this.setState({
          schoolInfos: response.data.data,
        });
      }
    });
  };
  getStudentDetail = (peopleId) => {
    let param = {
      peopleId: peopleId || this.state.peopleId
    }
    axiosPost(URL.getStudentDetail, param, (response) => {
      if (response.status === 200) {
        let data = response.data.data;
        this.setState({
          selectedEducationLevelId: data?.educationLevel?.id,
          selectedYearLevel: data?.yearLevel?.id,
          selectedClassrooms: data?.classroom?.id,
          selectedEducationLevelName: data?.educationLevel?.name,
          selectedYearLevelName: data?.yearLevel?.name,
          selectedClassroomName: data?.classroom?.name
        }, function () {
          this.getWeekdays();
          this.getInsertedRoutine();

        });
      }
    });

  }

  getStudentByContactPeople = () => {
    let param = {
      contactPeopleId: this.state.peopleId
    }
    axiosPost(URL.getStudentByContactPeople, param, (response) => {
      if (response.data.status == 200) {
        let data = response.data.data;
        this.setState({ studentList: data })
      }
    })
  }

  getTeachers = () => {
    axiosPost(URL.getSubjectTeachers, {}, (response) => {
      if (response.status == 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            teachers: data,
          });
      }
    });
  };

  // getInsertedRoutine = () => {
  //   this.setState({ routine1: [] }, () => {
  //     let filter = {
  //       educationLevelId: this.state.selectedEducationLevelId,
  //       yearlevelId: this.state.selectedYearLevel,
  //       classroomId: this.state.selectedClassrooms,
  //     };
  //     axiosPost(URL.getClassRoutine, filter, (response) => {
  //       if (response.data.status === 200) {
  //         let data = [...response.data.data];
  //         this.setState({ mergeData: data });
  //         data = data.map((d) => {
  //           d.columnIndex = d.indexKey.split(",")[1];
  //           return d;
  //         });

  //         const groupByRoutine = groupBy(data, (d) => d.indexKey.split(",")[0]);
  //         for (const property in groupByRoutine) {
  //           groupByRoutine[property] = orderBy(
  //             groupByRoutine[property],
  //             ["columnIndex"],
  //             ["asc"]
  //           );
  //         }

  //         let output = [];
  //         Object.keys(groupByRoutine).forEach((key, id) => {
  //           let newOutput = groupByRoutine[key].reduce(function (o, cur) {
  //             // Get the index of the key-value pair.
  //             let occurs = o.reduce(function (n, item, i) {
  //               return item.indexKey === cur.indexKey ? i : n;
  //             }, -1);

  //             // If the name is found,

  //             if (occurs >= 0) {
  //               let subjectName = [];
  //               if (cur.subjectName) {
  //                 subjectName = [
  //                   {
  //                     aliasName: cur.aliasName,
  //                     name: cur.subjectName,
  //                     hasAliasName: cur.aliasName ? true : false,
  //                   },
  //                 ];
  //               }
  //               // append the current value to its list of values. if same peopleid is found then donot add the peopleId again.
  //               if (!(o[occurs].peopleId.some((el) => el == cur.peopleId))) {
  //                 o[occurs].peopleName = o[occurs].peopleName.concat(
  //                   cur.peopleName
  //                 );
  //               }
  //               o[occurs].subjectName =
  //                 o[occurs].subjectName.concat(subjectName);
  //               o[occurs].subjectId = o[occurs].subjectId.concat(cur.subjectId);
  //               o[occurs].peopleId = o[occurs].peopleId.concat(cur.peopleId);
  //               o[occurs].selectedSubjectValue = [];

  //               if (o[occurs].subjectId.length > 0) {
  //                 o[occurs].subjectId.forEach((el, idx) => {
  //                   let data = {
  //                     value: el,
  //                     label: o[occurs].subjectName[idx]?.name,
  //                     hasAliasName: o[occurs].subjectName[idx]?.hasAliasName,
  //                     aliasName: o[occurs].subjectName[idx]?.aliasName,
  //                   };
  //                   o[occurs].selectedSubjectValue.push(data);
  //                 });
  //               }

  //               o[occurs].selectedPeopleValue = [];
  //               if (o[occurs].peopleId.length > 0) {
  //                 o[occurs].peopleId.forEach((el, idx) => {
  //                   let data = {
  //                     value: el,
  //                     label: o[occurs].peopleName[idx],
  //                   };
  //                   o[occurs].selectedPeopleValue.push(data);
  //                 });
  //               }

  //               // Otherwise,
  //             } else {
  //               // add the current item to o (but make sure the value is an array).
  //               let subjectName = [];
  //               if (cur.subjectName) {
  //                 subjectName = [
  //                   {
  //                     aliasName: cur.aliasName,
  //                     name: cur.subjectName,
  //                     hasAliasName: cur.aliasName ? true : false,
  //                   },
  //                 ];
  //               }

  //               var obj = {
  //                 indexKey: cur.indexKey,
  //                 peopleName: [cur.peopleName],
  //                 subjectName: subjectName,
  //                 peopleId: cur.peopleId ? [cur.peopleId] : [],
  //                 subjectId: cur.subjectId ? [cur.subjectId] : [],
  //                 selectedSubjectValue: [],
  //                 selectedPeopleValue: [],
  //               };

  //               if (obj.subjectId.length > 0) {
  //                 obj.subjectId.forEach((el, idx) => {
  //                   let data = {
  //                     value: el,
  //                     label: obj.subjectName[idx].name,
  //                     hasAliasName: obj.subjectName[idx].hasAliasName,
  //                     aliasName: obj.subjectName[idx].aliasName,
  //                   };
  //                   obj.selectedSubjectValue.push(data);
  //                 });
  //               }
  //               if (obj.peopleId.length > 0) {
  //                 obj.peopleId.forEach((el, idx) => {
  //                   let data = {
  //                     value: el,
  //                     label: obj.peopleName[idx],
  //                   };
  //                   obj.selectedPeopleValue.push(data);
  //                 });
  //               }

  //               var finalObj = {
  //                 ...cur,
  //                 ...obj,
  //               };

  //               o = o.concat([finalObj]);
  //             }

  //             return o;
  //             // this.setState({ finalRoutinee: routine });
  //           }, []);
  //           output.push(newOutput);
  //         });

  //         var routine = Object.assign({}, output);
  //         this.setState({ routine1: routine, render: true });
  //         // localStorage.setItem("routine", JSON.stringify(routine));
  //       }
  //     });
  //   });
  // };

  getInsertedRoutine = () => {
    this.setState({ routine1: [] }, () => {
      let filter = {
        educationLevelId: this.state.selectedEducationLevelId,
        yearlevelId: this.state.selectedYearLevel,
        classroomId: this.state.selectedClassrooms,
      };
      axiosPost(URL.getClassRoutine, filter, (response) => {
        if (response.data.status === 200) {
          let data = [...response.data.data];
          this.setState({ mergeData: data });
          data = data.map((d) => {
            d.columnIndex = d.indexKey.split(",")[1];
            return d;
          });
  
          const groupByRoutine = groupBy(data, (d) => d.indexKey.split(",")[0]);
          for (const property in groupByRoutine) {
            groupByRoutine[property] = orderBy(
              groupByRoutine[property],
              ["columnIndex"],
              ["asc"]
            );
          }
  
          let output = [];
          Object.keys(groupByRoutine).forEach((key, id) => {
            let newOutput = groupByRoutine[key].reduce((o, cur) => {
              // Get the index of the key-value pair.
              let occurs = o.findIndex((item) => item.indexKey === cur.indexKey);
  
              // If the name is found,
  
              if (occurs >= 0) {
                let subjectName = [];
                if (cur.subjectName) {
                  subjectName = [
                    {
                      aliasName: cur.aliasName,
                      name: cur.subjectName,
                      hasAliasName: cur.aliasName ? true : false,
                    },
                  ];
                }
                // append the current value to its list of values. if same peopleid is found then donot add the peopleId again.
                if (!o[occurs].peopleId.includes(cur.peopleId)) {
                  o[occurs].peopleName = o[occurs].peopleName.concat(
                    cur.peopleName
                  );
                }
                o[occurs].subjectName =
                  o[occurs].subjectName.concat(subjectName);
                o[occurs].subjectId = o[occurs].subjectId.concat(cur.subjectId);
                o[occurs].peopleId = o[occurs].peopleId.concat(cur.peopleId);
                o[occurs].selectedSubjectValue = [];
  
                if (o[occurs].subjectId.length > 0) {
                  o[occurs].subjectId.forEach((el, idx) => {
                    let data = {
                      value: el,
                      label: o[occurs].subjectName[idx]?.name,
                      hasAliasName: o[occurs].subjectName[idx]?.hasAliasName,
                      aliasName: o[occurs].subjectName[idx]?.aliasName,
                    };
                    o[occurs].selectedSubjectValue.push(data);
                  });
                }
  
                // Filter out undefined labels from selectedPeopleValue
                o[occurs].selectedPeopleValue = o[occurs].peopleId
                  .map((peopleId, idx) => {
                    return {
                      value: peopleId,
                      label: o[occurs].peopleName[idx],
                    };
                  })
                  .filter((person) => person.label !== undefined);
  
                // Otherwise,
              } else {
                // add the current item to o (but make sure the value is an array).
                let subjectName = [];
                if (cur.subjectName) {
                  subjectName = [
                    {
                      aliasName: cur.aliasName,
                      name: cur.subjectName,
                      hasAliasName: cur.aliasName ? true : false,
                    },
                  ];
                }
  
                let obj = {
                  indexKey: cur.indexKey,
                  peopleName: [cur.peopleName],
                  subjectName: subjectName,
                  peopleId: cur.peopleId ? [cur.peopleId] : [],
                  subjectId: cur.subjectId ? [cur.subjectId] : [],
                  selectedSubjectValue: [],
                  selectedPeopleValue: [],
                };
  
                if (obj.subjectId.length > 0) {
                  obj.subjectId.forEach((el, idx) => {
                    let data = {
                      value: el,
                      label: obj.subjectName[idx].name,
                      hasAliasName: obj.subjectName[idx].hasAliasName,
                      aliasName: obj.subjectName[idx].aliasName,
                    };
                    obj.selectedSubjectValue.push(data);
                  });
                }
  
                // Filter out undefined labels from selectedPeopleValue
                obj.selectedPeopleValue = obj.peopleId
                  .map((peopleId, idx) => {
                    return {
                      value: peopleId,
                      label: obj.peopleName[idx],
                    };
                  })
                  .filter((person) => person.label !== undefined);
  
                let finalObj = {
                  ...cur,
                  ...obj,
                };
  
                o.push(finalObj);
              }
  
              return o;
              // this.setState({ finalRoutinee: routine });
            }, []);
            output.push(newOutput);
          });
  
          let routine = Object.assign({}, output);
          this.setState({ routine1: routine, render: true });
          // localStorage.setItem("routine", JSON.stringify(routine));
        }
      });
    });
  };

  
  getWeekdays = () => {
    axiosPost(
      URL.getWeekDaysByEducationLevelDivision,
      { educationLevelId: this.state.selectedEducationLevelId },
      (response) => {
        if (response.data.status === 200) {
          const data = response.data.data;
          const sort = data.sort((a, b) => a.id - b.id);
          if (data.length > 0)
            this.setState({
              weekDays: sort,
            });
        }
      }
    );
  };

  getEducationLevels = () => {
    axiosPost(URL.getEducationLevel, {}, (response) => {
      if (response.data.status === 200) {
        const data = response.data.data;
        if (data.length > 0)
          this.setState({
            educationLevels: data,
          });
      }
    });
  };

  handleStudentChange = (e) => {
    let studentPeopleId = e.target.value;
    this.setState({ selectedStudent: studentPeopleId })
    this.getStudentDetail(studentPeopleId);

  }

  handleEducationLevelChange = (e) => {
    this.setState({ selectedEducationLevelId: e.target.value }, () => {
      this.getWeekdays();
    });

    this.state.educationLevels.map((datum) => {
      if (datum.id == e.target.value) {
        this.setState({
          yearLevels: datum.yearLevels,
          classrooms: [],
          selectedClassrooms: "",
          subjects: [],
          selectedSubjects: "",
          selectedYearLevel: "",
          routine1: "",
          render: false,
        });
      }
    });
  };

  handleYearLevel = (e) => {
    this.setState(
      {
        selectedYearLevel: e.target.value,
        selectedClassrooms: "",
      },
      function () {
        var data = {
          yearLevelId: this.state.selectedYearLevel,
        };
        axiosPost(URL.selectClassroom, data, (response) => {
          if (response.status === 200) {
            const datas = response.data.data;
            if (datas.length > 0) {
              this.setState({
                classrooms: datas,
                selectedClassrooms: "",
                subjects: [],
                selectedSubjects: "",
              });
            }
          }
        });

        axiosPost(URL.getSubjectForPlanner, data, (response) => {
          if (response.data.status === 200) {
            const data = response.data.data;
            if (data.length > 0)
              this.setState({
                subjects: data
              });
          }
        });
      }
    );
  };

  handleSectionChange = (e) => {
    this.setState({ selectedClassrooms: e.target.value }, () => {
      this.getInsertedRoutine();
      // this.handleClassRoutineDelete();
    });
    // var data = {
    //   classroomId: e.target.value,
    // };

    // axiosPost(URL.getSubjectForPlanner, data, (response) => {
    //   if (response.data.status === 200) {
    //     const data = response.data.data;
    //     if (data.length > 0)
    //       this.setState({
    //         subjects: data,
    //         selectedSubjects: data[0].id,
    //         render: true,
    //       });
    //   }
    // });

  };
  handleClassRoutineUpdateModal = (e) => {
    this.setState({
      classRoutineUpdateModal: !this.state.classRoutineUpdateModal,
    });
  };

  handleClassRoutineDelete = () => {
    let data = {
      educationLevelId: this.state.selectedEducationLevelId,
      yearlevelId: this.state.selectedYearLevel,
      classroomId: this.state.selectedClassrooms,
    };

    swal({
      title: "Delete Confirmation",
      text: "Are you sure you want to delete this class routine?",
      buttons: true,
      dangerMode: true,
    }).then((confirm) => {
      if (confirm) {
        let data = {
          educationLevelId: this.state.selectedEducationLevelId,
          yearlevelId: this.state.selectedYearLevel,
          classroomId: this.state.selectedClassrooms,
        };
        axiosPost(URL.deleteClassRoutine, data, (response) => {
          if (response.status === 200) {
            swal({ title: "Success", text: "Class routine successfully deleted." });
            this.setState({
              routine1: [],
            });
          }
        });
        localStorage.removeItem("routine");
      }
    });
  };

  handleClassRoutineDownload = async () => {
    // document.getElementById("routine-details").style.display = "block";
    document.getElementById("printRoutine").parentNode.style.overflow =
      "visible";
    const element = document.getElementById("printRoutine");
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    // pdf.addImage(data, "PNG", 0, 0);
    pdf.save("classRoutine.pdf");
    // document.getElementById("routine-details").style.display = "none";
  };

  renderWeekDayPeople = (el) => {
    // return this.state.weekDays.map((wD) => {
    //   return this.state.routine1[el].map((rout, idx) => {
    //     return wD.id === rout.weekdayId ? (
    //       <td width="200px" style={{ verticalAlign: "middle" }}>
    //         <strong>
    //           {rout.subjectName.length > 0
    //             ? rout.subjectName.map((sub, idx) => {
    //                 if (idx == rout?.subjectName.length - 1) {
    //                   if (sub.aliasName) {
    //                     return sub.aliasName;
    //                   } else {
    //                     return sub.name;
    //                   }
    //                 } else {
    //                   return `${sub.aliasName ? sub.aliasName : sub.name} / `;
    //                 }
    //               })
    //             : "-"}
    //         </strong>
    //         {rout.peopleId.length > 0 ? (
    //           <>
    //             <br />(
    //             {rout.peopleName.map((name, idx) => {
    //               if (idx == rout?.peopleName.length - 1) {
    //                 return name;
    //               } else {
    //                 return `${name} / `;
    //               }
    //             })}
    //             )
    //           </>
    //         ) : null}
    //       </td>
    //     ) : null;
    //   });
    // });
    return this.state.weekDays.map((wD) => {
      const routinesForWeekday = this.state.routine1[el].filter(
        (rout) => rout.weekdayId === wD.id
      );
      
      if (routinesForWeekday?.length > 0) {
        return (
          <td key={wD.id} width="200px" style={{ verticalAlign: "middle" }}>
            <strong>
              {routinesForWeekday[0]?.subjectName?.length > 0
                ? routinesForWeekday[0].subjectName.map((sub, idx) => {
                  if (idx === routinesForWeekday[0]?.subjectName.length - 1) {
                    if (sub.aliasName) {
                      return sub.aliasName;
                    } else {
                      return sub.name;
                    }
                  } else {
                    return `${sub.aliasName ? sub.aliasName : sub.name} / `;
                  }
                })
                : "-"}
            </strong>
            {routinesForWeekday[0]?.peopleId?.length > 0 ? (
              <>
                <br />(
                {routinesForWeekday[0]?.peopleName?.map((name, idx) => {
                  if (idx === routinesForWeekday[0]?.peopleName.length - 1) {
                    // return name;
                    return (name === "" || name == undefined) ?  "-":name;
                  } else {
                    // return `${name} / `;
                    return (name === "" || name == undefined) ? "":`${name} / `;
                  }
                })}
                )
              </>
            ) : null}
          </td>
        );
      } else {
        // If there are no routines for this weekday, render an empty <td>
        return (
          <td key={wD.id} width="200px" style={{ verticalAlign: "middle" }}>
            -
          </td>
        );
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="tt-widgetContent-tab-holder"
          style={{ height: "79.5vh" }}
        >
          <div className="tt-group-header">
            List Class Routine
            {checkWidgetAdminOrNot("Class Routine") ? (
              <button
                className="tt-button tt-button-primary float-right permissionBtnCSS"
                onClick={() => {
                  this.setState({
                    permissionModal: !this.state.permissionModal,
                  });
                }}
              >
                Permissions
              </button>
            ) : null}
          </div>

          <div
            className="tt-newListBox mb-1"
            style={{ height: "60.5vh", overflowX: "hidden", overflowY: "auto" }}
          >
            <div className="container-fluid">
              {/* Filter begins here */}
              {userRole.toString() == userRoleCode.roleStudentContact ?
                <div className="row">
                  <div className="col-4">
                    <strong>Students</strong>
                    <select
                      className="form-control"
                      name="educationLevel"
                      value={this.state.selectedStudent}
                      onChange={this.handleStudentChange}
                    >
                      <option value="" selected disabled>
                        Select student
                      </option>
                      {this.state.studentList.map((sl, idx) => {
                        return <option value={sl.id}>{sl.name}</option>;
                      })}
                    </select>
                  </div>
                </div> : null
              }
              {userRole.toString() !== userRoleCode.roleStudent && userRole.toString() !== userRoleCode.roleStudentContact ? (
                <div className="row">
                  <div className="col-4 col-md">
                    <strong>Education Level</strong>
                    <select
                      className="form-control"
                      name="educationLevel"
                      value={this.state.selectedEducationLevelId}
                      onChange={this.handleEducationLevelChange}
                    >
                      <option value="" selected disabled>
                        Select education level
                      </option>
                      {this.state.educationLevels.map((sl, idx) => {
                        return <option value={sl.id}>{sl.name}</option>;
                      })}
                    </select>
                  </div>

                  <div className="col-4 col-md">
                    <strong>Class</strong>
                    <select
                      className="form-control"
                      name="yearLevel"
                      value={this.state.selectedYearLevel}
                      onChange={this.handleYearLevel}
                      disabled={this.state.selectedEducationLevelId === ""}
                    >
                      <option value="" selected disabled>
                        Select class
                      </option>
                      {this.state.yearLevels
                        // .sort(compareByName)
                        .map((sl, idx) => {
                          return <option value={sl.id}>Class {sl.name}</option>;
                        })}
                    </select>
                  </div>

                  <div className="col-4 col-md">
                    <strong>Section</strong>
                    <select
                      className="form-control"
                      name="sectionId"
                      value={this.state.selectedClassrooms}
                      onChange={this.handleSectionChange}
                      disabled={this.state.selectedYearLevel === ""}
                    >
                      <option value="" selected disabled>
                        Select section
                      </option>
                      {this.state.classrooms.map((el, idx) => {
                        return <option value={el.id}>{el.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
              ) : null}

              {/* Filter ends here */}
              {this.state.render ? (
                <div className="row mt-2" id="printRoutine">
                  <div className="container-fluid row  my-3 d-flex justify-content-center">
                    {/* <div className="col-md-4">
                      <img
                        src={
                          URL.imageSelectURL + this.state.schoolInfos?.imageName
                        }
                        className="img-fluid"
                      />
                    </div> */}

                    <div className="col-md-4 text-center">
                      <h2>{this.state.schoolInfos?.name}</h2>
                      <h5>{this.state.schoolInfos?.address}</h5>
                      <h6>{this.state.currentAcYrName}</h6>
                    </div>
                    {/* <div className="col-md-4"></div> */}
                  </div>

                  <div
                    className="col-12"
                    id="routine-details"
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <b>Education Level: </b>
                        {userRole.toString() == userRoleCode.roleStudent || userRole.toString() == userRoleCode.roleStudentContact ?
                          (<span>{this.state.selectedEducationLevelName}
                          </span>)
                          :
                          this.state.educationLevels.map(
                            (el) =>
                              el.id == this.state.selectedEducationLevelId && (
                                <span>{el.name}</span>
                              )
                          )}


                      </div>

                      <div className="col-md-4">
                        <b>Class: </b>
                        {userRole.toString() == userRoleCode.roleStudent || userRole.toString() == userRoleCode.roleStudentContact ?
                          (<span>{this.state.selectedYearLevelName}
                          </span>) :
                          this.state.yearLevels.map(
                            (el) =>
                              el.id == this.state.selectedYearLevel && (
                                <span>{el.name}</span>
                              )
                          )}
                      </div>

                      <div className="col-md-4">
                        <b>Section: </b>
                        {userRole.toString() == userRoleCode.roleStudent || userRole.toString() == userRoleCode.roleStudentContact ?
                          (<span>{this.state.selectedClassroomName}
                          </span>) :
                          this.state.classrooms.map(
                            (el) =>
                              el.id == this.state.selectedClassrooms && (
                                <span>{el.name}</span>
                              )
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <table className="table table-bordered text-center mt-3">
                      <thead className="tt-group-header">
                        <tr>
                          <th width="180px"></th>
                          {this.state.weekDays.map((weeks, id) => {
                            return <th key={id}>{weeks.name}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(this.state.routine1).length > 0 ? (
                          Object.keys(this.state.routine1).map((el, id) => {
                            return (
                              <>
                                {this.state.routine1[el].map((r, ridx) => {
                                  return (
                                    ridx === 0 && (
                                      <tr>
                                        <td
                                          className="tableData"
                                          style={{ verticalAlign: "middle" }}
                                        >
                                          <strong>
                                            {moment(r?.startDateTime).format(
                                              "LT"
                                            )}
                                          </strong>
                                          {` - `}
                                          <strong>
                                            {moment(r?.endDateTime).format(
                                              "LT"
                                            )}
                                          </strong>
                                        </td>
                                        {r?.isBreak ? (
                                          <td
                                            colspan={this.state.weekDays.length}
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <strong
                                              className="text-center"
                                              style={{
                                                justifyContent: "center",
                                                textAlign: "center",
                                                alignItems: "center",
                                                display: "flex",
                                              }}
                                            >
                                              {r?.breakType}
                                            </strong>
                                          </td>
                                        ) : (
                                          this.renderWeekDayPeople(el)
                                        )}
                                      </tr>
                                    )
                                  );
                                })}
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="text-center py-3">
                  {
                    userRole.toString() == userRoleCode.roleStudentContact ? (`Please select student first.`) :
                      (`Select all the fields for education level, class and section
                  to view routine data.`)
                  }
                </div>
              )}
            </div>
          </div>
          {Object.keys(this.state.routine1).length > 0 ? (
            <div className="text-center mt-3">
              {userRole.toString() !== userRoleCode.roleStudent && userRole.toString() !== userRoleCode.roleStudentContact ? (
                <button
                  className="tt-button tt-button-primary mr-2"
                  onClick={(e) => this.handleClassRoutineUpdateModal(e)}
                >
                  Update
                </button>
              ) : null}

              {userRole.toString() !== userRoleCode.roleStudent && userRole.toString() !== userRoleCode.roleStudentContact ? (
                <button
                  className="tt-button tt-button-primary mr-2"
                  onClick={() => this.handleClassRoutineDelete()}
                >
                  Delete
                </button>
              ) : null}
              <button
                className="tt-button tt-button-primary"
                onClick={() => this.handleClassRoutineDownload()}
              >
                Download
              </button>
            </div>
          ) : null}
        </div>

        <ModalWindow
          modal={this.state.classRoutineUpdateModal}
          toggleModal={this.handleClassRoutineUpdateModal}
          modalHeader={"Update Class Routine"}
          size="xl"
          fullWidth={true}
          modalBody={
            <ClassRoutineFormat
              condition="update"
              //dont know why the props key was set educationLevelId instead of selectedEducationLevelId, therefore added the selected value here
              educationLevelId={this.state.selectedEducationLevelId}
              selectedEducationLevelId={this.state.selectedEducationLevelId}
              yearlevelId={this.state.selectedYearLevel}
              classroomId={this.state.selectedClassrooms}
              routine1={this.state.routine1}
              teachers={this.state.teachers}
              subjects={this.state.subjects}
              getInsertedRoutine={this.getInsertedRoutine}
              handleClassRoutineUpdateModal={this.handleClassRoutineUpdateModal}
            ></ClassRoutineFormat>
          }
        ></ModalWindow>

        <ModalWindow
          modal={this.state.permissionModal}
          size="lg"
          id="tt-permissionModal"
          toggleModal={() => {
            this.setState({
              permissionModal: !this.state.permissionModal,
            });
          }}
          modalHeader={"Assign permission to user"}
          modalBody={
            <GranularPermissionModal
              widgetName="Class Routine"
              moduleName="Class Routine"
              header="List Class Routine configuration"
              activityName="list-class-routine"
            />
          }
        ></ModalWindow>
      </React.Fragment>
    );
  }
}

export default ListClassRoutine;
