import { ad2bs } from "ad-bs-converter";
import moment from "moment";
import React, { Component } from "react";
const { adToBs } = require('@sbmdkl/nepali-date-converter');

class NepaliDateViewer extends Component {
  state = {};

  componentDidMount() {
    this.setNepaliDateView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.customTableField.day !== this.props.customTableField?.day ||
      prevProps.customTableField.month !== this.props.customTableField?.month
    ) {
      this.setNepaliDateView();
    }
    // if (prevState.customTableField.day !== this.state.customTableField.day) {
    //   this.setNepaliDateView();
    // }
  }

  devanagariDigits = {
    '0': '०',
    '1': '१',
    '2': '२',
    '3': '३',
    '4': '४',
    '5': '५',
    '6': '६',
    '7': '७',
    '8': '८',
    '9': '९',
  };

  nepaliMonths = [
    'बैशाख',
    'जेष्ठ',
    'असार',
    'श्रावण',
    'भाद्र',
    'आश्विन',
    'कार्तिक',
    'मंसिर',
    'पौष',
    'माघ',
    'फाल्गुन',
    'चैत्र',
  ];

  englishMonths = [
    'Baisakh',
    'Jestha',
    'Asadh',
    'Shrawan',
    'Bhadra',
    'Aswin',
    'Kartik',
    'Mangsir',
    'Poush',
    'Magh',
    'Falgun',
    'Chaitra',
  ];

  convertToNepaliNumber = (number) => {
    return number.toString().split('').map(digit => this.devanagariDigits[digit]).join('');
  };

  convertToNepaliDate = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    const nepaliDate = {
      day: this.convertToNepaliNumber(day),
      month: this.convertToNepaliNumber(month),
      year: this.convertToNepaliNumber(year),
      strMonth: this.nepaliMonths[month - 1],
    };
    return nepaliDate;
  };

  convertToEnglishDate = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    const englishDate = {
      day,
      year,
      month,
      strMonth: this.englishMonths[month - 1],
    };
    return englishDate;
  };

 

  setNepaliDateView = () => {
    if (
      this.props?.customTableField?.year?.toString() === "NaN" ||
      this.props?.customTableField?.year === null ||
      this.props?.customTableField?.month?.toString() === "NaN" ||
      this.props?.customTableField?.month === null ||
      this.props?.customTableField?.day?.toString() === "NaN" ||
      this.props?.customTableField?.day === null
    ) {
      return null;
    }
    else if (this.props?.customTableField?.year &&
      this.props?.customTableField?.month &&
      this.props?.customTableField?.day
    ) {

      let yrValue = parseInt(this.props?.customTableField?.year)
      let mnthValue = parseInt(this.props?.customTableField?.month);
      let dayValue = parseInt(this.props?.customTableField?.day);

      const engDate = `${yrValue}-${mnthValue === 11 ||
        mnthValue === 12 ||
        mnthValue === 10
        ? mnthValue
        : "0" + mnthValue
        }-${dayValue}`;

      if (engDate) {
        const nepaliDateNew = adToBs(engDate);
        const nepaliDateObject = this.convertToNepaliDate(nepaliDateNew);
        const englishDateObject = this.convertToEnglishDate(nepaliDateNew);
        this.setState({
          // customDate: nepaliDate.ne,
          customDate: nepaliDateObject,
          customTableField: this.props.customTableField,
        });
      }
    }
  };

  render() {
    let errors = this.props.errors;

    let stateName = this.props.stateName;
    let studentFieldIndex = this.props.studentFieldIndex;
    return (
      <div className="row">
        <div className="col">
          <input
            type="text"
            disabled
            // className="form-control"
            placeholder="बर्ष"
            className={
              this.props.identity
                ? errors
                  ? stateName === "admissionTableFieldGroups"
                    ? errors[stateName]
                      ? errors[stateName].year
                        ? "form-control indicate-error"
                        : this.props.bday
                          ? "form-control"
                          : errors.dateOfEstablishment.year
                            ? "form-control indicate-error"
                            : "form-control"
                      : errors[stateName][studentFieldIndex].year
                        ? "form-control indicate-error"
                        : "form-control"
                    : "form-control"
                  : "form-control"
                : "form-control"
            }
            value={
              this.state.customDate?.day
                ? this.state.customDate?.year || ""
                : ""
            }
          />
        </div>
        <div className="col">
          <input
            type="text"
            disabled
            placeholder="महिना"
            className={
              this.props.identity
                ? errors
                  ? stateName === "admissionTableFieldGroups"
                    ? errors[stateName]
                      ? errors[stateName].month
                        ? "form-control indicate-error"
                        : this.props.bday
                          ? "form-control"
                          : errors.dateOfEstablishment.month
                            ? "form-control indicate-error"
                            : "form-control"
                      : errors[stateName][studentFieldIndex].month
                        ? "form-control indicate-error"
                        : "form-control"
                    : "form-control"
                  : "form-control"
                : "form-control"
            }
            value={
              this.state.customDate?.day
                ? this.state.customDate?.strMonth || ""
                : ""
            }
          />
        </div>
        <div className="col">
          <input
            type="text"
            disabled
            placeholder="दिन"
            className={
              this.props.identity
                ? errors
                  ? stateName === "admissionTableFieldGroups"
                    ? errors[stateName]
                      ? errors[stateName].day
                        ? "form-control indicate-error"
                        : this.props.bday
                          ? "form-control"
                          : errors.dateOfEstablishment.day
                            ? "form-control indicate-error"
                            : "form-control"
                      : errors[stateName][studentFieldIndex].day
                        ? "form-control indicate-error"
                        : "form-control"
                    : "form-control"
                  : "form-control"
                : "form-control"
            }
            value={this.state.customDate?.day || ""}
          />
        </div>
      </div>
    );
  }
}

export default NepaliDateViewer;
