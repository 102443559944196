import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { axiosPost } from '../../utils/AxiosApi';
import { URL, userRole, userRoleCode } from '../../utils/Constants';
import moment from 'moment';
import swal from "sweetalert";
import { Spinner } from 'reactstrap';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import ModalWindow from '../UI/ModalWindow';
import { displayErrorAlert } from "../../utils/Utils";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const SendSMS = () => {
  const [title, setTitle] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [message, setMessage] = useState("");
  const [notifyDate, setNotifyDate] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedIndividual, setSelectedIndividual] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [peopleSpinner, setPeopleSpinner] = useState(false);

  const [notificationTypeName, setNotificationTypeName] = useState("");
  const [notificationTypeModal, setNotificationTypeModal] = useState(false);
  const [groupsArray, setGroupsArray] = useState([]);
  const [notificationTypeArr, setNotificationTypeArr] = useState([]);
  const [peopleArray, setPeopleArray] = useState([]);

  const [notifyViewModal, setNotifyViewModal] = useState(false);
  const [creditUsed, setCreditUsed] = useState(null);
  const [notifyData, setNotifyData] = useState([]);
  const [todayDate, setTodayDate] = useState(new Date().toISOString().substring(0, 10));

  useEffect(() => {
    getGroupAssociatedPeople();
    getGroupsArray();
  }, [])

  const getGroupsArray = () => {
    if (userRole === userRoleCode.roleAdmin) {
      axiosPost(URL.getAllGroups, {}, (response) => {
        if (response.status === 200) {
          let groups = response.data.data;
          let groupsArray = [];
          groups.forEach((element) => {
            groupsArray.push({ label: element.name, value: element.id });
          });
          setGroupsArray(groupsArray)
        }
      });
    } else {
      let peopleId = localStorage.getItem("peopleId");

      let data = {
        peopleId: peopleId,
      };
      axiosPost(URL.getGroups, data, (response) => {
        if (response.status === 200) {
          let groups = response.data.data;
          let groupsArray = [];
          groups.forEach((element) => {
            groupsArray.push({ label: element.name, value: element.id });
          });
          setGroupsArray(groupsArray)
        }
      });
    }
  };

  const getGroupAssociatedPeople = () => {
    let data = {
      peopleId: localStorage.getItem("peopleId"),
    };
    setPeopleSpinner(true);
    axiosPost(
      URL.getGroupAssociatedPeople,
      data,
      (response) => {
        if (response.status === 200) {
          let people = response.data.data;
          let peopleArray = [];
          let yearLevel = localStorage.getItem("yearLevel");
          let classroom = localStorage.getItem("classroom");
          if (people.length > 0) {
            people.forEach((el) => {
              let displayName = "";
              if (el.userRoleCode === userRoleCode.roleStudent) {
                displayName = `${el.name} ( [${el.userRole}] ${yearLevel} ${el.yearLevel} - ${classroom} ${el.classroom})`;
              } else {
                displayName = `${el.name} [${el.userRole}]`;
              }
              peopleArray.push({ label: displayName, value: el.id });
            });
          }
          setPeopleSpinner(false);
          setPeopleArray(peopleArray)
        }
      },
      (failur) => {
        setPeopleSpinner(false);
      }
    );
  };

  const handleGroupSelect = (selectedItem) => {
    setSelectedGroups(selectedItem);
    setSelectedIndividual([]);
  };

  const handleIndividualSelect = (selectItem) => {
    setSelectedIndividual(selectItem);
    setSelectedGroups([]);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "dateFrom") {
      setDateTo("");
      setNotifyDate("");
    }
    if (name === "message") {
      let creditUsed = Math.ceil(value.length / 160);
      setCreditUsed(creditUsed);
      setMessage(value);
    }
  };

  const resetDataState = () => {
    setMessage("");
    setSelectedGroups([]);
    setSelectedIndividual([]);
  };
  const handleSendSms = (sendSms) => {
    let url = sendSms ? URL.sendSms : URL.saveSms;
    let selectedGroupArr = [];
    let selectedPeopleArr = [];
    if (
      selectedGroups.length === 0 ||
      selectedIndividual.length === 0
    ) {
      swal("Oops!!", "Please fill all the required fields.");
      return;
    }
    selectedGroups.length > 0
      ? selectedGroups.forEach((sg) => {
        selectedGroupArr.push(sg.value);
      })
      : selectedIndividual.length > 0 &&
      selectedIndividual.forEach((si) => {
        selectedPeopleArr.push(si.value);
      });
    let params = {
      message: message,
      peopleIds: selectedGroupArr,
      peopleIds: selectedPeopleArr,
    }

    axiosPost(
      url, params, (response) => {
        if (response.status === 200) {
          if (sendSms) {
            swal("Success", "SMS has been queued for sending.");
          } else {
            swal("Success", "SMS saved successfully");
          }
          resetDataState();
        } else {
          displayErrorAlert(response.data.message);
        }
      }
    );

  }

  return (
    <>
      {spinner ? (
        <div className="fullWindow-Spinner">
          <div>
            <Spinner color="white"></Spinner>
          </div>
          <div style={{ fontSize: "16px", marginTop: "15px" }}>
            Please wait !! Processing.....
          </div>
        </div>
      ) : null}
      <div
        className="tt-widgetContent-tab-holder"
        style={{ height: "79.5vh" }}
      >
        <div className="tt-group-header">Send SMS</div>
        <div className="container-fluid">
          <div className="row mt-2">
            <div className="col-md-2 mt-1">
              <strong>
                Send To:
                <span
                  className="tt-assessment-module-mandatory"
                  style={{ paddingLeft: "5px" }}
                >
                  *
                </span>
              </strong>
            </div>
            <div className="col-md-4">
              <Select
                closeMenuOnSelect={false}
                isClearable={true}
                components={animatedComponents}
                isMulti
                options={groupsArray}
                placeholder="Choose Group"
                name="colors"
                onChange={handleGroupSelect}
                menuPlacement="auto"
                value={selectedGroups}
              />
            </div>
            <div className="col-md-1 text-center p-3">
              <span>OR</span>
            </div>
            <div className="col-md-4">
              <Select
                closeMenuOnSelect={false}
                isClearable={true}
                components={animatedComponents}
                isMulti
                options={peopleArray}
                placeholder="Choose Individual People"
                name="people"
                onChange={handleIndividualSelect}
                menuPlacement="auto"
                value={selectedIndividual}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-2 mt-1">
              <strong>
                Message:
                <span
                  className="tt-assessment-module-mandatory"
                  style={{ paddingLeft: "5px" }}
                >
                  *
                </span>
              </strong>
            </div>
            <div className="col">
              <textarea
                rows={4}
                name="message"
                placeholder="Enter Message"
                className="form-control"
                onChange={handleChange}
                value={message}
              ></textarea>
              <div class="mt-2">Credit used : {creditUsed ?? 0}</div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col text-right">

              <DropdownButton
                as={ButtonGroup}
                key="up"
                id="dropdown-button-drop-up"
                drop="up"
                variant="tt-button tt-button-primary"
                title="Save"
              >
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => handleSendSms(false)}
                >
                  Save
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => handleSendSms(true)}
                >
                  Send Now
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default SendSMS
